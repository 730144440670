import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Button } from '@mui/material';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import SearchIcon from '@mui/icons-material/Search';

const RelatorioPerContainer = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idLanc = searchParams.get('idlanc');
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const gridRef = useRef();
  const today = new Date().toLocaleDateString('pt-BR'); 

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const getContainers = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/relatorio/containersasfat`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        setData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getContainers();
  }, []);

  useEffect(() => {
    //if (idLanc !== null) {
    //  const modalElement = document.getElementById('modal2');
    //  modalElement.classList.add('show');
    //  modalElement.style.display = 'block';
    //  modalElement.setAttribute('aria-modal', 'true');
    //  modalElement.setAttribute('role', 'dialog');
    //  document.body.classList.add('modal-open');
    //  const backdropElement = document.createElement('div');
    //  backdropElement.classList.add('modal-backdrop', 'fade', 'show');
    //  document.body.appendChild(backdropElement);
    //}
  }, [idLanc]);

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  var filterParams = {
    
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2024,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };


  const colDefs = [
    { 
      field: "container",
      headerName: "Container",
      floatingFilter: true, 
      filter: true,
      width: 150 ,
    },
    { 
      field: "data_lanc", 
      headerName: "Data",
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: filterParams,
      width: 105 ,
    },
    { 
      field: "lanc_os", 
      headerName: "Lancamento",
      floatingFilter: true, 
      filter: true,
      width: 150 ,
    },
    { 
      field: "cli", 
      headerName: "Cliente", 
      floatingFilter: true,
      filter: true,
      width: 150 ,
    },
    { 
        field: "pag", 
        headerName: "Pagador",
        floatingFilter: true, 
        filter: true,
        width: 150 ,
      },
    { 
      field: "data_data_emissao_nf", 
      headerName: "Emissão", 
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: filterParams,
      width: 150 ,
    },
    { 
      field: "data_vencimento", 
      headerName: "Vencimento", 
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: filterParams,
      width: 150 ,
    },
    { 
      field: "number_nf", 
      headerName: "Nota Fiscal", 
      floatingFilter: true,
      filter: true,
      width: 150 ,
    },
    { 
      field: "value", 
      headerName: "Valor do Container", 
      floatingFilter: true,
      valueFormatter: (params) => formatCurrency(params.value),
      filter: true,
      width: 150 ,
    },
    { 
      field: "status", 
      headerName: "Status", 
      floatingFilter: true,
      filter: true,
      width: 150 ,
    },
    
    {
      field: "id_fat",
      headerName: "Consultar",
      width: 150,
      cellRenderer: (params) => (
        <Link to={`/faturamento?idlanc=${params.data.id_fat}`}
            className="btn btn-outline-dark btn-sm" target="_blank" rel="noopener noreferrer"
            style={{
              padding: "2px .2rem",
              height: "25px",
              fontSize: "12px"
            }}
            >
          <SearchIcon />
        </Link>
      ),
    },
  ];

  const getCurrentDate = () => {
    const date = new Date();
    console.log(date)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}_${month}_${year}__${hours}_${minutes}`;
  };

  const exportToExcel = () => {
    const rowData = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });

    const columns = {
      lanc_os: "Lançamento",
      data_lanc: "Data",
      emp: "Empresa",
      client: "Cliente",
      valor: "Valor",
      data_data_emissao_nf: "Emissão",
      data_vencimento: "Vencimento",
      status: "Status"
    };


    const formattedData = rowData.map(row => {
      const formattedRow = {};
      Object.keys(row).forEach(key => {
        formattedRow[columns[key] || key] = row[key];
      });
      return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: Object.values(columns) });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Faturamento");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `Relatorio de Containers_${getCurrentDate()}.xlsx`);
  };


  return (
    <>
      <Navbar />
      <div className="row align-items-start g-1">
        <div className="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <form className="row g-3 py-2">
                  <legend className="form-label">Relatorio de Containers</legend>
                  <div className="container">
                    <div className="box-center1 bg-custom">
                      <div className="card col-md-12 mx-auto">
                        <div className="ag-theme-quartz ag-theme-custom" style={{ height: 600 }}>
                          <AgGridReact
                          localeText={AG_GRID_LOCALE_BR}
                            ref={gridRef}
                            rowData={data}
                            columnDefs={colDefs}
                            rowSelection="unique"
                            pagination={true}
                          />
                        </div>
                      </div>
                      <div><hr className="divider" /></div>
                      <div className="col-12 ">
                        <button type="button" className="btn mx-2 btn-success" onClick={exportToExcel}><i className="pi pi-file-excel" style={{ fontSize: '18px' }}></i> Exportar Excel</button>
                        {/* <button type="reset" className="btn mx-2 btn-danger">Exportar PDF</button> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RelatorioPerContainer;
