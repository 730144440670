import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {  useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Aba2 from "./aba2";
import Aba1 from "./aba1";
import Aba5 from "./aba5";
import axios from "axios";

const FaturamentoModal = () => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [fatAba1, setFatAba1] = useState({
    lanc_os:"",
    id_emp: "",
    fechamento: "",
    id__entidade: "",
    cliente:"",
    id_entidade_pag: "",
    cliente_pag:"",
    data_emissao_nf: "",
    data_vencimento: "",
    data_vencimentoOrig: "",
    valor: "",
    valor_receb: "",
    valor_pagm: "",
    id_bank: "",
    id_forma_pag: "",
    number_nf:"",
    boleto: "",
    comment:"",
    id_conta: "",
    id_status: "",
    nome:"",
    id_usuario: ""});
  const [fatAba2, setFatAba2] = useState([]);
  const [totalRowDataAba2, setTotalRowDataAba2] = useState([]);
  const [fatAba5, setFatAba5] = useState([]);
  const [totalRowDataAba5, setTotalRowDataAba5] = useState([]);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const idLanc = new URLSearchParams(window.location.search).get('idlanc');
  const [codLanc, setCodLanc ] = useState(null);
  const [handleButtonBlockSave, setHandleButtonBlockSave] = useState(false)
  const [isReady, setIsReady] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);

  useEffect(() => {
    if (idLanc != null) {
      setCodLanc(idLanc);
    }
  },[idLanc])

  useEffect(() => {
      setIsReady(true); // Define como pronto quando codLanc não é mais nulo
  }, [codLanc]);

  useEffect(() => {    
    const modalElement = document.getElementById('modal2');
    const closeModalButtons = document.querySelectorAll('[data-bs-dismiss="modal"]');
    const handleCloseModal = () => {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
      modalElement.setAttribute('aria-modal', 'false');
      modalElement.setAttribute('aria-hidden', 'true');
      document.body.classList.remove('modal-open');
      removeModalBackdrop();
      window.location.href = `${window.location.origin}/faturamento`;

      //navigate('/faturamento');
      //window.location.reload();
    };

    closeModalButtons.forEach(button => {
      button.addEventListener('click', handleCloseModal);
    });
    return () => {
      closeModalButtons.forEach(button => {
        button.removeEventListener('click', handleCloseModal);
      });
    };
  }, []);

  const removeModalBackdrop = () => {
    const backdropElement = document.querySelector('.modal-backdrop');
    if (backdropElement) {
        backdropElement.remove();
    }
  };

  const validateFields = () => {
    const newErrors = {};
  
  
    if (!fatAba1.id_emp) newErrors.id_emp = "Empresa é obrigatória";
    //if (!fatAba1.fechamento); newErrors.fechamento = "Fechamento é obrigatório";
    if (!fatAba1.cliente) newErrors.cliente = "Cliente é obrigatório";
    if (!fatAba1.cliente_pag) newErrors.cliente_pag = "Cliente Pagador é obrigatório";
    if (!fatAba1.data_emissao_nf) newErrors.data_emissao_nf = "Data de Emissão NF é obrigatória";
    if (!fatAba1.data_vencimento) newErrors.data_vencimento = "Data de Vencimento é obrigatória";
    if (!fatAba1.valor) newErrors.valor = "Valor Original é obrigatório";
    if (!fatAba1.valor_receb) newErrors.valor_receb = "Valor a Receb. é obrigatório";
    if (!fatAba1.valor_pagm) newErrors.valor_pagm = "Valor Pago é obrigatório";
    if (!fatAba1.id_bank) newErrors.id_bank = "Banco é obrigatório";
    if (!fatAba1.id_forma_pag) newErrors.id_forma_pag = "Forma de Pagamento é obrigatória";
    if (!fatAba1.id_conta) newErrors.id_conta = "Conta é obrigatória";
    if (!fatAba1.id_status) newErrors.id_status = "Status é obrigatório";
    if (!fatAba2.length > 0) newErrors.containes_has_values = "Os containers são obrigatório";
    if (!fatAba5.length > 0) newErrors.center_has_values = "Os Centros são obrigatório";
    const totalValue2 = fatAba2.reduce((acc, item) => acc + parseFloat(item.value/100), 0);
    const totalValue5 = fatAba5.reduce((acc, item) => acc + parseFloat(item.value/100), 0);
    console.log(convertCurrency(fatAba1.valor).toFixed(2),totalValue5.toFixed(2))
    if(fatAba1.id_status != 5 && fatAba1.id_status != 9 && convertCurrency(fatAba1.valor).toFixed(2) !== totalValue2.toFixed(2)) newErrors.containes_has_values = "Total de Container esta divergente!";
    if (fatAba1.id_status != 5 && fatAba1.id_status != 9 && convertCurrency(fatAba1.valor).toFixed(2) !== totalValue5.toFixed(2)) newErrors.center_has_values = "Total dos Centros esta divergente!";
    //console.log(convertCurrency(fatAba1.valor));
    //console.log(totalValue2)
    //console.log(totalValue5)


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const convertCurrency = (currency) => {
    // Remove o 'R$ ' da string
    let numberString = currency.replace('R$', '').trim();
    // Remove os pontos de separação de milhares
    numberString = numberString.replace(/\./g, '');
    // Substitui a vírgula por ponto
    numberString = numberString.replace(',', '.');
    // Converte para número de ponto flutuante
    const floatValue = parseFloat(numberString);
    return floatValue;
  };

  const handleSave = async () => {
    if (!validateFields()) {
      toast.error('Por favor, preencha todos os campos obrigatórios!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    setHandleButtonBlockSave(true);

    if (!codLanc) {
      await handleSubmitAba1();
    } else {
      await handleUpdateAba1();
    }

    setTimeout(async () => {
      if (codLanc) {
        await handleSaveAba2();
        await handleSaveAba5();
        window.location.href = `${window.location.origin}/faturamento?idlanc=${codLanc}`;
      } else {
        setHandleButtonBlockSave(false);
        toast.error('Erro ao definir codLanc!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }, 2000);
  };

  const handleSubmitAba1 = async () => {
    try {
      const response = await fetch(`${parsedURL}post/lanc/Fat`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(fatAba1)
      });
      console.log(fatAba1)

      if (!response.ok) {
        throw new Error('Erro ao enviar os dados.');
      }

      const data = await response.json();
      console.log('Dados enviados com sucesso:', data.idlanc);
      setCodLanc(data.idlanc);
      await handleSaveOtherAbas(data.idlanc);
      toast.success('Lançamento realizado', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      setHandleButtonBlockSave(false);
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateAba1 = async () => {
    try {
      const response = await fetch(`${parsedURL}put/lanc/fat_id?idlanc=${codLanc}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(fatAba1)
      });

      if (!response.ok) {
        setHandleButtonBlockSave(false);
        throw new Error('Erro ao enviar os dados.');
      }

      const data = await response.json();
      console.log('Dados enviados com sucesso:', data);
      await handleSaveOtherAbas(codLanc);
      toast.success('Lançamento atualizado', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      setHandleButtonBlockSave(false);
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSaveOtherAbas = async (idlanc) => {
    try {
      await handleSaveAba2(idlanc);
      await handleSaveAba5(idlanc);
      window.location.href = `${window.location.origin}/faturamento?idlanc=${idlanc}`;
    } catch (error) {
      setHandleButtonBlockSave(false);
      toast.error('Erro ao salvar dados das outras abas!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmitAba5 = async (idlanc) => {
    try {
      const newData = fatAba5.filter(data => !data.id_centF); // Filtra os dados que não possuem id_centF
      const response = await axios.post(
        `${parsedURL}post/lanc/fat_center_coust?idFat=${idlanc}`,
        newData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      setHandleButtonBlockSave(false);
      console.error(error);
    }
  };

  const handleUpdateAba5 = async (idlanc) => {
    try {
      const updatedData = fatAba5.filter(data => data.id_centF);
      const response = await axios.put(
        `${parsedURL}put/lanc/fat_center_coust_id?idFat=${idlanc}`,
        updatedData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      setHandleButtonBlockSave(false);
      console.error(error);
    }
  };

  const handleSaveAba5 = async (idlanc) => {
    await handleSubmitAba5(idlanc);
    await handleUpdateAba5(idlanc);
  };

  const handleSubmitAba2 = async (idlanc) => {
    const newData = fatAba2.filter(data => !data.id_cc); // Filtra os dados que não possuem id_cc
    try {
      const response = await axios.post(
        `${parsedURL}post/lanc/fat_cntr?idFat=${idlanc}`,
        newData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      setHandleButtonBlockSave(false);
      console.error(error);
    }
  };

  const handleUpdateAba2 = async (idlanc) => {
    try {
      const updatedData = fatAba2.filter(data => data.id_cc); // Filtra os dados que possuem id_cc
      const response = await axios.put(
        `${parsedURL}put/lanc/fat_services_id?idFat=${idlanc}`,
        updatedData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      setHandleButtonBlockSave(false);
      console.error(error);
    }
  };

  const handleSaveAba2 = async (idlanc) => {
    await handleSubmitAba2(idlanc);
    await handleUpdateAba2(idlanc);
  };

  return (
    <>
      <div class="modal fade" id="modal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Faturamento</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className="row align-items-end">
              <div className="col-12 ">
              <legend className="form-label">Dados da Fatura</legend>
                  <Aba1 errors={errors} fat={fatAba1} setFat={setFatAba1} cancelModal={cancelModal} setCancelModal={setCancelModal} />
              </div>
              <div><hr className="divider" /></div>
              <div className="col-8 ">
              <legend className="form-label">Containers / Serviços</legend>
                <Aba2 errors={errors} rowData={fatAba2} setRowData={setFatAba2} totalRowData={totalRowDataAba2} setTotalRowData={setTotalRowDataAba2} fat={fatAba1}/>
              </div>
              <div className="col-4 ">
              <legend className="form-label">Centro de Custos</legend>
                  <Aba5 errors={errors} rowData={fatAba5} setRowData={setFatAba5} totalRowData={totalRowDataAba5} setTotalRowData={setTotalRowDataAba5} fat={fatAba1}/>
              </div>
              </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" disabled={handleButtonBlockSave}  class="btn btn-primary"  onClick={handleSave}>Salvar</button>
            </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default FaturamentoModal;