import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import DataFat from "./cad_entidade_data_fat";
import { formatDate } from "date-fns/format";

const CadEntidade = () => {
  const [email, setEmail] = useState('assist.financeiro@transtecworld.com');
  const [password, setPassword] = useState('Tec1906@');
  const [dataApiBrasil, setDataApiBrasil] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [visiblecpf,setVisibleCpf] = useState(false);
  const idEntidade = new URLSearchParams(window.location.search).get('idEntidade');
  const [visibleCNPJ,setVisibleCNPJ] = useState(false);
  const [formState, setFormState] = useState({
    razao_social: "",
    fantasia: "",
    cnpj_cpf: "",
    cnh: "",
    inscricao_estadual: "",
    logradouro: "",
    cep: "",
    numero: "",
    cidade: "",
    doc_type: "",
    obs: "",
    estado: "",
    a: 0,
    acheck: false,
    c: 0,
    ccheck: false,
    t: 0,
    tcheck: false,
    m: 0,
    mcheck: false,
    f: 0,
    fcheck: false
  });

  const handleCheckboxChange = (event) => {
    const { id, checked, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [id]: checked ? '1' : '0',
      [`${id}check`] : checked ? true : false
    }));
  };

  const handleGetEntidade = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/entidade_id?idEntidade=${idEntidade}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.content[0];
        console.log(data);
        
        // Convertendo valores para booleanos
        const formattedData = {
          ...data,
          acheck: data.a === 1,
          ccheck: data.c === 1,
          tcheck: data.t === 1,
          mcheck: data.m === 1,
          fcheck: data.f === 1,
          doc_type: data.doc_type === 'CPF' ? 2 : data.doc_type === 'CNPJ' ? 1 : data.doc_type
        };

        //const formatData2 = {
        //  ...formatDate,
        //  doc_type:
//
        //}
  
        setFormState(formattedData);
        console.log(formState);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() =>{
    if(idEntidade){
      handleGetEntidade();
    }

  },[idEntidade])

  useEffect(() => {
    handleLoginApiBrasil();
  }, []);
  
  useEffect(() => {
    if(formState.doc_type === '2'){
    setVisibleCpf(true)
    setVisibleCNPJ(false)
    } else if (formState.doc_type === '1'){
      setVisibleCNPJ(true)
      setVisibleCpf(false)
    }
    else{
      setVisibleCNPJ(false)
      setVisibleCpf(false)
    }
    
  }, [formState.doc_type]);

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "cnpj_cpf") {
      const formattedValue = formState.doc_type === '1' ? formatCNPJ(value) : formatCPF(value);
      setFormState(prevState => ({
        ...prevState,
        [name]: formattedValue
      }));
    } else {
      setFormState(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleSubmit = () => {
    //event.preventDefault();

    fetch(`${parsedURL}post/cadastro/entidade`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(formState)
    })
      .then(response => {
        if (!response.ok) {
          toast.error('Erro ao enviar os dados!', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          throw new Error('Erro ao enviar os dados.');

        }
        return response.json();
      })
      .then(data => {
        console.log('Dados enviados com sucesso:', data);
        toast.success('Entidade Cadastrada com sucesso!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        window.location.reload();
      })
      .catch(error => {
        console.error('Erro ao enviar os dados:', error);
        toast.error('Erro ao enviar os dados!', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const handleLoginApiBrasil = async () => {
    try {
      const res = await axios.post('https://gateway.apibrasil.io/api/v2/login', {
        email: email,
        password: password
      }, {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      setDataApiBrasil(res.data);
      //setError(null);
      console.log(res.data)
    } catch (err) {
      //setResponse(null);
    }
  };

  const formatCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/\D/g, "");
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  };

  const handleGetCEP = async () => {
    const fetchCPFData = new Promise(async (resolve, reject) => {
      console.log(formState.cep);
      if (formState.cep !== null && formState.cep !== '') {
        try {
          const result = await axios.get(
            `https://viacep.com.br/ws/${formState.cep}/json/?callback=callback_name`,
            {
              headers: {
                'accept': 'application/json'
              },
            }
          );
          if (result.status === 200) {
            // Remove o "callback_name(" do início e o ")" do fim
            const dataCepString = result.data.replace(/^callback_name\(|\);$/g, '');
            const dataCep = JSON.parse(dataCepString);
  
            setFormState(prevState => ({
              ...prevState,
              estado: dataCep.uf || '',
              logradouro: (dataCep.logradouro || '') + ', ' + (dataCep.bairro || '') + ', ' + (dataCep.complemento || ''),
              cidade: dataCep.localidade || '',
              numero: dataCep.unidade || ''
            }));
            console.log(JSON.stringify(dataCep));
            resolve("CEP validado com sucesso!");
          }
        } catch (error) {
          console.error("Erro ao buscar o CEP:", error);
          reject(error);
        }
      } else {
        reject("Não existe dados para realizar a busca!");
      }
    });
  
    toast.promise(
      fetchCPFData,
      {
        pending: {
          render() {
            return "Buscando dados...";
          },
          icon: true,
        },
        success: {
          render({ data }) {
            return `${data}`;
          },
          icon: "🟢",
        },
        error: {
          render({ data }) {
            return `Erro: ${data.message}`;
          },
          icon: "🔴",
        }
      }
    );
  };

  const handleGetCPF = async () => {
    setFormState(
      prevState => ({
        ...prevState,
      razao_social: "",
      fantasia: "",
      cnh: "",
      inscricao_estadual: "",
      logradouro: "",
      cep: "",
      numero: "",
      cidade: "",
      obs: "",
      estado: "",
      a: 0,
      c: 0,
      t: 0,
      m: 0,
      f: 0
    }));
    if (formState.doc_type === '2') {
        if (formState.cnpj_cpf !== null && formState.cnpj_cpf !== '') {
            const fetchCPFData = new Promise(async (resolve, reject) => {
                try {
                    const verifica = await axios.get(
                        `${parsedURL}get/entidade/verifica_doc?doc=${formState.cnpj_cpf}`,
                        {
                            headers: {
                                'accept': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                        }
                    );
                    if (verifica.status === 200) {
                        reject(new Error('Já existe cadastro realizado com esse CPF.'));
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        // Proceed with API call to get CPF data
                        try {
                            const response = await axios.post(
                                `https://cluster.apigratis.com/api/v2/dados/cpf`,
                                {
                                    "cpf": formState.cnpj_cpf
                                },
                                {
                                    headers: {
                                        'accept': 'application/json',
                                        'DeviceToken': '20eb8941-3bf4-4593-b6b3-578b2a16658c',
                                        'Authorization': `Bearer ${dataApiBrasil.authorization.token}`
                                    },
                                }
                            );

                            if (response.data.error === false) {
                                console.log(JSON.stringify(response.data));

                                const contentResponse = response.data.response.content;
                                const nome = contentResponse.nome.conteudo.nome || '';

                                setFormState(prevState => ({
                                    ...prevState,
                                    razao_social: nome,
                                }));

                                console.log(contentResponse.pesquisa_enderecos);

                                if (contentResponse.pesquisa_enderecos.existe_informacao === 'SIM') {
                                    const endereco = contentResponse.pesquisa_enderecos.conteudo.endereco;

                                    setFormState(prevState => ({
                                        ...prevState,
                                        logradouro: (endereco.logradouro || '') + ', ' + (endereco.bairro || ''),
                                        cep: endereco.cep || '',
                                        estado: endereco.estado || '',
                                        cidade: endereco.cidade || '',
                                        numero: endereco.numero || ''
                                    }));

                                    resolve("CPF validado com sucesso!");
                                } else {
                                    resolve("CPF validado com sucesso! Não existe endereços cadastrados nesse CPF!");
                                }
                            } else {
                                reject(new Error('Erro na resposta da API, verifique os dados e tente novamente.'));
                            }
                        } catch (error) {
                            console.log(error);
                            reject(error);
                        }
                    } else {
                        reject(new Error('Erro ao verificar o documento.'));
                    }
                }
            });
            toast.promise(
                fetchCPFData,
                {
                    pending: {
                        render() {
                            return "Buscando dados...";
                        },
                        icon: true,
                    },
                    success: {
                        render({ data }) {
                            return `${data}`;
                        },
                        icon: "🟢",
                    },
                    error: {
                        render({ data }) {
                            return `Erro: ${data.message}`;
                        },
                        icon: "🔴",
                    }
                }
            );

        } else {
            toast.error('Não existem dados para buscar', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    } else if (formState.doc_type === '1') {
        console.log('Buscar via CNPJ');
        if (formState.cnpj_cpf !== null && formState.cnpj_cpf !== '') {
            const fetchCNPJData = new Promise(async (resolve, reject) => {
                try {
                    const verifica = await axios.get(
                        `${parsedURL}get/entidade/verifica_doc?doc=${formState.cnpj_cpf}`,
                        {
                            headers: {
                                'accept': 'application/json',
                                'Authorization': `Bearer ${token}`
                            },
                        }
                    );
                    if (verifica.status === 200) {
                        reject(new Error('Já existe cadastro realizado com esse CNPJ.'));
                    }
                } catch (error) {
                    if (error.response && error.response.status === 404) {
                        // Proceed with API call to get CNPJ data
                        try {
                            const response = await axios.post(
                                `https://cluster.apigratis.com/api/v2/dados/cnpj`,
                                {
                                    "cnpj": formState.cnpj_cpf
                                },
                                {
                                    headers: {
                                        'accept': 'application/json',
                                        'DeviceToken': '7399ab25-6c78-44cb-9a59-a43f8c2b6cc1',
                                        'Authorization': `Bearer ${dataApiBrasil.authorization.token}`
                                    },
                                }
                            );

                            if (response.data.error === false) {
                                console.log(JSON.stringify(response.data));
                                const cnpjResponse = response.data.response.cnpj;

                                setFormState(prevState => ({
                                    ...prevState,
                                    fantasia: cnpjResponse.nome_fantasia || '',
                                    razao_social: cnpjResponse.empresa.razao_social || '',
                                    logradouro: (cnpjResponse.tipo_logradouro || '') + ' ' + (cnpjResponse.logradouro || '') + ', ' + (cnpjResponse.bairro || '') + ', ' + (cnpjResponse.complemento || ''),
                                    cep: cnpjResponse.cep || '',
                                    estado: cnpjResponse.uf || '',
                                    cidade: (cnpjResponse.municipio && cnpjResponse.municipio.descricao) || '',
                                    numero: cnpjResponse.numero || ''
                                }));
                                resolve("CNPJ validado com sucesso!");
                            } else {
                                reject(new Error('Erro na resposta da API, verifique os dados e tente novamente.'));
                            }
                        } catch (error) {
                            console.log(error);
                            reject(error);
                        }
                    } else {
                        reject(new Error('Erro ao verificar o documento.'));
                    }
                }
            });
            toast.promise(
                fetchCNPJData,
                {
                    pending: {
                        render() {
                            return "Buscando dados...";
                        },
                        icon: true,
                    },
                    success: {
                        render({ data }) {
                            return `${data}`;
                        },
                        icon: "🟢",
                    },
                    error: {
                        render({ data }) {
                            return `Erro: ${data.message}`;
                        },
                        icon: "🔴",
                    }
                }
            );

        } else {
            toast.error('Não existem dados para buscar', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }

    } else {
        console.log('Não tem como buscar...')
        toast.error('Selecione um tipo de documento', {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    }
};
  return (
    <>
      <Navbar></Navbar>
      <div className="box-center">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button class="nav-link active form-label" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Cadastro Gerais </button>
            {/*<button class="nav-link form-label" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Dados Fatura</button>*/}
          </div>
        </nav>
        <div class="tab-content" id="nav-tabContent">
          <div class="tab-pane fade fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
            <legend className="form-label p-2">Cadastro de Entidade</legend>
            <form class="row g-3" onSubmit={handleSubmit}>

              <div class="col-md-4">
                <label for="razaosocial" class="form-label ">Razão Social</label>
                <input type="text" class="form-control transparent-input" id="razaosocial" name="razao_social" value={formState.razao_social} onChange={handleChange} />
              </div>
              {(visibleCNPJ) && (<div class="col-md-4">
                <label for="nomefantasia" class="form-label">Nome Fantasia</label>
                <input type="text" class="form-control transparent-input " id="nomefantasia" name="fantasia" value={formState.fantasia} onChange={handleChange} />
              </div>)}
              <div class="col-md-2" >
              {(visibleCNPJ) && (<div class="form-check">
                  <input class="form-check-input" type="checkbox" value={formState.a} id="a" onChange={handleCheckboxChange} checked={formState.acheck}></input>
                  <label class="form-check-label" for="flexCheckDefault">Armador</label>
                </div>)}
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value={formState.c} id="c" onChange={handleCheckboxChange} checked={formState.ccheck}></input>
                  <label class="form-check-label" for="flexCheckDefault">Cliente</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value={formState.f} id="f" onChange={handleCheckboxChange} checked={formState.fcheck}></input>
                  <label class="form-check-label" for="flexCheckDefault">Fornecedor</label>
                </div>
              </div>
              <div class="col-md-2" >
              {(visibleCNPJ) && (<div class="form-check">
                  <input class="form-check-input" type="checkbox" value={formState.t} id="t" onChange={handleCheckboxChange} checked={formState.tcheck}></input>
                  <label class="form-check-label" for="flexCheckDefault">Transportadora</label>
                </div>)}
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value={formState.m} id="m" onChange={handleCheckboxChange} checked={formState.mcheck}></input>
                  <label class="form-check-label" for="flexCheckDefault">Motorista</label>
                </div>
              </div>
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
                <div class="col-md-4">
                  <label for="doc_type" class="form-label">Tipo de Documento</label>
                  <select value={formState.doc_type} onChange={handleChange} name="doc_type" id="inputState" class="form-select transparent-input">
                    <option selected value={0}>Selecione o Tipo Doc.</option>
                    <option value={1}>CNPJ</option>
                    <option value={2}>CPF</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <label for="cnpjcpf" class="form-label">CNPJ/CPF</label>
                  <input type="text" class="form-control transparent-input" id="cnpjcpf" name="cnpj_cpf" value={formState.cnpj_cpf} onChange={handleChange} />
                </div>
                <div class="col-md-1 justify-content-center">
                  <div class="btn btn-success" onClick={handleGetCPF}>Procurar</div>
                </div>
                {(visiblecpf) && (<div class="col-md-3">
                  <label for="cnh" class="form-label">CNH</label>
                  <input type="text" class="form-control transparent-input" id="cnh" name="cnh" value={formState.cnh} onChange={handleChange} />
                </div>)}
                {(visibleCNPJ) && (<div class="col-md-2">
                  <label for="inscestadual" class="form-label">Inscrição Estadual</label>
                  <input type="text" class="form-control transparent-input" id="inscestadual" name="inscricao_estadual" value={formState.inscricao_estadual} onChange={handleChange} />
                </div>)}
              </div>
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end">
                <div class="col-md-3">
                  <label for="cep" class="form-label">CEP</label>
                  <input type="text" class="form-control transparent-input" id="cep" name="cep" value={formState.cep} onChange={handleChange} />
                </div>
                <div class="col-md-1 justify-content-center">
                  <div class="btn btn-success" onClick={handleGetCEP}>Procurar </div>
                </div>
                <div class="col-md-2">
                  <label for="estado" class="form-label">UF</label>
                  <input type="text" class="form-control transparent-input" id="estado" name="estado" value={formState.estado} onChange={handleChange} />
                </div>
                <div class="col-md-2">
                  <label for="cidade" class="form-label">Cidade</label>
                  <input type="text" class="form-control transparent-input" id="cidade" name="cidade" value={formState.cidade} onChange={handleChange} />
                </div>
                <div class="col-md-2">
                  <label for="numero" class="form-label">Número</label>
                  <input type="text" class="form-control transparent-input" id="numero" name="numero" value={formState.numero} onChange={handleChange} />
                </div>
              </div>
              <div class="col-md-12">
                <label for="endereco" class="form-label">Endereço</label>
                <input type="text" class="form-control transparent-input" id="endereco" name="logradouro" value={formState.logradouro} onChange={handleChange} />
              </div>

              <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label">Observação</label>
                <textarea class="form-control transparent-input" rows="5"></textarea>
              </div>
              <div class="col-md-12">
                {/*<div class="form-check">
                  <input class="form-check-input" type="checkbox" id="gridCheck" />
                  <label class="form-check-label hfx" for="gridCheck">
                    Inadimplente
                  </label>
              </div>*/}
              </div>
              <div class="col-md-12">
                <button class="btn btn-success">Salvar</button>
              </div>
            </form>
          </div>
          <div class="tab-pane" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0"><DataFat /></div>
        </div>
      </div>
      <ToastContainer stacked
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};


export default CadEntidade;
