import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const Empresas = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = localStorage.getItem("token");
    const parsedURL = localStorage.getItem('urlParsed');
    const [empresas,setEmpresas] = useState();
    const [idEmp,setIdEmp] = useState();
    const [empresa, setEmpresa] = useState({
       "id_emp":"",
       "razao_social":"",
       "fantasia":"",
       "end":"",
       "city":"",
       "uf":"",
       "cep":"",
       "cnpj":"",
       "insc_est":"",
       "insc_mun":"",
       "fone":"",
       "site":"",
       "email":""
    });
  
    useEffect(() => {
        handleGetEmpresas();
      }, []);


      const handleGetEmpresas = async () => {
        try {
          const response = await axios.get(
            `${parsedURL}get/conf/data_empresas`,
            {
              headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
              },
            }
          );
          if (response.status === 200) {
            console.log(JSON.stringify(response.data.content));
            setEmpresas(response.data.content);
          }
        } catch (error) {
          console.log(error);
        }
      }

      const handleGetEmpresaId = async (params) => {
        try{
            const response = await axios.get(
                `${parsedURL}get/conf/data_empresa_id?id_empresa=${params.data.id_emp}`,
                {
                    headers: {
                      'accept': 'application/json',
                      'Authorization': `Bearer ${token}`
                    },
                  }
                );
                if (response.status === 200) {
                  console.log(response.data);
                  setEmpresa(response.data.content);
                }
        } catch (error) {
            console.log(error);
        }

      }

      
    const colDefs = [
        { field: "razao_social", headerName: "Empresa", filter: true, width: 450 },
        { field: "city", headerName: "Cidade", filter: true, width: 150 },
        { field: "uf", headerName: "UF", filter: true, width: 80 },
        { field: "cnpj", headerName: "CNPJ", filter: true, width: 200 },
        {
            field: "id_emp", headerName: "Editar", filter: false, width: 100, cellRenderer: (params) => (
<button type="submit" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#BancoEdit" onClick={() => handleGetEmpresaId(params)}><NavigateNextRoundedIcon/></button>            )
        }
    ];

    return (
        <>
            <div className="box-center1 bg-custom">
                <div className="card col-md-12 mx-auto">
                    <div className="ag-theme-quartz" style={{ height: 600 }}>
                        <AgGridReact
                            rowData={empresas}
                            columnDefs={colDefs}
                            rowSelection="unique"
                        />
                    </div>
                </div>
            </div>
            {/*BOTA DE CADASTRO*/}
            <div className="box-center1">
                <div className='card-body'>
                    <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#BancoCad">Cadastrar Empresas<AddRoundedIcon /></button>
                </div>
            </div>
            {/*MODAL DE CADASTRO*/}
            <div class="modal fade" id="BancoCad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">Cadastrar Empresa <AddRoundedIcon /></h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <didiv class="modal-body">
                            <form>
                                <div className="card col-md-20 mx-auto ">
                                    <div className="card-body bg">

                                        <form class="row g-3">
                                            <div class="col-12 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">Razão Social</label>
                                            </div>
                                            <div><hr class="divider" /></div>

                                            <div class="col-md-6 form-floating mb-3 ">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">Fantasia</label>
                                            </div>

                                            <div class="col-md-6 form-floating mb-3 col-md-6">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">Endereço</label>
                                            </div>

                                            <div class="col-md-5 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">Cidade</label>
                                            </div>
                                            <div class="col-md-1 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">UF</label>
                                            </div>

                                            <div class="col-md-5 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">CEP</label>
                                            </div>
                                            <div class="col-md-4 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">CNPJ</label>
                                            </div>
                                            <div class="col-md-4 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">I.E.</label>
                                            </div>
                                            <div class="col-md-4 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">I.M.</label>
                                            </div>
                                            <div><hr class="divider" /></div>
                                            <div class="col-md-6 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">Fone</label>
                                            </div>
                                            <div class="col-md-6 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput"/>
                                                <label for="floatingInput">Site</label>
                                            </div>
                                            <div class="col-12 form-floating mb-3">
                                                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" />
                                                <label for="floatingInput">E-mail</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </form>
                        </didiv>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Limpar dados</button>
                            <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >Cadastrar</button>
                        </div>
                    </div>
                </div>
            </div>

            {/*MODAL EDITAR*/}
            <div class="modal fade" id="BancoEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-xl">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">Editar Empresa</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <form>
                                <div className="card col-md-20 mx-auto ">
                                    <div className="card-body bg">

                                        <form class="row g-3">
                                            <div class="col-12 form-floating mb-3">
                                                <input type="text" class="form-control " value={empresa.razao_social} id="floatingInput"/>
                                                <label for="floatingInput">Razão Social</label>
                                            </div>
                                            <div><hr class="divider" /></div>

                                            <div class="col-md-6 form-floating mb-3 ">
                                                <input type="text" class="form-control" value={empresa.fantasia} id="floatingInput"/>
                                                <label for="floatingInput">Fantasia</label>
                                            </div>

                                            <div class="col-md-6 form-floating mb-3 col-md-6">
                                                <input type="text" class="form-control" value={empresa.endereco} id="floatingInput"/>
                                                <label for="floatingInput">Endereço</label>
                                            </div>

                                            <div class="col-md-5 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.city} id="floatingInput"/>
                                                <label for="floatingInput">Cidade</label>
                                            </div>
                                            <div class="col-md-1 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.uf} id="floatingInput"/>
                                                <label for="floatingInput">UF</label>
                                            </div>

                                            <div class="col-md-5 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.cep} id="floatingInput"/>
                                                <label for="floatingInput">CEP</label>
                                            </div>
                                            <div class="col-md-4 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.cnpj} id="floatingInput"/>
                                                <label for="floatingInput">CNPJ</label>
                                            </div>
                                            <div class="col-md-4 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.insc_est} id="floatingInput"/>
                                                <label for="floatingInput">I.E.</label>
                                            </div>
                                            <div class="col-md-4 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.insc_mun} id="floatingInput"/>
                                                <label for="floatingInput">I.M.</label>
                                            </div>
                                            <div><hr class="divider" /></div>
                                            <div class="col-md-6 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.fone} id="floatingInput"/>
                                                <label for="floatingInput">Fone</label>
                                            </div>
                                            <div class="col-md-6 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.site} id="floatingInput"/>
                                                <label for="floatingInput">Site</label>
                                            </div>
                                            <div class="col-12 form-floating mb-3">
                                                <input type="text" class="form-control" value={empresa.email} id="floatingInput"/>
                                                <label for="floatingInput">E-mail</label>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-primary">Salvar alterações</button>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default Empresas;