
import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from "../../context/AuthContext";
import logo from "../../assets/logo.png";
import "../../style/style.css";

const Navbar = () => {
  const navigate = useNavigate();
  const { permissions } = useAuth();
  

  const HandleExitSystem = async (event) => {
    event.preventDefault();
    localStorage.removeItem('token');
    localStorage.clear();
    window.location.href = `${window.location.origin}/login`;
    window.location.reload();
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-custom wp-1 -w">
      <div className="container-fluid">
        <a className="navbar-brand" href="#"><img src={logo} alt="Transtec Word" width="150" height="40" /></a>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main_nav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="main_nav">
          <ul className="navbar-nav">
            <Link to="/home" ><button type="button" className="btn btn-sm btn-default bg-custom mx-1">Home</button></Link>
            {(permissions.dev || permissions.admin || permissions.manager || permissions.director || permissions.directorFin || permissions.managerOpe) && (<Link to="/dashboards" ><button type="button" className="btn btn-sm btn-default bg-custom mx-1">Dashboard</button></Link>)}
            {(permissions.dev || permissions.admin || permissions.manager || permissions.fatCont || permissions.fatUser || permissions.fatSuper || permissions.fatAvaria || permissions.managerOpe ) && (<div className="btn-group">
              <button type="button" className="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Cadastros</button>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
              {(permissions.dev || permissions.admin || permissions.manager || permissions.fatCont || permissions.fatUser || permissions.fatSuper || permissions.fatAvaria ) && 
              (<li><Link className="dropdown-item" to="/cadastro_entidade">Cadastro de Entidade</Link></li>)}
              {(permissions.dev || permissions.admin || permissions.manager || permissions.fatCont || permissions.fatUser || permissions.fatSuper || permissions.fatAvaria || permissions.managerOpe ) && 
              (<li><Link className="dropdown-item" to="/consulta_entidade">Consulta de Entidade</Link></li>)}
                {(permissions.dev || permissions.admin || permissions.manager ) && (
                  <li><Link className="dropdown-item" to="/cadastro_arquivos">Cadastro de Arquivos Bancarios</Link></li>
                )}
              </ul>
            </div>)}
            {(permissions.dev || permissions.admin || permissions.manager || permissions.fatUser || permissions.fatSuper || permissions.fatAvaria || permissions.director || permissions.directorFin || permissions.fatCont || permissions.managerOpe  ) && (
              <div className="btn-group">
                <button type="button" className="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Contas a Receber</button>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                    <>
                  {(permissions.dev || permissions.admin || permissions.manager || permissions.fatUser || permissions.fatSuper || permissions.fatAvaria || permissions.director || permissions.directorFin || permissions.fatCont || permissions.managerOpe ) && (
                      <li><Link className="dropdown-item" to="/faturamento">Faturamento</Link></li> )}
                  {(permissions.dev || permissions.admin || permissions.manager || permissions.fatUser || permissions.fatSuper || permissions.fatAvaria || permissions.director || permissions.directorFin || permissions.fatCont  ) && (
                      <li><Link className="dropdown-item" to="/conciliacao_bancaria">Baixa faturado</Link></li>)}
                  {(permissions.dev || permissions.admin || permissions.manager || permissions.fatUser || permissions.fatSuper || permissions.fatAvaria || permissions.director || permissions.directorFin || permissions.fatCont  ) && (
                      <li><Link className="dropdown-item" to="/conciliacao_bancaria_avaria">Baixa a vista</Link></li>)}
                    </>
                </ul>
              </div>
            )}
              <>
            {(permissions.dev || permissions.admin || permissions.manager || permissions.fatCont || permissions.directorFin) && (
                <div className="btn-group">
                  <button type="button" className="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Contas a Pagar</button>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                    <li><Link className="dropdown-item" to="/contas/lancamentos">Lançamentos</Link></li>
                    {(permissions.dev || permissions.manager || permissions.admin || permissions.directorFin) && (<li><Link className="dropdown-item" to="/contas/pagamentos_contas">Aprovações</Link></li>)}
                    <li><Link className="dropdown-item" to="/contas/conciliacao_bancaria">Baixa de Lançamentos</Link></li>
                  </ul>
                </div>)}
                {(permissions.dev || permissions.manager || permissions.admin || permissions.directorFin) && (<div className="btn-group">
                  <button type="button" className="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Tesouraria</button>
                  <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                  {(permissions.dev || permissions.manager || permissions.admin || permissions.directorFin) && (
                    <li><Link className="dropdown-item" to="/saldos_bancarios">Bancos</Link></li>)}
                    {(permissions.dev ) && (
                    <li><Link className="dropdown-item" to="">Cheques</Link></li>)}
                  </ul>
                </div>
              )}
              </>
            <div className="btn-group">
              <button type="button" className="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Relatórios</button>
              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
              {(permissions.dev || permissions.admin || permissions.manager || permissions.fatUser || permissions.fatSuper || permissions.fatAvaria || permissions.director || permissions.directorFin || permissions.fatCont || permissions.managerOpe  ) && 
              (<li><a class="dropdown-item" >Receita &raquo; </a>
              <ul class="submenu dropdown-menu">
                <li><Link className="dropdown-item" to="/relatorios/container_fat">Containers</Link></li>
                <li><Link className="dropdown-item" to="/relatorios/conciliacao_bancaria">Conciliação</Link></li>
                <li><Link className="dropdown-item" to="/relatorios/receita_armador">Armador</Link></li>
              </ul>
              </li>)}
                {(permissions.dev) && <li><Link className="dropdown-item" to="/relatorios">Lista de Relatórios</Link></li>}
              </ul>
            </div>
          </ul>
          <ul className="navbar-nav ms-auto">
              <div className="btn-group">
                <button type="button" className="btn btn-sm btn-default bg-custom dropdown-toggle mx-1" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">Configurações</button>
                <ul className="dropdown-menu dropdown-menu-end dropdown-menu-lg-start">
                  {(permissions.dev || permissions.admin || permissions.manager) && (<li><Link className="dropdown-item" to="/manutencao">Manutenção</Link></li>)}
                  {permissions.dev && <li><Link className="dropdown-item" to="">Logs</Link></li>}
                  <li><Link className="dropdown-item" to="/info">Versão do software</Link></li>
                </ul>
              </div>
            <Link to="/login"><button type="button" className="btn btn-sm btn-danger me-1 mx-1" onClick={HandleExitSystem}>Sair</button></Link>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
