import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const PlanoContas = () => {
    const [rowData, setRowData] = useState();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = localStorage.getItem("token");
    const parsedURL = localStorage.getItem('urlParsed');

    useEffect(() => {
      handleGetPlanConts();
      }, []);
    
    const handleGetPlanConts= async () => {
      try {
        const response = await axios.get(
          `${parsedURL}get/conf/plan_counts`,
          {
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          }
        );
        if (response.status === 200) {
          console.log(JSON.stringify(response.data.content));
          setRowData(response.data.content);
        }
      } catch (error) {
        console.log(error);
      }
    }

    const handleGetPlanCount = async (params) => {
      try{
          const response = await axios.get(
              `${parsedURL}get/conf/form_pag_id?id_pag=${params.data.id_plan}`,
              {
                  headers: {
                    'accept': 'application/json',
                    'Authorization': `Bearer ${token}`
                  },
                }
              );
              if (response.status === 200) {
                console.log(response.data);
                setRowData(response.data.content);
              }
      } catch (error) {
          console.log(error);
      }

    }

    const colDefs = [
      { field: "cont", headerName: "Conta", filter: true, width: 150 },
      { field: "descript", headerName: "Descrição", filter: true , width: 250},
      { field: "id_plan", headerName: "Editar", filter: true, width: 100, cellRenderer: (params) => (
          <button type="submit" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#planoedit" onClick={() => handleGetPlanCount(params)}><NavigateNextRoundedIcon/></button>
      )}
    ];

    return (
          <>
           <div className="box-center1 bg-custom">
           <div className="card col-md-12 mx-auto">
           <div className="ag-theme-quartz" style={{ height: 600 }}>
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefs}
            rowSelection="unique"/>
        </div>
        </div>
        </div>
        {/*BOTA DE CADASTRO*/}
        <div className="box-center1">
            <div className='card-body'>
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#planocad">Cadastrar Conta <AddRoundedIcon/></button>
        </div>
        </div>
        
        
        
        {/*MODAL DE CADASTRO*/}
                <div class="modal fade" id="planocad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">Cadastrar Banco <AddRoundedIcon/></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <form>
            <div className="card col-md-12 mx-auto">
            <div className="card-body">
                <form class="row g-1">
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control " id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Conta</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Descrição</label>
                </div>
                </form>
            </div>
            </div>
            </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Limpar e Fechar</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >Salvar</button>
            </div>
            </div>
        </div>
        </div>

        {/*MODAL EDITAR*/}
        <div class="modal fade" id="planoedit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">Editar Conta</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                <form>
            <div className="card col-md-12 mx-auto ">
            <div className="card-body bg">
                <form class="row g-1">
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control " id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Número do Banco</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Banco</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Agencia</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Conta correntte</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Caixa</label>
                </div>
                </form>
            </div>
            </div>
            </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
                
                </div>
            </div>
            </div>
          </>
    );
  };
  
  export default PlanoContas;