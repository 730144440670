import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Checkbox } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';

const OrcamentoCompra= () => {
    const navigate = useNavigate();
    const [data, setData] = useState([]);
    
     
    const getConsultaOS = async () => {
      try {
        const response = await axios.get("https://www.balldontlie.io/api/v1/teams");
        setData(response.data.data.map(row => ({ ...row, selected: false })));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    useEffect(() => {
      getConsultaOS();
    }, []);
  
    const handleCheckboxChange = (id) => {
      setData((prevData) => {
        const updatedData = prevData.map((row) =>
          row.id === id ? { ...row, selected: !row.selected } : row
        );
        return updatedData;
      });
    };
  
    const handleVerCNTRClick = (id) => {
      // Implemente a lógica desejada quando o botão "Ver CNTR" é clicado
      console.log(`Botão "Ver CNTR" clicado para a linha com ID ${id}`);
    };
    const columns = [
      {
        field: "id",
        headerName: "ID",
        width: 90,
        renderCell: (params) => (
          <Checkbox
            checked={params.row.selected}
            onChange={() => handleCheckboxChange(params.row.id)}
          />
        ),
      },
      { field: "city", headerName: "City", width: 150 },
      { field: "abbreviation", headerName: "Abbreviation", width: 150 },
      { field: "conference", headerName: "Conference", width: 150 },
      { field: "division", headerName: "Division", width: 150 },
      {
        field: "verCNTR",
        headerName: "Ver CNTR",
        width: 150,
        renderCell: (params) => (
          <button type="button" class="btn btn-outline-primary" data-bs-target="#" data-bs-toggle="modal" onClick={() => handleVerCNTRClick(params.row.id)}>
           <SearchIcon/>
          </button>
        ),
      },
    ];
  
    const rows = data.map((row) => ({
      id: row.id,
      abbreviation: row.abbreviation,
      city: row.city,
      conference: row.conference,
      division: row.division,
    }));
  
    console.log(data);

  return (
        <>
        <Navbar></Navbar>
        <div className="box-center">
        <div className="card col-md-12 mx-auto">
          <div className="card-body pt-2">
            <legend className="form-label">Compra</legend>
                    <form class="row g-3">
                <div class="col-md-6">
                    <label for="razaosocial" class="form-label ">Tipo da compra</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial" placeholder="Insira o tipo da compra"/>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Motivo</label>
                    <select id="inputState" class="form-select transparent-input">
                    <option selected>Selecione o motivo</option>
                    <option>Pedido</option>
                    <option>Orçamento</option>
                    </select>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Motivo</label>
                    <select id="inputState" class="form-select transparent-input">
                    <option selected>Selecione o Setor</option>
                    <option>Pedido</option>
                    <option>Orçamento</option>
                    </select>
                </div>
                <div class="col-md-2 ">
                    <label for="inputState" class="form-label ">Gerente</label>
                    <select id="inputState" class="form-select transparent-input">
                    <option selected>Selecione um Gerente</option>
                    <option>Masculino</option>
                    <option>Feminino</option>
                    <option>Outros</option>
                    </select>
                </div>
                <div class="col-md-2 ">
                    <label for="numdocumento" class="form-label">Data do Cadastro</label>
                    <input type="date" class="form-control transparent-input" id="numdocumento" placeholder="000.000.000-00 | 00.000.000/0000-00"/>
                </div>
                <div><hr class="divider"/></div>
                <div class="col-md-6">
                    <label for="razaosocial" class="form-label ">Valor Total</label>
                    <input type="number" class="form-control transparent-input" id="razaosocial" placeholder="Insira o tipo da compra"/>
                </div>
                <div class="col-md-2 ">
                    <label for="numdocumento" class="form-label">Data do Pedido</label>
                    <input type="date" class="form-control transparent-input" id="numdocumento" placeholder="000.000.000-00 | 00.000.000/0000-00"/>
                </div>
                <div class="col-md-2 ">
                    <label for="numdocumento" class="form-label">Data da Aprovação</label>
                    <input type="date" class="form-control transparent-input" id="numdocumento" placeholder="000.000.000-00 | 00.000.000/0000-00"/>
                </div>
                    <div><hr class="divider"/></div>
                    <div  className="my-3 transparent-input" style={{ height:400, width: '100%' }}>
                        <DataGrid rows={rows} columns={columns} pageSize={5} />
                        </div>
                        <div class="col-12">
                    <button type="submit" class="btn btn-primary">Salvar</button>
                    <button type="submit" class="btn mx-2 btn-secondary">Imprimir</button>
                    </div>
                    </form>
          </div>
            </div>
            </div>
        </>
  );
};

export default OrcamentoCompra;