import React from 'react';
import Navbar from "../navbar/navbar";

const Unauthorized = () => {
  return (
    <>
      <Navbar />
      <div className="container d-flex flex-column justify-content-center align-items-center vh-100">
        <h1 className="display-4">Acesso Não Autorizado</h1>
        <p className="lead">Você não está autorizado a acessar esta página. Por favor, verifique com o administrador do sistema.</p>
      </div>
    </>
  );
};

export default Unauthorized;
