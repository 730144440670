import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import 'primeicons/primeicons.css';


const EntConsulta = () => {
  const [rowData, setRowData] = useState();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const gridRef = useRef();

  useEffect(() => {
    handleGetEntidade();
  }, []);

  const handleGetEntidade = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/entidade`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        console.log(response.data.content)
        setRowData(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const validatenumber = (valor) => {
    const formattedValue = valor.length > 11 ? formatCNPJ(valor) : formatCPF(valor);
    return formattedValue;
  };
  
  const formatCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const formatCNPJ = (cnpj) => {
    cnpj = cnpj.replace(/\D/g, "");
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5");
  };

  const buttonRedireciona = (params) => 
    {
    console.log(params)
    window.location.href = `${window.location.origin}/cadastro_entidade?idEntidade=${params}`;
    }


  const colDefs = [
    {
      field: "razao_social", headerName: "Razão social", filter: true,
      floatingFilter: true, width: 250
    },
    {
      field: "fantasia", headerName: "Fantasia", filter: true,
      floatingFilter: true, width: 250
    },
    {
      field: "cnpj_cpf", headerName: "CPF/CNPJ", filter: true,
      valueFormatter: (params) => validatenumber(params.value),
      floatingFilter: true, width: 200
    },
    {
      field: "cidade", headerName: "Cidade", filter: true,
      floatingFilter: true, width: 120
    },
    {
      field: "estado", headerName: "UF", filter: true,
      floatingFilter: true, width: 80
    },
    {
      field: "logradouro", headerName: "Endereço", filter: true,
      floatingFilter: true, width: 150
    },
    {
      field: "numero", headerName: "Nº", filter: true,
      floatingFilter: true, width: 120
    },
    {
      field: "cep", headerName: "CEP", filter: true,
      floatingFilter: true, width: 120
    },
    //{ field: "telefone", headerName: "Tel", filter: true, width: 100 },
    //{ field: "email", headerName: "E-mail", filter: true, width: 100 },
    {
      field: "id_entidade", 
      headerName: "Editar", 
      filter: true,
      floatingFilter: true, 
      width: 150, cellRenderer: (params) => (
        <div type="button" class="btn btn-dark"
        onClick={() => buttonRedireciona(params.value)}
          style={{
            padding: "2px .2rem",
            height: "25px",
            fontSize: "12px"
          }}
        >Editar<NavigateNextRoundedIcon /></div>
      )
    }
  ];

  return (
    <>
      <Navbar></Navbar>
      <div class="row align-items-start g-1">
        <div class="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <form class="row g-3 py-2">
                  <legend className="form-label p-2">Consulta de Entidade</legend>
                  <div className="box-center1">
                    <div className=" rounded card">
                      <div className="card col-md-12 mx-auto">
                        <div className="ag-theme-quartz ag-theme-custom" style={{ height: 600 }}>
                          <AgGridReact
                            localeText={AG_GRID_LOCALE_BR}
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={colDefs}
                            rowSelection="unique"
                            pagination={true}
                          />
                        </div>
                      </div>
                    </div>

                  </div>
                </form >
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default EntConsulta;