import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Button } from '@mui/material';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import SearchIcon from '@mui/icons-material/Search';

const Aprovacoes = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idLanc = searchParams.get('idlanc');
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const gridRef = useRef();

  const [totalRowData, setTotals] = useState([]);
  const [calculateTotal, setCalculateTotal] = useState(false);
  const today = new Date();
  const todayFormatted = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;


  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const getConsultFat = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/lanc/ListConts/aprovacao`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        setData(response.data.content);
        setCalculateTotal(true);
        console.log(response.data.content)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await getConsultFat();
    };
  
    fetchData();
  }, []);

  useEffect(() => {
    if (idLanc !== null) {
      const modalElement = document.getElementById('modal2');
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.setAttribute('role', 'dialog');
      document.body.classList.add('modal-open');
      const backdropElement = document.createElement('div');
      backdropElement.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdropElement);
    }
  }, [idLanc]);

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  const handleVerCNTRClick = () => {
    getConsultFat();
  };
  
  var filterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2024,
    maxValidYear: 2025,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
    defaultFilter: {
      filterType: 'date',
      type: 'equals',
      dateFrom: todayFormatted,  // Definindo a data de hoje como padrão
    },
  };

  const colDefs = [
    { 
      field: 'id_lanc',
      headerCheckboxSelection: true, 
      checkboxSelection: true, 
      width: 40 ,
      headerCheckboxSelectionFilteredOnly: true, 
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    {
      field: "data_aprovd",
      headerName: "Data Aprovado",
      filter: "agDateColumnFilter",
      cellEditor: 'agDateStringCellEditor',
      editable: true,
      filterParams: filterParams,
      floatingFilter: true,
      width: 150,
      cellEditorParams: {
        useFormatter: true,
        dateFormat: 'dd/mm/yyyy',
      },
      valueFormatter: (params) => {
        if (params.value) {
          // Verifica se a data já está no formato 'dd/mm/yyyy'
          if (params.value.includes('/')) {
            return params.value; // Retorna a data como está
          }
          
          // Se não tiver '/', assume que a data está no formato 'yyyy-mm-dd' e a converte
          const dateParts = params.value.split("-");
          if (dateParts.length === 3) {
            return `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
          }
        }
        return params.value;
      },
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    { 
      field: "data_vencimento",
      headerName: "Vencimento", 
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      floatingFilter: true,
      width: 140 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    { 
      field: "data_vencimentoOrig", 
      headerName: "Venc. Original",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      floatingFilter: true,
      width: 140 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    { 
      field: "client", 
      headerName: "Fornecedor", 
      filter: true,
      floatingFilter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    //{ 
    //  field: "valor", 
    //  headerName: "Valor", 
    //  filter: true,
    //  floatingFilter: true,
    //  valueFormatter: (params) => formatCurrency(params.value),
    //  width: 150 ,
    //
    //},
    { 
      field: "valor_apg", 
      headerName: "Valor a Pagar", 
      valueFormatter: (params) => formatCurrency(params.value),
      filter: true,
      floatingFilter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    { 
      field: "number_nf", 
      headerName: "Forma de Pag", 
      filter: true,
      floatingFilter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    { 
      field: "planContas", 
      headerName: "Centro", 
      filter: true,
      floatingFilter: true,
      filterParams: filterParams,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    { 
      field: "emp", 
      headerName: "Empresa", 
      filter: true,
      floatingFilter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    { 
      field: "valueAprovado", 
      headerName: "Valor Aprovado", 
      valueFormatter: (params) => formatCurrency(params.value),
      filter: true,
      floatingFilter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    { 
      field: "status", 
      headerName: "Status", 
      floatingFilter: true,
      filter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
    {
      field: "id_lanc",
      headerName: "Consultar",
      floatingFilter: true,
      width: 150,
      cellRenderer: (params) => (params.node.rowPinned ? null :
        <Link to={`/contas/lancamentos?idlanc=${params.data.id_lanc}`}
            className="btn btn-outline-primary btn-sm" onClick={() => handleVerCNTRClick()}>
          <SearchIcon />
        </Link>
      ),
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }
      }
    },
  ];

  const getCurrentDate = () => {
    const date = new Date();
    console.log(date)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}_${month}_${year}__${hours}_${minutes}`;
  };

  const exportToExcel = () => {
    const rowData = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });

    const columns = {
      lanc_os: "Lançamento",
      data_lanc: "Data",
      emp: "Empresa",
      client: "Cliente",
      valor: "Valor",
      data_data_emissao_nf: "Emissão",
      data_vencimento: "Vencimento",
      status: "Status"
    };


    const formattedData = rowData.map(row => {
      const formattedRow = {};
      Object.keys(row).forEach(key => {
        formattedRow[columns[key] || key] = row[key];
      });
      return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: Object.values(columns) });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Faturamento");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(data, `faturamento_${getCurrentDate()}.xlsx`);
  };

  const calculateTotals = () => {
    const rowData = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });
    
    const totalValorOficial = rowData.reduce((acc, item) => acc + (parseFloat(item.valor) || 0), 0);
    const totalValorApagar = rowData.reduce((acc, item) => acc + (parseFloat(item.valor_apg) || 0), 0);
    const totalValorAprovado = rowData.reduce((acc, item) => acc + (parseFloat(item.valueAprovado) || 0), 0);
    
    setTotals([{
      client: 'Total',
      valueAprovado: totalValorAprovado,
      valor_apg: totalValorApagar,
      number_nf: 0
    }]);
    //console.log(rowData)
    //console.log(`Total Valor Original: R$ ${formatCurrency(totalValorOficial.toFixed(2))}`);
    //console.log(`Total Valor a Pagar: R$ ${formatCurrency(totalValorApagar.toFixed(2))}`);
    //console.log(`Total Valor Pago: R$ ${formatCurrency(totalValorPagm.toFixed(2))}`);
  };

  const onFilterChanged = () => {
    calculateTotals();
  };

  useEffect(() => {
    if (calculateTotal) {
      calculateTotals();
    }
  }, [calculateTotal, data]);

  const handleSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIdLancs = selectedRows.map(row => row.id_lanc);
    const selectedDataAprovado = selectedRows.map(row => row.data_aprovd);
    const selectedValuesAprovado = selectedRows.map(row => row.valor_apg);
    const totalValorApagar = selectedValuesAprovado.reduce((acc, item) => acc + (parseFloat(item) || 0), 0);

    console.log(selectedIdLancs)
    console.log(selectedDataAprovado)
    console.log(totalValorApagar)
    setTotals(prevState => {
      // Cria uma cópia do array de estado anterior
      const newState = [...prevState];
    
      // Atualiza o item na posição 0 com o novo valor
      newState[0] = {
        ...newState[0],
        number_nf: formatCurrency(totalValorApagar)
      };
    
      // Retorna o novo estado
      return newState;
    });
  };

  const handleButtonApr = async () => {
    const selectedRows = gridRef.current.api.getSelectedRows();
    const selectedIdLancs = selectedRows.map(row => row.id_lanc);
    const selectedDataAprovado = selectedRows.map(row => row.data_aprovd);
    const selectedDataVencimeto = selectedRows.map(row => row.data_vencimento);

    const jsonList = selectedRows.map(row => ({
      id_lanc: row.id_lanc,
      data_aprovd: row.data_aprovd,
      data_vencimento: row.data_vencimento
    }));
    console.log(jsonList);
    try {
      const response = await axios.post(
        `${parsedURL}post/contas/aprovacao_diretor_fin`,
        jsonList,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data.content)
      }
    } catch (error) {
      console.log(error);
    }

    };
  

  //const onFirstDataRendered = (params) => {
  //  params.api.forEachNodeAfterFilterAndSort((node) => {
  //    node.setSelected(true);
  //  });
  //};


  return (
    <>
      <Navbar />
      <div className="row align-items-start g-1">
        <div className="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <form className="row g-3 py-2">
                  <legend className="form-label ">Aprovação Contas</legend>
                  <div className="container">
                    <div className="box-center1 bg-custom">
                      <div className="card col-md-12 mx-auto ">
                        <div className="ag-theme-quartz ag-theme-custom" style={{ height: 600 }}>
                          <AgGridReact
                          localeText={AG_GRID_LOCALE_BR}
                            ref={gridRef}
                            rowData={data}
                            columnDefs={colDefs}
                            rowSelection="multiple"
                            pagination={true}
                            onSelectionChanged={handleSelectionChanged}
                            onFilterChanged={onFilterChanged}
                            pinnedBottomRowData={totalRowData}
                          />
                        </div>
                      </div>
                      <div><hr className="divider" /></div>
                      <div className="col-12 ">
                        <button type="button" className="btn mx-2 btn-success" onClick={handleButtonApr} >Aprovar</button>
                        {/* <button type="button" className="btn mx-2 btn-success" onClick={exportToExcel}>Exportar Excel</button>*/}
                        {/* <button type="reset" className="btn mx-2 btn-danger">Exportar PDF</button> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aprovacoes;
