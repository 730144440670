import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import '../../style/style.css';

const Grid2 = ({ grid1Data, onAssociatePayment, selectedLancIdGrid2, setSelectedLancIdGrid2, setSelectedValueGrid2 }) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [conscBank, setConcBanj] = useState([]);
  const [classExtrat, setClassExtrat] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetDataConc();
    handleGetClassExtrat();
  }, []);

  const handleGetClassExtrat = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/class_extract`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setClassExtrat(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetDataConc = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conciliacao/data_cons/receita`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setConcBanj(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleSelectChange = (index, newValue) => {

    setConcBanj((prevData) => {
      const newData = [...prevData];
      newData[index].id_class = newValue;
      return newData;
    });
  };

  const handleUpdate = async () => {
    setLoading(true);
    try {
      const updatedData = conscBank.filter(data => data.id_class); // Filtra os dados que possuem id_centF
      const response = await axios.put(
        `${parsedURL}put/lanc/conscilicacao_id`,
        updatedData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const idCelery = response.data;

        while (true) {
          const resp = await axios.get(
            `${parsedURL}get/status/put/lanc/conscilicacao_id/${idCelery}`,
            {
              headers: {
                'Authorization': `Bearer ${token}`,
                'accept': 'application/json',
              },
            }
          );

          if (resp.status === 200 && resp.data !== null) {
            setLoading(false);
            toast.success(`Classificação realizada com sucesso!!!`, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            break;
          }

          await new Promise(resolve => setTimeout(resolve, 2000));
        }
      } else {
        setLoading(false);
        toast.error(`Erro ao integrar o arquivo`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });

      }
    } catch (error) {
      setLoading(false);
      console.error(error);
    }
  };

  const handleSave = async () => {
    await handleUpdate();
    window.location.reload();
  };

  var filterParams = {

    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2024,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  const colDefs = [
    {
      field: 'id_lanc',
      headerName: '',
      headerCheckboxSelection: true,
      checkboxSelection: (params) => params.data.id_class !== 0 ? true : false,
      width: 35,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'banco',
      headerName: 'Banco',
      floatingFilter: true,
      filter: true,
      width: 120,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'conta',
      headerName: 'Conta',
      filter: true,
      floatingFilter: true,
      width: 130,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'value',
      headerName: 'Valor',
      valueFormatter: (params) => formatCurrency(params.value),
      filter: true,
      floatingFilter: true,
      width: 130,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'description',
      headerName: 'Descrição',
      filter: true,
      floatingFilter: true,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'cod_identify',
      headerName: 'Cod Ident.',
      filter: true,
      floatingFilter: true,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'dt_transition',
      headerName: 'Data Transação',
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      floatingFilter: true,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'dt_create',
      headerName: 'Data de Inserção',
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      floatingFilter: true,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'id_class',
      headerName: 'Classificação',
      filter: true,
      floatingFilter: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: classExtrat.map((opcao) => opcao.id_class), // IDs para seleção
      },
      valueFormatter: (params) => getClassNameById(params.value),
      editable: true,
      cellStyle: (params) => ({
        backgroundColor: params.data.id_origin != 0 ? 'yellow' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
  ];

  const getClassNameById = (id) => {
    // Encontra o centro de custo pelo ID
    const opcao = classExtrat.find((c) => c.id_class === id);
    return opcao ? opcao.class : ''; // Retorna o nome do centro
  };

  const handleSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIdLanc = selectedRows.length > 0 ? selectedRows[0].id_lanc : null;
    const selectedValueLanc = selectedRows.length > 0 ? selectedRows[0].value_reais : null;

    console.log(selectedIdLanc);
    setSelectedValueGrid2(selectedValueLanc);
    setSelectedLancIdGrid2(selectedIdLanc);
  };

  return (
    <>
      <div className="box-center1 bg-custom">
        <div className="card col-md-12 mx-auto">
          <div className="ag-theme-quartz ag-theme-custom" style={{ height: 370 }}>
            <AgGridReact
              localeText={AG_GRID_LOCALE_BR}
              rowData={conscBank}
              columnDefs={colDefs}
              rowSelection="unique"
              onSelectionChanged={handleSelectionChanged}
              pagination={true}
              rowStyle={(params) => ({ backgroundColor: params.data.selected ? 'green' : 'red' })}
            />
          </div>
        </div>
        <div className="form-group text-center mb-3" />
        <div className="modal-footer">
          <button type="button" className="btn btn-primary" disabled={loading} onClick={handleSave}>
            {loading ? 'Carregando...' : 'Salvar'}
          </button>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Grid2;
