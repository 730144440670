import React from 'react';
import "../../style/style.css"
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";


const ReconciliationTable = ({ data }) => {
  const columnDefs = [
    { headerName: 'Data', field: 'date' },
    { headerName: 'Descrição', field: 'description' },
    { headerName: 'Débito', field: 'debit' },
    { headerName: 'Crédito', field: 'credit' },
    { headerName: 'Saldo', field: 'balance' },
  ];

  const defaultColDef = {
    flex: 1,
    minWidth: 100,
    filter: true,
  };

  return (
    <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
      <AgGridReact
        columnDefs={columnDefs}
        rowData={data}
        defaultColDef={defaultColDef}
      />
    </div>
  );
};

export default ReconciliationTable;
