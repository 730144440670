import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import background from "../../assets/login1.png";
import logo from "../../assets/cropped-LOGO.png";
import axios from 'axios';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const Login = () => {
  const [cpf_user, setCpf_user] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const parsedURL = localStorage.getItem('urlParsed');

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      };
      const resposta = await axios.post(
        `${parsedURL}post/login`,
        {
          cpf: cpf_user,
          password: password
        },
        { headers }
      );
      const data = resposta.data.user_info;
      const token = resposta.data.token;
      console.log(data);
      if (data.active === 'S') {
        const currentTime = Date.now();
        localStorage.setItem('token', token);
        localStorage.setItem('typeUser', data.id_tipo_usuario);
        localStorage.setItem('idUser', data.id_usuario);
        localStorage.setItem('userName', data.nome);
        setLoading(false);
        localStorage.setItem('user_login_time', currentTime.toString());
        window.location.href = `${window.location.origin}/home`;
        //window.location.reload();
      } else {
        setLoading(false);
        toast.error('Usuario Bloqueado', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error('Usuario ou senha incorreto!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleLogin(event);
    }
  };

  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const cookieAccepted = localStorage.getItem('cookiesAccepted');
    if (!cookieAccepted) {
      setShowBanner(true);
    }
  }, []);

  const acceptCookies = () => {
    localStorage.setItem('cookiesAccepted', 'true');
    setShowBanner(false);
  };

  const formatCPF = (cpf) => {
    cpf = cpf.replace(/\D/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    //const formattedValue = formatCPF(value);

    setCpf_user(value);

  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <body className="my-login-page">
        <section className="h-100" style={{ backgroundImage: `url(${background})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}>
          <div className="container h-100">
            <div className="row justify-content-md-center h-100">
              <div className="card-wrapper">
                <div className="brand" >
                  <Link to='https://transtecworld.com/'><img src={logo}/></Link>
                </div>
                <div className="card fat card-fat" >
                  <div className="card-body">
                    <h5 className="card-title text-center">Login</h5>
                    <div onKeyDown={handleKeyDown}>
                      <div className="form-floating mb-3">
                        <input type="text" className="form-control" id="floatingInput" placeholder="111.111.111-11" onChange={handleChange} value={formatCPF(cpf_user)} maxLength={11} required/>
                        <label htmlFor="floatingInput">CPF</label>
                      </div>
                      <div className="form-floating position-relative">
                        <input type={showPassword ? "text" : "password"} className="form-control" id="floatingPassword" placeholder="Password" onChange={(e) => setPassword(e.target.value)} value={password} required/>
                        <label htmlFor="floatingPassword">Senha</label>
                        <span
                          onClick={togglePasswordVisibility}
                          className="position-absolute"
                          style={{ top: "50%", right: "10px", cursor: "pointer", transform: "translateY(-50%)" }}
                        >
                          {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                        </span>
                      </div>
                      <div className="form-group text-center mb-3" />
                      <div className="form-group text-center mb-3">
                        <button type="button" className="btn btn-outline-primary btnn" onClick={handleLogin} disabled={loading}>
                          {loading ? 'Carregando...' : 'Entrar'}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="footer">
                  Copyright &copy; 2023 &mdash; Transtec World
                </div>
              </div>
            </div>
            <ToastContainer
              position="bottom-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
            />
          </div>
        </section>
      </body>
      {(showBanner) && (<div className="fixed-bottom bg-dark text-white p-3">
        <div className="container d-flex justify-content-between align-items-center">
          <p className="mb-0">
            Este site usa cookies para melhorar sua experiência. Ao continuar, você aceita o uso de cookies.
          </p>
          <button
            onClick={acceptCookies}
            className="btn btn-primary ml-3"
          >
            Aceitar
          </button>
        </div>
      </div>)}
    </>
  );
};

export default Login;

