import React, { useState, useEffect, useRef } from 'react';
import { TextField, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Checkbox } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { format, isValid, parseISO} from 'date-fns';
import { ptBR } from 'date-fns/locale';

const Aba4 = ({ cont, setCont, paymentType, setPaymentType, installments, setInstallments, recurrence, setRecurrence, recurrenceType, setRecurrenceType, installmentIntervalType, setInstallmentIntervalType, dayInterval, setDayInterval, rowData, setRowData, errors,totalRowData, setTotalRowData}) => {

  const gridRef = useRef();
  

  useEffect(() => {
    if (rowData.length > 0) {
      calculateTotals();
    }
  }, [rowData]);

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value);
    setRowData([]);
  };

  const handleInstallmentsChange = (event) => {
    const value = event.target.value;
    setInstallments(value);
    updateInstallmentRowData(value);
  };

  const handleRecurrenceChange = (event) => {
    setRecurrence(event.target.value);
  };

  const handleRecurrenceTypeChange = (event) => {
    setRecurrenceType(event.target.value);
  };

  const handleInstallmentIntervalTypeChange = (event) => {
    setInstallmentIntervalType(event.target.value);
  };

  const handleDayIntervalChange = (event) => {
    setDayInterval(event.target.value);
  };

  const updateInstallmentRowData = (installments) => {
    if (!cont.data_vencimento) {
      console.error("Data de vencimento não está definida.");
      return; // Evita continuar se a data de vencimento não estiver definida
    }
  
    const amountPerInstallment = (convertCurrency(cont.valor_apg) / installments).toFixed(2);
    
    // Tentamos fazer o parse da data de vencimento em formato válido
    const parsedDueDate = isValid(new Date(cont.data_vencimento))
      ? new Date(cont.data_vencimento)
      : null;
  
    if (!parsedDueDate) {
      console.error("Data de vencimento inválida.");
      return; // Evita continuar se a data de vencimento for inválida
    }
  
    const newRowData = Array.from({ length: installments }, (_, index) => {
      const dueDate = new Date(parsedDueDate);
      if (installmentIntervalType === 'monthly') {
        dueDate.setMonth(dueDate.getMonth() + index);
      } else if (installmentIntervalType === 'daily' && dayInterval) {
        dueDate.setDate(dueDate.getDate() + index * parseInt(dayInterval, 10));
      }
      return {
        id: index + 1,
        dueDate: dueDate.toISOString().split('T')[0],
        amount: amountPerInstallment
      };
    });
    
    setRowData(newRowData);
  };

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  const convertCurrency = (currency) => {
    let numberString = currency.replace('R$', '').trim();
    numberString = numberString.replace(/\./g, '');
    numberString = numberString.replace(',', '.');
    const floatValue = parseFloat(numberString);
    return floatValue;
  };

  const calculateTotals = () => {
    const totalAmount = rowData.reduce((acc, item) => acc + parseFloat(item.amount || 0), 0);
    
    setTotalRowData([{
      dueDate: 'Total',
      amount: totalAmount
    }]);
  };

  const handleCellValueChange = () => {
    const updatedRowData = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      updatedRowData.push(node.data);
    });
    setRowData(updatedRowData);
    calculateTotals(); // Recalcular totais após a edição
  };

  const columns = [
    { 
      headerName: "Vencimento", 
      field: "dueDate", 
      editable: true,  
      width: 150,
      valueFormatter: (params) => {
        const date = parseISO(params.value); // Convertemos a string para Date
        if (isValid(date)) { // Garantimos que a data é válida antes de formatar
          return format(date, 'dd/MM/yyyy', { locale: ptBR });
        }
        return params.value; 
      }
    },
    { 
      headerName: "Valor", 
      field: "amount", 
      width: 150,
      valueFormatter: (params) => formatCurrency(params.value),
      editable: true 
    }
  ];

  return (
    <>
      <div className="box-center1">
        <div className="card col-md-12 mx-auto">
          <div className="row align-items-end">
            <div className="col-12">
              <div className="card-body">
                <FormControl component="fieldset">
                  <RadioGroup row aria-label="paymentType" name="paymentType" value={paymentType} onChange={handlePaymentTypeChange}>
                    <FormControlLabel value="cash" control={<Radio />} label="A Vista" />
                    <FormControlLabel value="installments" control={<Radio />} label="Parcelado" />
                    <FormControlLabel value="recurrence" control={<Radio />} label="Recorrência" />
                  </RadioGroup>
                  {errors.paymentType && <span className="text-danger">{errors.paymentType}</span>}
                </FormControl>
                <div><hr/></div>
                {paymentType === 'installments' && (
                  <>
                  <FormControl component="fieldset">
                      <FormLabel component="legend">Tipo de Intervalo</FormLabel>
                      <RadioGroup row aria-label="installmentIntervalType" name="installmentIntervalType" value={installmentIntervalType} onChange={handleInstallmentIntervalTypeChange}>
                        <FormControlLabel value="monthly" control={<Radio />} label="Mensal" />
                        <FormControlLabel value="daily" control={<Radio />} label="Por Dias" />
                      </RadioGroup>
                    </FormControl>
                    {installmentIntervalType === 'daily' && (
                      <TextField
                        label="Intervalo de Dias"
                        type="number"
                        value={dayInterval}
                        InputProps={{ inputProps: { min: 0} }}
                        onChange={handleDayIntervalChange}
                        placeholder="Ex: 5"
                        fullWidth
                      />
                    )}
                      <div><hr/></div>
                    <TextField
                      label="Quantidade de Parcelas"
                      type="number"
                      value={installments}
                      InputProps={{ inputProps: { min: 0} }}
                      onChange={handleInstallmentsChange}
                      fullWidth
                    />
                    <div className="ag-theme-quartz " style={{ height: '200px', width: '100%' }}>
                      <AgGridReact
                        localeText={AG_GRID_LOCALE_BR}
                        ref={gridRef}
                        columnDefs={columns}
                        rowData={rowData}
                        pinnedBottomRowData={totalRowData} // Linha de totais fixada na parte inferior
                        onCellValueChanged={handleCellValueChange} // Detecta mudanças e recalcula os totais
                      />
                    </div>
                  </>
                )}
                {paymentType === 'recurrence' && (
                  <>
                    <TextField
                      label="Quantidade de Recorrências"
                      type="number"
                      InputProps={{ inputProps: { min: 0} }}
                      value={recurrence}
                      onChange={handleRecurrenceChange}
                      fullWidth
                    />
                    <FormControl component="fieldset">
                      <FormLabel component="legend">Tipo de Recorrência</FormLabel>
                      <RadioGroup row aria-label="recurrenceType" name="recurrenceType" value={recurrenceType} onChange={handleRecurrenceTypeChange}>
                        <FormControlLabel value="monthly" control={<Radio />} label="Mensal" />
                        <FormControlLabel value="daily" control={<Radio />} label="Por Dias" />
                      </RadioGroup>
                    </FormControl>
                    {recurrenceType === 'daily' && (
                      <TextField
                        label="Intervalo de Dias"
                        type="number"
                        value={dayInterval}
                        InputProps={{ inputProps: { min: 0} }}
                        onChange={handleDayIntervalChange}
                        placeholder="Ex: 5"
                        fullWidth
                      />
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aba4;
