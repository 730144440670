import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { embedDashboard } from "@superset-ui/embedded-sdk";
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import axios from 'axios';

const Dash = () => {
    const login = async () => {
      try {
        const response = await axios.post('https://superset.transtecworld.com/api/v1/security/login', {
          username: 'guest',
          password: '7rans73cp1p',
          provider: 'db',
          refresh: true
        }, {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        });
        console.log('Login bem-sucedido:', response.data.access_token);
        return response.data.access_token
      } catch (error) {
        console.error('Erro ao fazer login:', error);
      }
    };
  const requestGuestToken = async () => {
      const tokken = await login()
      console.log(tokken)
      const response = await axios.post(
        'https://superset.transtecworld.com/api/v1/security/guest_token/',
        {
          "resources": [
            {
              "id": "cad1fdb2-b7bc-4e08-979c-2c8ee4a6ceba",
              "type": "dashboard",
            },
          ],
          "rls": [],
          "user": {
            "first_name": "Guest",
            "last_name": "User",
            "username": "guest",
          },
        },
        {
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${tokken}`,
          },
        }
      );
      console.log('Response:', response.data);
      return response.data.token
  };

  useEffect(() => {
    const embed = async () => {
      const token = await requestGuestToken();
      console.log(token)
      await embedDashboard({
        id: "cad1fdb2-b7bc-4e08-979c-2c8ee4a6ceba",
        supersetDomain: "https://superset.transtecworld.com",
        mountPoint: document.getElementById("dashboard"),
        fetchGuestToken: () => token,
        dashboardUiConfig: {
          hideTitle: true,
          hideChartControls: true,
          hideTab: true,
        },
      });

      var iframe = document.querySelector("iframe")
      if (iframe) {
        iframe.style.width = '100%'; // Set the width of the iframe
        iframe.style.minHeight = '100vw'; // Set the height of the iframe
      }
    };
    embed();
  }, []);

  return (
    <>
      <Navbar />
      <div id="dashboard" style={{ height: '100vh', width: '100vw' }}></div>    
      </>
  );
};

export default Dash;
