import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import axios from "axios";

const AddDocumento = () => {
  const navigate = useNavigate();
  const typeUser = parseInt(localStorage.getItem("typeUser"));
  const token = localStorage.getItem("token");
  const [files, setFiles] = useState([]);
  const parsedURL = localStorage.getItem('urlParsed');
  const [loading, setLoading] = useState(false);
  const [finalsEndPoint, setFinalsEndPoint] = useState([]);
  const [finalEndPoint, setFinalEndPoint] = useState('');

  const handleAppendFile = (event) => {
    const arquivosSelecionados = event.target.files;
    setFiles(Array.from(arquivosSelecionados));
  };

  useEffect(() => {
    handleGetEndPoint();
  }, []);

  const handleGetEndPoint = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/finalsEndPoint/archive`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        setFinalsEndPoint(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleFileUpload = async () => {
    if (files.length === 0) {
      toast.error('Nenhum arquivo selecionado', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    toast.info('Iniciando o cadastro do Extrato Bancario!', {
      position: "bottom-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    setLoading(true);

    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(
          `${parsedURL}post/upload_archive/${finalEndPoint}`,
          formData,
          {
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'multipart/form-data',
            },
          }
        );

        if (response.status === 200) {
          const idCelery = response.data;

          while (true) {
            const resp = await axios.get(
              `${parsedURL}get/status/post/upload_archive/${finalEndPoint}/${idCelery}`,
              {
                headers: {
                  'Authorization': `Bearer ${token}`,
                  'accept': 'application/json',
                },
              }
            );

            if (resp.status === 200 && resp.data !== null) {
              toast.success(`Arquivo ${file.name} integrado com sucesso!!`, {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              });
              break;
            }

            await new Promise(resolve => setTimeout(resolve, 2000));
          }
        } else {
          toast.error(`Erro ao integrar o arquivo ${file.name}`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } catch (error) {
        toast.error(`Erro ao concluir a operação para o arquivo ${file.name}!`, {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Navbar></Navbar>
      <div className="container box-center">
        <legend className="">Integração de Arquivos</legend>
        <div className="mb-3">
          <div className="col-md-12">
            <label htmlFor="emailfat" className="form-label hfx4">Tipo de Arquivo</label>
            <select
              className="form-select transparent-input form-select-sm"
              value={finalEndPoint}
              onChange={(e) => {
                console.log("Novo valor selecionado:", e.target.value);
                setFinalEndPoint(e.target.value);
              }}
            >
              <option>Selecione um tipo de arquivo</option>
              {finalsEndPoint && finalsEndPoint.map(option => (
                <option key={option.id_type} value={option.type}>{option.type}</option>
              ))}
            </select>
          </div>
          <label htmlFor="formFile" className="hfx my-2">Adicione aqui o seu arquivo:</label>
          <input className="form-control" type="file" id="file" onChange={handleAppendFile} multiple />
        </div>
        {loading && 
          <div className="d-flex align-items-center">
            <strong className='text-dark' role="status">Loading...</strong>
            <div className="spinner-border ms-auto text-dark" aria-hidden="true"></div>
          </div>}
        <div className="my-2">
          <button type="button" className="btn btn-success mx-2" onClick={handleFileUpload}>Salvar</button>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default AddDocumento;
