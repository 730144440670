import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const ClassExtr = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [classExtrat, setClassExtrat] = useState();

  useEffect(() => {
    handleGetServico();
  }, []);


  const handleGetServico= async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/servicos`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setClassExtrat(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetServicoId = async (params) => {
    try{
        const response = await axios.get(
            `${parsedURL}get/conf/servicos?id_servico=${params.data.id_servico}`,
            {
                headers: {
                  'accept': 'application/json',
                  'Authorization': `Bearer ${token}`
                },
              }
            );
            if (response.status === 200) {
              console.log(response.data);
              setClassExtrat(response.data.content);
            }
    } catch (error) {
        console.log(error);
    }
  }

  const colDefs = [
    { field: "servico", headerName: "Servico", filter: true, width: 250 },
    {
      field: "id_servico", headerName: "Editar", filter: false, width: 80, cellRenderer: (params) => (
        <button type="submit" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#centroedit" > <NavigateNextRoundedIcon /></button>
      )
    }
  ];

  return (
    <>
      <div className="box-center1 bg-custom">
        <div className="card col-md-12 mx-auto">
          <div className="ag-theme-quartz" style={{ height: 600 }}>
            <AgGridReact
              rowData={classExtrat}
              columnDefs={colDefs}
              rowSelection="unique"
            />
          </div>
        </div>
      </div>
      {/*BOTA DE CADASTRO*/}
      <div className="box-center1">
        <div className='card-body'>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#centrocad">Cadastrar Serviço<AddRoundedIcon /></button>
        </div>
      </div>
      {/*MODAL DE CADASTRO*/}
      <div class="modal fade" id="centrocad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">Cadastrar<AddRoundedIcon /></h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form>
                <div className="card col-md-12 mx-auto">
                  <div className="card-body">
                    <form class="row g-1">
                      <div class="col-12 form-floating mb-3">
                        <input type="text" class="form-control " id="floatingInput" placeholder="name@example.com" />
                        <label for="floatingInput">Centro</label>
                      </div>
                      <div class="col-12 form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label for="floatingInput">Fecha</label>
                      </div>
                    </form>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Limpar</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >Salvar</button>
            </div>
          </div>
        </div>
      </div>

      {/*MODAL EDITAR*/}
      <div class="modal fade" id="centroedit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">Editar Centro de Custo</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form>
                <div className="card col-md-12 mx-auto">
                  <div className="card-body bg">
                    <form class="row g-1">
                      <div class="col-12 form-floating mb-3">
                        <input type="text" class="form-control " id="floatingInput" placeholder="name@example.com" />
                        <label for="floatingInput">Centro</label>
                      </div>
                      <div class="col-12 form-floating mb-3">
                        <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com" />
                        <label for="floatingInput">Fecha</label>
                      </div>
                    </form>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-primary">Salvar alterações</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassExtr;