import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Button } from '@mui/material';
import "../../style/style.css";
import Navbar from "../navbar/navbar";
import FaturamentoModal from "./contas_modal";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import SearchIcon from '@mui/icons-material/Search';

const Contas = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idLanc = searchParams.get('idlanc');
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const gridRef = useRef();
  const today = new Date().toLocaleDateString('pt-BR'); 
  const [totalRowData, setTotals] = useState([]);
  const [calculateTotal, setCalculateTotal] = useState(false);

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };

  const getConsultFat = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/lanc/ListConts/NotCancel`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        setData(response.data.content);
        setCalculateTotal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getConsultFat();
    console.log(today);
  }, []);

  useEffect(() => {
    if (idLanc !== null) {
      const modalElement = document.getElementById('modal2');
      modalElement.classList.add('show');
      modalElement.style.display = 'block';
      modalElement.setAttribute('aria-modal', 'true');
      modalElement.setAttribute('role', 'dialog');
      document.body.classList.add('modal-open');
      const backdropElement = document.createElement('div');
      backdropElement.classList.add('modal-backdrop', 'fade', 'show');
      document.body.appendChild(backdropElement);
    }
  }, [idLanc]);

  const handleVerCNTRClick = () => {
    getConsultFat();
  };

  var filterParams = {
    
    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );
  
      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
  
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
  
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2024,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };

  const colDefs = [
    { 
      field: "lanc_os",
      headerName: "Lançamento", 
      filter: true,
      floatingFilter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "data_lanc", 
      headerName: "Data",
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      floatingFilter: true,
      width: 105 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "emp", 
      headerName: "Empresa", 
      floatingFilter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "client", 
      headerName: "Fornecedor", 
      filter: true,
      floatingFilter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "valor", 
      headerName: "Valor", 
      floatingFilter: true,
      valueFormatter: (params) => formatCurrency(params.value),
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "valor_apg", 
      headerName: "Valor a Pagar", 
      floatingFilter: true,
      valueFormatter: (params) => formatCurrency(params.value),
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "valueExtra", 
      headerName: "Valor Extra", 
      floatingFilter: true,
      valueFormatter: (params) => formatCurrency(params.value),
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "valueAprovado", 
      headerName: "Valor Aprovado", 
      floatingFilter: true,
      valueFormatter: (params) => formatCurrency(params.value),
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "data_data_emissao_nf", 
      headerName: "Emissão", 
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: filterParams,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "data_vencimento", 
      headerName: "Vencimento", 
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: filterParams,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "parcelamento", 
      headerName: "Parcelamento", 
      floatingFilter: true,
      filter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "status", 
      headerName: "Status", 
      floatingFilter: true,
      filter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "number_nf", 
      headerName: "Nota Fiscal", 
      floatingFilter: true,
      filter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    { 
      field: "nameUser", 
      headerName: "Usuario", 
      floatingFilter: true,
      filter: true,
      width: 150 ,
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
    {
      field: "id_lanc",
      headerName: "Consultar",
      floatingFilter: true,
      width: 150,
      cellRenderer: (params) => (params.node.rowPinned ? null :
        <Link to={`/contas/lancamentos?idlanc=${params.data.id_lanc}`}
            className="btn btn-outline-dark btn-sm" onClick={() => handleVerCNTRClick()}
            style={{
              padding: "2px .2rem",
              height: "25px",
              fontSize: "12px"
            }}
            >
          <SearchIcon />
        </Link>
      ),
      cellStyle: (params) => {
        if (params.node.rowPinned) {
          return { backgroundColor: "#86A6AC" };
        }

        if (params.data.status === 'PAGO') {
          return { backgroundColor: 'green' }; // Cor verde para status PAGO
        } else if (parseDate(params.data.data_vencimento) === parseDate(today)) {
          return { backgroundColor: 'yellow' }; // Cor amarela para vencimento hoje
        } else if (parseDate(params.data.data_vencimento) < parseDate(today)) {
          return { backgroundColor: 'red' }; // Cor vermelha para vencimento atrasado
        } else {
          return { backgroundColor: 'inherit' }; // Cor padrão para outras linhas
        }
      }

    },
  ];

  const getCurrentDate = () => {
    const date = new Date();
    console.log(date)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Janeiro é 0!
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}_${month}_${year}__${hours}_${minutes}`;
  };

  const exportToExcel = async () => {
    const rowData = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });
    console.log(rowData)

    try {
  
      const apiUrl = `${parsedURL}create_relatorio_fat/tela_contas`; // URL do seu endpoint para download
  
      const downloadResponse = await axios({
        method: 'POST',
        url: apiUrl,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
        },
        data: rowData,
      });
  
      console.log('Resposta recebida:', downloadResponse);
  
      // Extrair o nome do arquivo do cabeçalho content-disposition
      const contentDisposition = downloadResponse.headers['content-disposition'];
      const matches = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(contentDisposition);
      let fileName = matches && matches[1] ? matches[1] : `Contas a Pagar.xlsx`;
  
      // Substituir '/' por '-'
      if (fileName) {
        fileName = fileName.replace(/\//g, '-');
      }
  
      // Criar um URL temporário para o blob de dados
      const blobUrl = window.URL.createObjectURL(new Blob([downloadResponse.data]));
  
      // Criar um elemento de âncora para iniciar o download
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = fileName;
  
      // Simular um clique no link para iniciar o download
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
  
    } catch (error) {
      console.error('Erro ao enviar lançamentos ou baixar arquivo:', error);
    }

    //const columns = {
    //  lanc_os: "Lançamento",
    //  data_lanc: "Data",
    //  emp: "Empresa",
    //  client: "Cliente",
    //  valor: "Valor",
    //  data_data_emissao_nf: "Emissão",
    //  data_vencimento: "Vencimento",
    //  status: "Status"
    //};
//
    //const formattedData = rowData.map(row => {
    //  const formattedRow = {};
    //  Object.keys(row).forEach(key => {
    //    formattedRow[columns[key] || key] = row[key];
    //  });
    //  return formattedRow;
    //});
//
    //const worksheet = XLSX.utils.json_to_sheet(formattedData, { header: Object.values(columns) });
    //const workbook = XLSX.utils.book_new();
    //XLSX.utils.book_append_sheet(workbook, worksheet, "Faturamento");
//
    //const excelBuffer = XLSX.write(workbook, {
    //  bookType: "xlsx",
    //  type: "array",
    //});
    //
    //const data = new Blob([excelBuffer], { type: "application/octet-stream" });
    //saveAs(data, `faturamento_${getCurrentDate()}.xlsx`);
  };

  const calculateTotals = () => {
    const rowData = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      rowData.push(node.data);
    });
    const totalValorOficial = rowData.reduce((acc, item) => acc + (parseFloat(item.valor) || 0), 0);
    const totalValorApg = rowData.reduce((acc, item) => acc + (parseFloat(item.valor_apg) || 0), 0);
    const totalValorExtra = rowData.reduce((acc, item) => acc + (parseFloat(item.valueExtra) || 0), 0);
    const totalValorAprovd = rowData.reduce((acc, item) => acc + (parseFloat(item.valueAprovado) || 0), 0);

    //const totalValorPagm = rowData.reduce((acc, item) => acc + (parseFloat(item.valor_pagm) || 0), 0);

    setTotals([{
      client: 'Total',
      valor: totalValorOficial,
      valor_apg: totalValorApg,
      valueExtra: totalValorExtra ,
      valueAprovado: totalValorAprovd
    }]);

    //console.log(`Total Valor Original: R$ ${formatCurrency(totalValorOficial.toFixed(2))}`);
    //console.log(`Total Valor a Receber: R$ ${formatCurrency(totalValorReceb.toFixed(2))}`);
    //console.log(`Total Valor Pago: R$ ${formatCurrency(totalValorPagm.toFixed(2))}`);
  };

  const onFilterChanged = () => {
    calculateTotals();
  };

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  useEffect(() => {
    if (calculateTotal) {
      calculateTotals();
    }
  }, [calculateTotal, data]);

  return (
    <>
      <Navbar />
      <div className="row align-items-start g-1">
        <div className="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <form className="row g-3 py-2">
                  <legend className="form-label">Contas a Pagar</legend>
                  <div className="container">
                    <div className="box-center1 bg-custom">
                      <div className="card col-md-12 mx-auto">
                        <div className="ag-theme-quartz ag-theme-custom" style={{ height: 600 }}>
                          <AgGridReact
                          localeText={AG_GRID_LOCALE_BR}
                            ref={gridRef}
                            rowData={data}
                            columnDefs={colDefs}
                            rowSelection="unique"
                            pagination={true}
                            onFilterChanged={onFilterChanged}
                            pinnedBottomRowData={totalRowData}
                          />
                        </div>
                      </div>
                      <div><hr className="divider" /></div>
                      <div className="col-12 ">
                        <Link type="button" to={`/contas/lancamentos`} className="btn mx-2 btn-primary" data-bs-target="#modal2" data-bs-toggle="modal">Adicionar</Link>
                        <button type="button" className="btn mx-2 btn-success" onClick={exportToExcel}>Exportar Excel</button>
                        {/* <button type="reset" className="btn mx-2 btn-danger">Exportar PDF</button> */}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FaturamentoModal  functiongetUpdate={getConsultFat}/>
    </>
  );
};

export default Contas;
