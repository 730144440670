import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Search from "@mui/icons-material/Search.js";
import axios from "axios";
import "../../style/style.css"
import { useAuth } from "../../context/AuthContext";

const Aba1 = ({ cont, setCont, setTypeReco,errors }) => {
  const navigate = useNavigate();
  const { permissions } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idLanc = searchParams.get('idlanc');
  const userName = localStorage.getItem("userName");
  const idUser = localStorage.getItem("idUser");
  const idUsuarioString = String(idUser);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [empresas, setEmpresas] = useState([]);
  const [centers, setCenters] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [formsPag, setFormsPag] = useState([]);
  const [entidade, setEntidade] = useState([]);
  const [data, setData] = useState(getDataAtual());
  const [planCounts, setPlanCounts] = useState([]);
  const [statusRec, setStatusRec] = useState([]);
  const [editor, setEditor] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectType, setSelectType] = useState();
  const [disabledInfos, setDisableItens] = useState(false)
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    handleGetEmpresas();
    handleGetCenterCoust();
    handleGetBanks();
    handleGetFormsPags();
    //handleGetEntidade();
    handleGetPlanConts();
    handleGetStatusRe();
    //getFechaAtual();
    if (idLanc != null) {
      clearFat();
      setEditor(true);
      handleGetDataCont();
      console.log(idLanc);
    }
  }, [idLanc]);


  useEffect(() => {
    if (cont.id_status === 6) {
      setDisableItens(true)
    }
  }, [cont.id_status])

  const clearFat = () => {
    setCont({
      lanc_os: "",
      id_emp: "",
      fechamento: "",
      id__entidade: "",
      fornecedor: "",
      data_emissao_nf: "",
      data_vencimento: "",
      data_vencimentoOrig: "",
      valor: "",
      valor_receb: "",
      valor_pagm: "",
      id_bank: "",
      id_forma_pag: "",
      number_nf: "",
      doc_conciliacao: "",
      comment: "",
      id_conta: "",
      id_status: "",
      nome: "",
      id_usuario: "",
      user: "",
      value_nf: "",
      value_descI: "",
      value_descC: "",
      value_rentF: "",
      value_rentO: "",
      value_iss: "",
      value_final: ""
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    let formattedValue = value;

    // Format the value as Brazilian reais (R$) if it's a numeric field
    if (name === 'valor' || name === 'valor_apg' || name === 'valor_pagm') {
      // Remove non-digit characters
      const numericValue = parseFloat(value.replace(/\D/g, ''));

      // Format the value in Brazilian reais (R$)
      formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(numericValue / 100);
    }

    setCont(prevState => ({
      ...prevState,
      [name]: formattedValue
    }));
  };

  function getDataAtual() {
    const hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = String(hoje.getMonth() + 1).padStart(2, '0'); // Mês é base zero
    const dia = String(hoje.getDate()).padStart(2, '0');
    return `${ano}-${mes}-${dia}`;
  };

  function getFechaAtual() {
    const hoje = new Date();
    const ano = hoje.getFullYear();
    const mes = String(hoje.getMonth() + 1).padStart(2, '0'); // Mês é base zero
    const formattedDate = `${mes}/${ano}`;
    setCont(prevState => ({ ...prevState, 
      fechamento: formattedDate 
    }));
    console.log(formattedDate)
    return formattedDate;

  };

  const handleGetEntidade = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/entidade`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEntidade(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetPlanConts = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/plan_counts_contas`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setPlanCounts(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetFormsPags = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/forms_pag`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setFormsPag(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetBanks = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/data_banks`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setBancos(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleGetCenterCoust = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/center_counts`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setCenters(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetDataCont = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/lanc/cont?idlanc=${idLanc}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        const data = response.data.content;
        console.log(response.data.content);
        console.log(data.value_final)
        // Format values and dates
        const formattedData = {
          ...data,
          valor: formatCurrency(data.valor),
          valor_apg: formatCurrency(data.valor_apg),
          valor_pagm: formatCurrency(data.valor_pagm),
          value_nf: formatCurrency(data.value_nf),
          value_descI: formatCurrency(data.value_descI),
          value_descC: formatCurrency(data.value_descC),
          value_rentF: formatCurrency(data.value_rentF),
          value_rentO: formatCurrency(data.value_rentO),
          value_iss: formatCurrency(data.value_iss),
          value_final: formatCurrency(data.value_final),
          data_emissao_nf: formatDate(data.data_emissao_nf),
          data_vencimento: formatDate(data.data_vencimento),
          data_vencimentoOrig: formatDate(data.data_vencimentoOrig)
        };

        console.log(JSON.stringify(formattedData));
        setCont(formattedData);
        setTypeReco(formattedData.type_reco);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const formatCurrency = (value) => {
    if (typeof value === 'number') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value);
    } else {
      return value; // Retorna o valor sem formatação se não for um número
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return null; // Retorna null se a data for undefined ou null

    const [day, month, year] = dateString.split('/');
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  };

  const handleGetStatusRe = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/status_contas`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setStatusRec(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEmpresas = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/data_empresas`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEmpresas(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowDoubleClick = (params) => {
    if (selectType === 'fornecedor') {
      setCont(prevState => ({
        ...prevState,
        id__entidade: params.data.id_entidade,
        fornecedor: params.data.razao_social
      }));
    }
    setShowConfirmationModal(false);
  };

  const handleClickSeach = (tipoBusca) => {
    setSelectType(tipoBusca);
    handleGetEntidade();
    setShowConfirmationModal(true);
  };

  const handleOptionChange = (event) => {
    setCont(prevState => ({
      ...prevState,
      type_fech: event.target.value
    }));
  };

  const columns = [
    {
      field: "id_entidade",
      headerName: "ID",
      width: 80
    },
    {
      field: "razao_social",
      headerName: "Razão Social",
      width: 200,
      filter: true
    },
    {
      field: "fantasia",
      headerName: "Fantasia",
      width: 200,
      filter: true
    },
    {
      field: "cnpj_cpf",
      headerName: "CPF/CNPJ",
      width: 180,
      filter: true,
    },
    //{ field: "Tipo", headerName: "Tipo", width: 150 },
    {
      field: "cidade",
      headerName: "Cidade",
      width: 200,
      filter: true
    },
    {
      field: "estado",
      headerName: "UF",
      width: 80,
      filter: true
    }
  ];

  return (
    <>
      <div className="box-center1">
        <div className="card col-md-12 mx-auto">
          <div className="card-body">
            <form class="row g-3">
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
                <div class="col-md-4">
                  <label class="form-label">Lançamento</label>
                  <input type="text" readOnly={true} value={cont.lanc_os} class="form-control transparent-input" disabled={disabledInfos} />
                </div>
                <div class="col-md-3">
                  <label class="form-label" name="">Data</label>
                  <input
                    type="date"
                    readOnly={true}
                    name="date"
                    className="form-control transparent-input"
                    value={data}
                    onChange={(e) => setData(e.target.value)}
                  />
                </div>
                <div class="row-md-1 align-items-end "></div>
                <div class="row align-items-end ">
                  <label class="form-label ">Tipo Fech.</label>
                </div>
                <div class="row align-items-end ">

                  <div class="col-md-2">
                  <div class="form-check">
                    <label class="form-check-label"
                    ><input type="radio"
                      class="form-check-input" 
                      name="option"
                      value="option1"
                      checked={cont.type_fech === "option1"}
                      onChange={handleOptionChange} />Bruto</label>
                      </div>
                  </div>
                  <div class="col-md-2">
                  <div class="form-check">
                    <label class="form-check-label"
                    ><input type="radio"
                      class="form-check-input" 
                      name="type_fech"
                      value="option2"
                      checked={cont.type_fech === "option2"}
                      onChange={handleOptionChange} />Liquido</label>
                      </div>
                  </div>
                  <div class="col-md-2">
                  <div class="form-check">
                    <label class="form-check-label"
                    ><input type="radio"
                      class="form-check-input" 
                      name="option"
                      value="option3"
                      checked={cont.type_fech === "option3"}
                      onChange={handleOptionChange} />Parcela</label>
                      </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-end ">
              </div>
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
                <div className="col-md-4">
                  <label htmlFor="inputState" className="form-label">Empr.</label>
                  <select id="inputState" disabled={editor} className="form-select transparent-input" value={cont.id_emp} name="id_emp" onChange={handleChange}>
                    <option value="">Selecione a Empr.</option>
                    {empresas.map((opcao) => (
                      <option key={opcao.id_emp} value={opcao.id_emp}>{opcao.fantasia}</option>
                    ))}
                  </select>
                  {errors.id_emp && <span className="text-danger">{errors.id_emp}</span>}
                </div>
                <div class="col-md-4">
                  <label class="hfx form-label">Fornecedor</label>
                  <div class="input-group" disabled={disabledInfos} >
                    <input type="text" value={cont.fornecedor} readOnly={true} class="form-control transparent-input" />
                    <button type="button" onClick={() => handleClickSeach("fornecedor")} class="input-group-text btn-primary btn"><Search /></button>
                  </div>
                  {errors.fornecedor && <span className="text-danger">{errors.fornecedor}</span>}
                </div>
                <div class="col-md-1 justify-content-center">
                  <Link class="btn btn-success" to="/cadastro_entidade" target="_blank" rel="noopener noreferrer" >Cadastrar</Link>
                </div>
              </div>
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
                <div class="col-md-3">
                  <label for="numdocumento" class="form-label">Vencimento</label>
                  <input type="date" class="form-control transparent-input" value={cont.data_vencimento} name="data_vencimento" onChange={handleChange} disabled={disabledInfos} />
                {errors.data_vencimento && <span className="text-danger">{errors.data_vencimento}</span>}
                </div>
                <div class="col-md-3">
                  <label for="razaosocial" class="form-label ">Venc. Original</label>
                  <input type="date" readOnly={true} class="form-control transparent-input" value={cont.data_vencimentoOrig} name="data_vencimentoOrig" onChange={handleChange} disabled={disabledInfos} />
                </div>
              </div>
              <div class="col-md-3">
                <label for="razaosocial" class="form-label ">Valor Orig.</label>
                <input type="text" placeholder="R$ 0,00" readOnly={editor} class="form-control transparent-input" value={cont.valor} name="valor" onChange={handleChange} disabled={disabledInfos} />
                {errors.valor && <span className="text-danger">{errors.valor}</span>}
              </div>
              <div class="col-md-3">
                <label for="razaosocial" class="form-label ">Valor a Pagar</label>
                <input type="text" placeholder="R$ 0,00" class="form-control transparent-input" value={cont.valor_apg} name="valor_apg" onChange={handleChange} />
                {errors.valor_apg && <span className="text-danger">{errors.valor_apg}</span>}
                </div>
              <div class="col-md-3">
                <label for="razaosocial" class="form-label ">Valor Pago</label>
                <input type="text" placeholder="R$ 0,00" class="form-control transparent-input" value={cont.valor_pagm} name="valor_pagm" onChange={handleChange} />
                {errors.valor_pagm && <span className="text-danger">{errors.valor_pagm}</span>}
                </div>
              <div class="row-md-1 align-items-end "></div>
              <div class="row align-items-end ">
                <div class="col-md-4">
                  <label for="inputState" class="form-label ">Banco</label>
                  <select id="inputState" class="form-select transparent-input" value={cont.id_bank} name="id_bank" onChange={handleChange} disabled={disabledInfos} >
                    <option selected value='0'>Selecione o banco</option>
                    {bancos.map((opcao) => (
                      <option key={opcao.id_banco} value={opcao.id_banco}>{opcao.banco}</option>
                    ))}
                  </select>
                  {errors.id_bank && <span className="text-danger">{errors.id_bank}</span>}
                  </div>
                <div class="col-md-4">
                  <label for="inputState" class="form-label ">Forma</label>
                  <select id="inputState" class="form-select transparent-input" value={cont.id_forma_pag} name="id_forma_pag" onChange={handleChange} disabled={disabledInfos} >
                    <option selected value='0'>Selecione o tipo</option>
                    {formsPag.map((opcao) => (
                      <option key={opcao.id_pag} value={opcao.id_pag}>{opcao.pag_forma}</option>
                    ))}
                  </select>
                  {errors.id_forma_pag && <span className="text-danger">{errors.id_forma_pag}</span>}
                </div>
              </div>
              <div class="col-md-6">
                <label for="razaosocial" class="form-label ">Doc Conciliação</label>
                <input type="text" class="form-control transparent-input" value={cont.boleto} name="boleto" onChange={handleChange} />
              </div>
              <div class="col-md-4">
                <label for="inputState" class="form-label ">Conta</label>
                <select id="inputState" disabled={editor} class="form-select transparent-input" value={cont.id_conta} name="id_conta" onChange={handleChange}>
                  <option selected value='0'>Selecione a Conta</option>
                  {planCounts.map((opcao) => (
                    <option key={opcao.id_plan} value={opcao.id_plan}>{opcao.cont + " - " + opcao.descript}</option>
                  ))}
                </select>
                {errors.id_conta && <span className="text-danger">{errors.id_conta}</span>}
              </div>
              <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label">Descrição</label>
                <textarea class="form-control transparent-input" readOnly={editor} rows="5" name="comment" value={cont.comment} onChange={handleChange}></textarea>
              </div>
              <div class="col-md-4">
                <label for="inputState" class="form-label " >Status</label>
                <select id="inputState" class="form-select transparent-input" value={cont.id_status} name="id_status" onChange={handleChange} disabled={permissions.admin || permissions.dev ? false : disabledInfos}>
                  <option selected value='0'>Selecione</option>
                  {statusRec.map((opcao) => (
                    <option key={opcao.id_status} value={opcao.id_status}>{opcao.status}</option>
                  ))}
                </select>
                {errors.id_status && <span className="text-danger">{errors.id_status}</span>}
              </div>
              <div class="col-md-4">
                <label for="razaosocial" class="form-label ">Usuario</label>
                <input type="text" value={cont.nome != "" ? cont.nome : userName} readOnly={true} class="form-control transparent-input" />
              </div>
            </form>
          </div>
        </div>
        {/*<div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
              <button type="button" class="btn btn-primary" onClick={idLanc != null ? handleUpdate : handleSubmit}>Salvar</button>
          </div>*/}
      </div>
      <div className={`modal ${showConfirmationModal ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showConfirmationModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Selecione o Cliente</b></h5>
              <button type="button" className="close bg-danger text-white" aria-label="Close" onClick={() => setShowConfirmationModal(false)}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body ">
              <div className="ag-theme-quartz" style={{ height: 400, width: '100%' }}>
                <AgGridReact
                  rowData={entidade}
                  columnDefs={columns}
                  rowSelection="single"
                  onRowDoubleClicked={handleRowDoubleClick}
                />
              </div>
            </div>
            <div className="modal-footer ">
              <button type="button" className="btn btn-secondary" onClick={() => setShowConfirmationModal(false)}>Fechar</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

export default Aba1;