// src/context/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [permissions, setPermissions] = useState({});
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const verificaUsers = async () => {
      const typeUser = parseInt(localStorage.getItem("typeUser"));
      console.log(typeUser)
      const userTypes = {
        125: { admin: true },
        300: { fatAvaria: true },
        310: { fatSuper: true },
        320: { fatUser: true },
        500: { director: true },
        510: { directorFin: true },
        400: { manager: true },
        410: { managerFin: true},
        420: { managerOpe: true},
        600: { contas: true },
        610: { contasS: true },
        620: { contasSuper: true },
        130: { fatCont : true},
        220: { cliente: true },
        1616: { dev: true },
      };
      const userTypeConfig = userTypes[typeUser] || {};
      setPermissions(userTypeConfig);
      setLoaded(true);
    };
    verificaUsers();
  }, []);

  return (
    <AuthContext.Provider value={{ permissions, loaded }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
