import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

const Banco1 = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = localStorage.getItem("token");
    const parsedURL = localStorage.getItem('urlParsed');
    const [bancos,setBancos] = useState();
    const [banco, setBanco] = useState({
      "id_banco":"",
      "codBank":"",
      "banco":"",
      "agencia":"",
      "conta":"",
      "cont_ofx":"",
      "telefone":"",
      "gerencia":"",
      "limit":""
    });
  
    useEffect(() => {
        handleGetEmpresas();
      }, []);


      const handleGetEmpresas = async () => {
        try {
          const response = await axios.get(
            `${parsedURL}get/conf/data_banks`,
            {
              headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
              },
            }
          );
          if (response.status === 200) {
            console.log(JSON.stringify(response.data.content));
            setBancos(response.data.content);
          }
        } catch (error) {
          console.log(error);
        }
      }

      const handleGetEmpresaId = async (params) => {
        try{
            const response = await axios.get(
                `${parsedURL}get/conf/data_bank_id?id_bank=${params.data.id_banco}`,
                {
                    headers: {
                      'accept': 'application/json',
                      'Authorization': `Bearer ${token}`
                    },
                  }
                );
                if (response.status === 200) {
                  console.log(response.data);
                  setBanco(response.data.content);
                }
        } catch (error) {
            console.log(error);
        }

      }

      const colDefs = [
        { field: "codBank", headerName: "Cod Banco", filter: true, width: 120 },
        { field: "banco", headerName: "Banco", filter: true, width: 220 },
        { field: "agencia", headerName: "Agencia", filter: true, width: 120 },
        { field: "conta", headerName: "Conta", filter: true , width: 150},
        { field: "conta_ofx", headerName: "Conta Consciliação", filter: true , width: 150},
        { field: "telefone", headerName: "Fone", filter: true, width: 150},
        { field: "gerente", headerName: "Gerente", filter: true, width:150},
        { field: "limites", headerName: "Limite", filter: true , width:150},
        { field: "id_banco", headerName: "Editar", filter: true, width: 150, cellRenderer: (params) => (
            <button type="submit" class="btn btn-primary btn-sm" data-bs-toggle="modal" data-bs-target="#bancoEdit" onClick={() => handleGetEmpresaId(params)} ><NavigateNextRoundedIcon/></button>
        )}
      ];

    return (
          <>
           <div className="box-center1 bg-custom">
           <div className="card col-md-12 mx-auto">
           <div className="ag-theme-quartz" style={{ height: 600 }}>
          <AgGridReact
            rowData={bancos}
            columnDefs={colDefs}
            rowSelection="unique"
          />
        </div>
        </div>
        </div>
        {/*BOTA DE CADASTRO*/}
        <div className="box-center1">
            <div className='card-body'>
        <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#centrocad">Cadastrar Custo <AddRoundedIcon/></button>
        </div>
        
        </div>
        
        
        
        {/*MODAL DE CADASTRO*/}
                <div class="modal fade" id="centrocad" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
            <div class="modal-header">
                <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">Cadastrar Custo <AddRoundedIcon/></h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
            <form>
            <div className="card col-md-12 mx-auto">
            <div className="card-body">
                <form class="row g-1">
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control " id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Cod Banco</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Banco</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Agencia</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Conta</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Fone</label>
                </div>
                </form>
            </div>
            </div>
            </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Limpar e Fechar</button>
                <button type="button" class="btn btn-primary" data-bs-dismiss="modal" >Salvar</button>
            </div>
            </div>
        </div>
        </div>

        {/*MODAL EDITAR*/}
        <div class="modal fade" id="bancoEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5 text-dark" id="exampleModalLabel">Editar Banco</h1>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                <form>
            <div className="card col-md-12 mx-auto">
            <div className="card-body bg">
                <form class="row g-1">
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control " value={banco.codBank} id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Cod Banco</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control"  value={banco.banco} id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Banco</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control"  value={banco.agencia} id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Agencia</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" value={banco.conta} id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Conta</label>
                </div>
                <div class="col-12 form-floating mb-3">
                <input type="text" class="form-control" value={banco.fone}  id="floatingInput" placeholder="name@example.com"/>
                <label for="floatingInput">Fone</label>
                </div>
                </form>
            </div>
            </div>
            </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div>
                
                </div>
            </div>
            </div>
          </>
    );
  };
  
  export default Banco1;