// src/routes/RouteApp.js
import React, { Fragment } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { AuthProvider, useAuth } from '../context/AuthContext';
import Home from '../pages/home/home';
import CargoCadastro from '../pages/cargos/cargo_cadastro';
import CargoLista from '../pages/cargos/cargos_lista';
import Faturamento from '../pages/faturamento/faturamento';
import FuncionarioCadastro from '../pages/funcionario/funcionario_cadastro';
import FuncionarioLista from '../pages/funcionario/funcionario_lista';
import PermissoesCadastro from '../pages/permissoes/permissoes_cadastro';
import PermissoesLista from '../pages/permissoes/permissoes_lista';
import RDVCadastro from '../pages/rdv/rdv_cadastro';
import RDVDados from '../pages/rdv/rdv_dados';
import RDVEvento from '../pages/rdv/rdv_eventos';
import RDVLista from '../pages/rdv/rdv_lista';
import ServicoCadastro from '../pages/servico/servico_cadastro';
import ServicoLista from '../pages/servico/servico_lista';
import Login from '../pages/login/login';
import OrcamentoCompra from '../pages/faturamento/orcamento_compras';
import OrcamentoLista from '../pages/faturamento/orcamento_lista';
import CadastrosBanco from '../pages/manutencao/cadastro_banco';
import AddDocumento from '../pages/cadastro/add_documento';
import BancoConsult from '../pages/credit/banco_cons';
import Teste1 from '../pages/teste/teste2';
import CadEntidade from '../pages/cadastro/cad_entidade';
import EntConsulta from '../pages/cadastro/cad_entidade_consulta';
import BancoConsultAvaria from '../pages/credit/banco_cons_ava';
import Dash from '../pages/dashboards/dash';
import RelatorioPerContainer from '../pages/relatorios/relatoriopercontainer';
import RelatorioConciliacao from '../pages/relatorios/relatorioconciliacao';
import Contas from '../pages/contas_a_pagar/contas';
import RelatorioPerArmador from '../pages/relatorios/relatorioporcliente';
import Unauthorized from '../pages/unauthorized/unauthorized';
import SessionTimeout from './SessionTimeout'; // Importa o componente de expiração de sessão
import Aprovacoes from '../pages/aprovacoes_contas/contas';
import SaldosBancos from '../pages/saldos_bancarios/SaldosBancos';
import CompanyInfo from '../pages/company_info/company_info';
import BancoConsultContas from '../pages/debit/banco_cons';

const Private = ({ Item, requiredPermissions }) => {
  const { permissions, loaded } = useAuth();
  const token = localStorage.getItem('token');

  if (!loaded) {
    return <div>Loading...</div>;
  }

  if (!token) {
    return <Navigate to="/login" />;
  }

  const hasPermission = requiredPermissions.some(permission => permissions[permission]);

  if (!hasPermission) {
    return <Navigate to="/unauthorized" />;
  }

  return <Item />;
};

const RouteApp = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
      <SessionTimeout>
        <Fragment>
          <Routes>
            {/* Geral */}
            <Route path="*" element={<Private Item={Home} 
            requiredPermissions={['admin', 'fatUser', 'director', 'manager', 'dev','directorFin','fatSuper','managerOpe']} />} />
            <Route path="/unauthorized" element={<Unauthorized />} />

            <Route path="/login" element={<Login />} />
            <Route path="/home" element={<Private Item={Home} 
            requiredPermissions={['admin', 'fatUser', 'director', 'manager', 'dev','directorFin','fatCont','fatSuper','managerOpe']} />} />

            {/* Tesouraria */}
            <Route path="/saldos_bancarios" element={<Private Item={SaldosBancos} 
            requiredPermissions={['dev','admin','manager','directorFin' ,'fatCont']}/>}/>

            {/* Contas a Receber */}
            <Route path="/faturamento" element={<Private Item={Faturamento} 
            requiredPermissions={['admin', 'director', 'dev', 'fatUser','fatSuper','manager','directorFin','fatCont','managerOpe']} />} />
            <Route path="/conciliacao_bancaria" element={<Private Item={BancoConsult} 
            requiredPermissions={['admin', 'director', 'dev', 'fatUser','fatSuper','manager','directorFin' ,'fatCont']} />} />
            <Route path="/conciliacao_bancaria_avaria" element={<Private Item={BancoConsultAvaria} 
            requiredPermissions={['admin', 'director', 'dev', 'fatUser','fatSuper','manager','directorFin' ,'fatCont']} />} />

            {/* Contas a Pagar */}
            <Route path="/contas/lancamentos" element={<Private Item={Contas} 
            requiredPermissions={['dev','admin','manager','directorFin' ,'fatCont']} />} />
            <Route path="/contas/pagamentos_contas" element={<Private Item={Aprovacoes} 
            requiredPermissions={['dev','admin','manager','directorFin' ,'fatCont']}/>}/>
             <Route path="/contas/conciliacao_bancaria" element={<Private Item={BancoConsultContas} 
             requiredPermissions={['dev','admin','manager','directorFin' ,'fatCont']}/>}/>

            {/* Cadastros */}
            <Route path="/cadastro_arquivos" element={<Private Item={AddDocumento} 
            requiredPermissions={['admin','manager', 'dev']} />} />
            <Route path="/cadastro_entidade" element={<Private Item={CadEntidade} 
            requiredPermissions={['admin', 'manager', 'dev', 'fatUser','fatSuper','fatCont']} />} />
            <Route path="/consulta_entidade" element={<Private Item={EntConsulta} 
            requiredPermissions={['admin', 'manager', 'dev', 'fatUser','fatSuper','fatCont','managerOpe']} />} />

            {/* Relatórios */}
            <Route path="/relatorios/container_fat" element={<Private Item={RelatorioPerContainer} 
            requiredPermissions={['admin', 'director', 'dev', 'fatUser','fatSuper','manager','directorFin' ,'fatCont','managerOpe']} />} />
            <Route path="/relatorios/conciliacao_bancaria" element={<Private Item={RelatorioConciliacao} 
            requiredPermissions={['admin', 'director', 'dev', 'fatUser','fatSuper','manager' ,'directorFin','fatCont','managerOpe']} />} />
            <Route path="/relatorios/receita_armador" element={<Private Item={RelatorioPerArmador} 
            requiredPermissions={['admin', 'director', 'dev', 'fatUser','fatSuper','manager','directorFin','fatCont','managerOpe']} />} />
            <Route path="/dashboards" element={<Private Item={Dash} 
            requiredPermissions={['admin', 'manager','dev', 'director','directorFin','managerOpe']} />} />

            {/* Manutenção */}
            <Route path="/manutencao" element={<Private Item={CadastrosBanco} 
            requiredPermissions={['admin', 'dev','manager']} />} />
            <Route path="/info" element={<Private Item={CompanyInfo} 
            requiredPermissions={['admin', 'dev','manager','managerOpe']}/>}/>

            <Route path="/teste1" element={<Private Item={Teste1} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/cargo_cadastro" element={<Private Item={CargoCadastro} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/cargo_lista" element={<Private Item={CargoLista} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/funcionario_cadastro" element={<Private Item={FuncionarioCadastro} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/funcionario_lista" element={<Private Item={FuncionarioLista} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/permissoes_cadastro" element={<Private Item={PermissoesCadastro} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/permissoes_lista" element={<Private Item={PermissoesLista} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/rdv_cadastro" element={<Private Item={RDVCadastro} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/rdv_dados" element={<Private Item={RDVDados} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/rdv_eventos" element={<Private Item={RDVEvento} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/rdv_lista" element={<Private Item={RDVLista} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/servico_cadastro" element={<Private Item={ServicoCadastro} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/servico_lista" element={<Private Item={ServicoLista} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/orcamento_compra" element={<Private Item={OrcamentoCompra} requiredPermissions={['admin', 'dev']} />} />
            <Route path="/orcamento_lista" element={<Private Item={OrcamentoLista} requiredPermissions={['admin', 'dev']} />} />
          </Routes>
        </Fragment>
      </SessionTimeout>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default RouteApp;
