import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";

const RDVCadastro = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar></Navbar>
        
        </>
  );
};

export default RDVCadastro;