import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import { Checkbox } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';


const RDVLista = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  
   
  const getConsultaOS = async () => {
    try {
      const response = await axios.get("https://www.balldontlie.io/api/v1/teams");
      setData(response.data.data.map(row => ({ ...row, selected: false })));
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    getConsultaOS();
  }, []);

  const handleCheckboxChange = (id) => {
    setData((prevData) => {
      const updatedData = prevData.map((row) =>
        row.id === id ? { ...row, selected: !row.selected } : row
      );
      return updatedData;
    });
  };

  const handleVerCNTRClick = (id) => {
    // Implemente a lógica desejada quando o botão "Ver CNTR" é clicado
    console.log(`Botão "Ver CNTR" clicado para a linha com ID ${id}`);
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 90,
      renderCell: (params) => (
        <Checkbox
          checked={params.row.selected}
          onChange={() => handleCheckboxChange(params.row.id)}
        />
      ),
    },
    { field: "city", headerName: "City", width: 150 },
    { field: "abbreviation", headerName: "Abbreviation", width: 150 },
    { field: "conference", headerName: "Conference", width: 150 },
    { field: "division", headerName: "Division", width: 150 },
    {
      field: "verCNTR",
      headerName: "Ver CNTR",
      width: 150,
      renderCell: (params) => (
        <button type="button" class="btn btn-outline-primary" data-bs-target="#" data-bs-toggle="modal" onClick={() => handleVerCNTRClick(params.row.id)}>
         <SearchIcon/>
        </button>
      ),
    },
  ];

  const rows = data.map((row) => ({
    id: row.id,
    abbreviation: row.abbreviation,
    city: row.city,
    conference: row.conference,
    division: row.division,
  }));

  console.log(data);
    return (
        <>
       <Navbar></Navbar>
    
    <div className="box-center"><div className=" rounded card">
    <div class="container">
            <div class="row">    
                <div class="col-xs-8 col-xs-offset-2 py-2">
                    <div class="input-group rounded">
                        <div class="input-group-btn search-panel">
                            <button type="button" class="btn btn-primary dropdown-toggle rounded mx-2" data-toggle="dropdown">
                                <span id="search_concept">Filtro</span> <span class="caret"></span>
                            </button>
                        </div>
                        <input type="hidden" name="search_param" value="all" id="search_param"/>         
                        <input type="text" class="form-control rounded" name="x" placeholder="Pesquisar...."/>
                        <span class="input-group-btn">
                            <button class="btn btn-primary rounded mx-2" type="button"><span>Pesquisar</span></button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    <div>
    <div  className="my-3 transparent-input" style={{ height:593, width: '100%' }}>
      <DataGrid rows={rows} columns={columns} pageSize={5} />
    </div>

    </div>
    </div>
    
    </div>
        </>
  );
};

export default RDVLista;