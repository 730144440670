import React, { useState } from 'react';
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';

const Aba3 = ({ cont, setCont, typeReco }) => {
  const parsedURL = localStorage.getItem('urlParsed');
  const idLanc = new URLSearchParams(window.location.search).get('idlanc');
  console.log(idLanc)
  
  const calculateValorLiquido = (updatedCont) => {
    const {
      value_descI,
      value_descC,
      value_rentF,
      value_rentO,
      value_iss
    } = updatedCont;

    const valor = !idLanc ? updatedCont.valor : updatedCont.value_nf;
    const valorNumeric = parseFloat(valor.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
    const descINumeric = parseFloat(value_descI.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
    const descCNumeric = parseFloat(value_descC.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
    const rentFNumeric = parseFloat(value_rentF.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
    const rentONumeric = parseFloat(value_rentO.replace(/[^\d,]/g, '').replace(',', '.')) || 0;
    const issNumeric = parseFloat(value_iss.replace(/[^\d,]/g, '').replace(',', '.')) || 0;

    
    const valorLiquido = valorNumeric - descINumeric - descCNumeric - rentFNumeric - rentONumeric - issNumeric;
    console.log(valorLiquido)

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL'
    }).format(valorLiquido);
  };

  const formatCurrency = (value) => {
    if (typeof value === 'number') {
      return new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(value);
    } else {
      return value; // Retorna o valor sem formatação se não for um número
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;

    let formattedValue = value;

    if (name === 'valor' || name === 'valor_apg' || name === 'valor_pagm' ||
      name === 'value_nf' || name === 'value_descI' || name === 'value_descC' ||
      name === 'value_rentF' || name === 'value_rentO' || name === 'value_iss' ||
      name === 'value_final'
    ) {
      const numericValue = parseFloat(value.replace(/\D/g, ''));

      // Format the value in Brazilian reais (R$)
      formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(numericValue / 100);
    }
    console.log(formattedValue)

   if (idLanc){
    setCont(prevState => {
      const updatedCont = {
        ...prevState,
        [name]: formattedValue
      };
      return {
        ...updatedCont,
        value_final: calculateValorLiquido(updatedCont)
      };
    });
   }
   else{
    setCont(prevState => {
      const updatedCont = {
        ...prevState,
        [name]: formattedValue
      };
      return {
        ...updatedCont,
        valor_apg: calculateValorLiquido(updatedCont),
        value_final: calculateValorLiquido(updatedCont)
      };
    });
   }
  };

  return (
    <>
      <div className="box-center1">
        <div className="card col-md-12 mx-auto">
          <div className="card-body">
            <div className="row-md-1 align-items-end "></div>
            <div className="col-md">
              <label htmlFor="razaosocial" className="form-label ">NF / Doc</label>
              <input type="text" className="form-control transparent-input" name="number_nf" value={cont.number_nf} onChange={handleChange} />
            </div>
            <div className="col-md">
              <label htmlFor="numdocumento" className="form-label">Emissão NF</label>
              <input type="date" className="form-control transparent-input" value={cont.data_emissao_nf} name="data_emissao_nf" onChange={handleChange} />
            </div>
            <div className="col-md ">
              <label htmlFor="razaosocial" className="form-label ">Valor da NF</label>
              <input type="text" placeholder="R$ 0,00" className="form-control transparent-input" value={!idLanc ? cont.valor : cont.value_nf } name={!idLanc ? "valor" :"value_nf"} onChange={handleChange} />
            </div>
            <div className="col-md ">
              <label htmlFor="razaosocial" className="form-label ">Desc. Inc.</label>
              <input type="text" placeholder="R$ 0,00" className="form-control transparent-input" value={cont.value_descI} name="value_descI" onChange={handleChange} />
            </div>
            <div className="col-md ">
              <label htmlFor="razaosocial" className="form-label ">Desc. Cond.</label>
              <input type="text" placeholder="R$ 0,00" className="form-control transparent-input" value={cont.value_descC} name="value_descC" onChange={handleChange} />
            </div>
            <div className="col-md ">
              <label htmlFor="razaosocial" className="form-label ">Retenção Federal</label>
              <input type="text" placeholder="R$ 0,00" className="form-control transparent-input" value={cont.value_rentF} name="value_rentF" onChange={handleChange} />
            </div>
            <div className="col-md ">
              <label htmlFor="razaosocial" className="form-label ">Outras Retenções</label>
              <input type="text" placeholder="R$ 0,00" className="form-control transparent-input" value={cont.value_rentO} name="value_rentO" onChange={handleChange} />
            </div>
            <div className="col-md ">
              <label htmlFor="razaosocial" className="form-label ">I.S.S Retido</label>
              <input type="text" placeholder="R$ 0,00" className="form-control transparent-input" value={cont.value_iss} name="value_iss" onChange={handleChange} />
            </div>
            <div className="col-md ">
              <label htmlFor="razaosocial" className="form-label ">Valor Liquido</label>
              <input type="text" placeholder="R$ 0,00" className="form-control transparent-input" value={!idLanc ? cont.valor_apg : cont.value_final } name={!idLanc ? "valor_apg" :"value_final"} readOnly />
            </div>
          </div>
          <div className="card-body">
          </div>
        </div>
      </div>
    </>
  );
};

export default Aba3;
