import React, { useEffect, useState } from 'react';
import "../../style/style.css";
import Navbar from '../navbar/navbar';
import axios from "axios";

const SaldosBancos = () => {
const [bancos,setBancos] = useState([]);
const token = localStorage.getItem("token");
const parsedURL = localStorage.getItem('urlParsed');

useEffect(() => {
    getConsultSaldos();
},[])

const formatCurrency = (value) => {
  const numericValue = parseFloat(value);
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
};

const getConsultSaldos = async () => {
    try {
        const response = await axios.get(
        `${parsedURL}post/bancos/saldo_bancario`,
        {
            headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
            },
        }
        );
        if (response.status === 200) {
        console.log(response.data.content)
        setBancos(response.data.content)
        }
    } catch (error) {
        console.log(error);
    }
    };


  return (
    <>
    <Navbar/>
    <h1 className="text-center my-4">Saldos Bancários</h1>
    <div className="container">
      <div className="row">
        {bancos.map((banco) => (
          <div key={banco.idsald} className="col-md-4 col-sm-6 mb-4">
            <div className="card">
            <div className="card-body text-center">
                <img 
                  src={banco.logoUrl} 
                  alt={`Logo do ${banco.banco}`} 
                  className="img-fluid mb-3"
                  style={{ maxHeight: '50px' }} 
                />
                <h5 className="card-title">{banco.banco}</h5>
                <p className="card-text">AG: {banco.agencia} Conta: {banco.conta}</p>
                {banco.value === 0 ? (
                  <p className="card-text">Saldo: {formatCurrency(banco.value_ofx)}</p>
                ) : (
                  <>
                    <p className="card-text">Saldo Inicial: {formatCurrency(banco.value_init)}</p>
                    <p className="card-text">Cred: {formatCurrency(banco.value_credit)} / Deb: {formatCurrency(banco.value_debit)}</p>
                    <p className="card-text">Saldo Final: {formatCurrency(banco.value)}</p>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default SaldosBancos;