import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";

const DataFat = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [formState, setFormState] = useState({
    razao_social: "",
    fantasia: "",
    cnpj_cpf: "",
    inscricao_estadual: "",
    logradouro: "",
    cep: "",
    numero: "",
    cidade: "",
    doc_type: "",
    obs: "",
    estado: "",
    telefone: "",
    email: "",
    telefone_financeiro: "",
    email_financeiro: "",
    tipo: ""
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  return (
    <>
      
      <div className="box-center1">
            <legend className="form-label p-2">Dados para Fatura</legend>
            <form class="row g-3" >
              <div class="col-md-2">
                <label for="razaosocial" class="form-label ">Alíquota</label>
                <input type="percent" class="form-control transparent-input" />
              </div>
              <div class="col-md-2">
                <label for="razaosocial" class="form-label ">PIS</label>
                <input type="percent" class="form-control transparent-input" />
              </div>
              <div class="col-md-2">
                <label for="razaosocial" class="form-label ">COFINS</label>
                <input type="percent" class="form-control transparent-input" />
              </div>
              <div class="col-md-2">
                <label for="razaosocial" class="form-label ">IR</label>
                <input type="percent" class="form-control transparent-input" />
              </div>
              <div class="col-md-2">
                <label for="razaosocial" class="form-label ">INSS</label>
                <input type="percent" class="form-control transparent-input" />
              </div>
              <div class="col-md-2">
                <label for="razaosocial" class="form-label ">CSLL</label>
                <input type="percent" class="form-control transparent-input" />
              </div>
              <div class="col-md-2">
                <label for="razaosocial" class="form-label ">ISSQN</label>
                <input type="percent" class="form-control transparent-input" />
              </div>
            </form>
          </div>
    </>
  );
};

export default DataFat;
