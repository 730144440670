import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import { format, isValid } from 'date-fns';
import { ptBR } from 'date-fns/locale';

const ReparcelamentoModal = ({ valorTotal, onClose }) => {
  const [installments, setInstallments] = useState(1);
  const [rowData, setRowData] = useState([]);
  const [installmentIntervalType, setInstallmentIntervalType] = useState('monthly');
  const [totalRowData, setTotals] = useState([]);
  const [calculateTotal, setCalculateTotal] = useState(false);
  const gridRef = useRef();

  useEffect(() => {
      updateInstallmentRowData(installments);
  }, [installments]);

  const updateInstallmentRowData = (installments) => {
      const initialDate = new Date();
      const amountPerInstallment = (convertCurrency(valorTotal) / installments).toFixed(2);
      const newRowData = Array.from({ length: installments }, (_, index) => {
          const dueDate = new Date(initialDate);
          if (installmentIntervalType === 'monthly') {
              dueDate.setMonth(initialDate.getMonth() + index);
          }
          return {
              id: index + 1,
              dueDate: dueDate.toISOString().split('T')[0],
              amount: amountPerInstallment
          };
      });
      setRowData(newRowData);
      calculateTotals(newRowData); 
  };

  const formatCurrency = (value) => {
      const numericValue = parseFloat(value);
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  const convertCurrency = (currency) => {
      let numberString = currency.toString().replace(/\./g, '').replace(',', '.');
      return parseFloat(numberString);
  };

  const columns = [
      { 
          headerName: "Vencimento", 
          field: "dueDate", 
          width: 150,
          valueFormatter: (params) => {
              if (isValid(new Date(params.value))) {
                  return format(new Date(params.value), 'dd/MM/yyyy', { locale: ptBR });
              }
              return params.value;
          },
          editable:(params) =>  params.node.rowPinned ? false : true,

      },
      { 
          headerName: "Valor", 
          field: "amount", 
          width: 150,
          valueFormatter: (params) => formatCurrency(params.value),
          editable:(params) =>  params.node.rowPinned ? false : true,
      }
  ];

  const calculateTotals = (rowData) => {
    const totalParcelamento = rowData.reduce((acc, item) => acc + (parseFloat(item.amount) || 0), 0);
    setTotals([{
      dueDate: 'Total',
      amount: totalParcelamento,
    }]);
  };

  const handleCellValueChange = () => {
    const updatedRowData = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      updatedRowData.push(node.data);
    });
    calculateTotals(updatedRowData); // Recalcula os totais após a edição
  };

  const handleInstallmentsChange = (e) => {
    const newInstallments = e.target.value;
    setInstallments(newInstallments);
    updateInstallmentRowData(newInstallments); // Atualiza os dados e recalcula imediatamente
  };

  return (
    <div className="modal fade show" style={{ display: 'block' }}>
    <div className="modal-dialog modal-l modal-dialog-scrollable">
        <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5">Reparcelar</h1>
                <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className="modal-body">
            <div  class="col-md-3">
            <label for="razaosocial" class="form-label ">Qtd de Parcelas</label>
                <input
                    class="form-control transparent-input"
                    type="number"
                    value={installments}
                    InputProps={{ inputProps: { min: 1 } }}
                    onChange={handleInstallmentsChange}
                />
                </div>
                <div className="ag-theme-quartz" style={{ height: '200px', width: '100%', marginTop: '20px' }}>
                    <AgGridReact
                        ref={gridRef}
                        columnDefs={columns}
                        rowData={rowData}
                        pinnedBottomRowData={totalRowData}
                        onCellValueChanged={handleCellValueChange}
                    />
                </div>
            </div>
            <div className="modal-footer">
                <Button variant="outlined" onClick={onClose}>Fechar</Button>
            </div>
        </div>
    </div>
</div>
  );
};

const Aba2 = ({}) => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = localStorage.getItem("token");
    const parsedURL = localStorage.getItem('urlParsed');
    const idLanc = searchParams.get('idlanc');
    const [dataParcela, setDataParcela] = useState(
        {
            valor: 0,
            valor_apg: 0,
            id_parcela: 0,
            qtd_parc: 0
        }
    );
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    useEffect(() => {
        handleGetDataParcela();
      }, [idLanc]);
    

    const handleGetDataParcela = async () => {
        try {
          const response = await axios.get(
            `${parsedURL}get/buscar_parcelas?idCont=${idLanc}`,
            {
              headers: {
                'accept': 'application/json',
                'Authorization': `Bearer ${token}`
              },
            }
          );
          if (response.status === 200) {
            const data = response.data.content;
            console.log(response.data.content);
            setDataParcela(data);
          }
        } catch (error) {
          console.log(error);
        }
      };

      const formatCurrency = (value) => {
        if (typeof value === 'number') {
          return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
          }).format(value);
        } else {
          return value;
        }
      };
    

    return (
        <>
            <div className="box-center1">
                <div className="card col-md-12 mx-auto">
                    <div className="card-body">
                        <div class="row align-items-end ">
                            <div class="col-md-6">
                            <label for="numdocumento" class="form-label">Valor Bruto</label>
                            <input type="text" readOnly={true} class="form-control transparent-input" value={formatCurrency(dataParcela.valor)} />
                            </div>
                            <div class="col-md-6">
                            <label for="razaosocial" class="form-label ">Valor Liquido</label>
                            <input type="text" readOnly={true} class="form-control transparent-input" value={formatCurrency(dataParcela.valor_apg)} />
                            </div>
                        </div>
                        <div class="row align-items-end ">
                            <div class="col-md-6">
                            <label for="numdocumento" class="form-label">Parcela</label>
                            <input type="text" readOnly={true} class="form-control transparent-input" value={dataParcela.id_parcela}/>
                            </div>
                            <div class="col-md-6">
                            <label for="razaosocial" class="form-label ">Qtd de Parcelas</label>
                            <input type="text" readOnly={true} class="form-control transparent-input" value={dataParcela.qtd_parc} />
                            </div>
                        </div>
                        <hr className="divider" />
                        <button type="button" className="btn btn-outline-primary" onClick={handleOpenModal}>
                            Reparcelar
                        </button>
                    </div>
                </div>
                {openModal && (
                      <ReparcelamentoModal
                          valorTotal={dataParcela.valor_apg}
                          onClose={handleCloseModal}
                      />
                  )}
            </div>
        </>
    );
}

export default Aba2;
