import React, { useState, useEffect } from "react";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import axios from "axios";
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import Banco1 from "./banco";
import CentroCusto from "./centro_custo";
import FormasPag from "./formas_pag";
import PlanoContas from "./plano_contas";
import Empresas from "./empresa";
import ClassExtr from "./class_extrat";
import StatusReceita from "./status_receita";

const CadastrosBanco = () => {

  return (
        <>
        <Navbar></Navbar>
        <div className="box-center bg-custom">
        <nav>
        <div class="nav nav-tabs" id="nav-tab" role="tablist">
                <button class="nav-link active form-label" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-empresas" type="button" role="tab" aria-controls="nav-empresas" aria-selected="true">Empresas</button>
                <button class="nav-link form-label" id="nav-banco-tab" data-bs-toggle="tab" data-bs-target="#nav-banco" type="button" role="tab" aria-controls="nav-banco" aria-selected="false">Banco</button>
                <button class="nav-link form-label" id="nav-centrocusto-tab" data-bs-toggle="tab" data-bs-target="#nav-centrocusto" type="button" role="tab" aria-controls="nav-centrocusto" aria-selected="false">Centro de Custo</button>
                <button class="nav-link form-label" id="nav-foxrmaspag-tab" data-bs-toggle="tab" data-bs-target="#nav-formaspag" type="button" role="tab" aria-controls="nav-formaspag" aria-selected="false">Formas de pagamento</button>
                <button class="nav-link form-label" id="nav-planocontas-tab" data-bs-toggle="tab" data-bs-target="#nav-planocontas" type="button" role="tab" aria-controls="nav-planocontas" aria-selected="false">Plano de contas</button>
                <button class="nav-link form-label" id="nav-classextrat-tab" data-bs-toggle="tab" data-bs-target="#nav-classextrat" type="button" role="tab" aria-controls="nav-classextrat" aria-selected="false">Classificação/Serviço</button>
                <button class="nav-link form-label" id="nav-statusreceita-tab" data-bs-toggle="tab" data-bs-target="#nav-statusreceita" type="button" role="tab" aria-controls="nav-statusreceita" aria-selected="false">Status Receita</button>


            </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
            <div class="tab-pane fade show active " id="nav-empresas" role="tabpanel" aria-labelledby="nav-1-tab" tabindex="0">
            <Empresas></Empresas>
            </div>
            <div class="tab-pane fade" id="nav-banco" role="tabpanel" aria-labelledby="nav-banco-tab" tabindex="0">
            <Banco1></Banco1>
            </div>
            <div class="tab-pane fade" id="nav-centrocusto" role="tabpanel" aria-labelledby="nav-centrocusto-tab" tabindex="0">
            <CentroCusto></CentroCusto>
            </div>
            <div class="tab-pane fade" id="nav-formaspag" role="tabpanel" aria-labelledby="nav-formaspag-tab" tabindex="0">
            <FormasPag></FormasPag>
            </div>
            <div class="tab-pane fade" id="nav-planocontas" role="tabpanel" aria-labelledby="nav-planocontas-tab" tabindex="0">
            <PlanoContas></PlanoContas>
            </div>
            <div class="tab-pane fade" id="nav-classextrat" role="tabpanel" aria-labelledby="nav-classextrat-tab" tabindex="0">
            <ClassExtr></ClassExtr>
            </div>
            <div class="tab-pane fade" id="nav-statusreceita" role="tabpanel" aria-labelledby="nav-statusreceita-tab" tabindex="0">
            <StatusReceita></StatusReceita>
            </div>
            </div>
            </div>
        </>
  );
};

export default CadastrosBanco;

