import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";

const ServicoCadastro = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar></Navbar>
      <div className="box-center">
        <div className="card col-md-12 mx-auto">
          <div className="card-body">
            <legend className="form-label">Cadastro de Cargos</legend>
                    <form class="row g-3">
                <div class="col-md-4">
                    <label for="razaosocial" class="form-label ">Nome do Cargo</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial" placeholder="Digite o nome do Cargo"/>
                </div>
                <div class="col-md-4">
                    <label for="nomefantasia" class="form-label">Cod.</label>
                    <input type="number" class="form-control transparent-input " id="nomefantasia" placeholder="Digite o Código do Cargo"/>
                </div>
                <div class="col-2">
                    <label for="tipoentidade" class="form-label">Início validade</label>
                    <input type="date" class="form-control transparent-input" id="tipoentidade"/>
                </div>
                <div class="col-2">
                    <label for="tipoentidade" class="form-label">Final validade</label>
                    <input type="date" class="form-control transparent-input" id="tipoentidade" />
                </div>
                <div><hr class="divider"/></div>
                <div class="col-md-1 ">
                    <label for="numdocumento" class="form-label">N° Lei</label>
                    <input type="number" class="form-control transparent-input" id="numdocumento"/>
                </div>
                <div class="col-md-2 ">
                    <label for="iscestadual" class="form-label">Data Lei</label>
                    <input type="date" class="form-control transparent-input" id="tipoentidade"/>
                    </div>
                    <div class="col-md-3 ">
                    <label for="iscestadual" class="form-label">Situação</label>
                    <input type="text" class="form-control transparent-input" id="tipoentidade" placeholder="Situação do Cargo"/>
                    </div>
                    <div class="col-md-3 ">
                    <label for="iscestadual" class="form-label">Dedicação Exclusiva</label>
                    <input type="text" class="form-control transparent-input" id="tipoentidade" placeholder="Dedicação exclusiva"/>
                    </div>
                    <div class="col-md-3 ">
                    <label for="iscestadual" class="form-label">Tempo Especial</label>
                    <input type="text" class="form-control transparent-input" id="tipoentidade" placeholder="Possui tempo especial?"/>
                    </div>
                    <div><hr class="divider"/></div>
                    <div class="col-md-6 ">
                    <label for="iscestadual" class="form-label">C.B.O.</label>
                    <input type="number" class="form-control transparent-input" id="tipoentidade" placeholder="CBO do Cargo"/>
                    </div>
                    <div class="col-md-6 ">
                    <label for="iscestadual" class="form-label">Função</label>
                    <input type="text" class="form-control transparent-input" id="tipoentidade" placeholder="Função do Cargo"/>
                    </div>
                    <div><hr class="divider"/></div>
                    <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label">Descrição</label>
                <textarea class="form-control transparent-input" id="exampleFormControlTextarea1" rows="7"></textarea>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success">Cadastrar</button>
                    <button type="reset" class="btn mx-2 btn-warning">Limpar</button>
                </div>
                
                </form>
          </div>
            </div>
            </div>
        
        </>
  );
};

export default ServicoCadastro;