import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import CloseIcon from '@mui/icons-material/Close';


const GridContainers = ({ gridRef, rowData, colDefs, totalRowData, handleCellValueChanged }) => {
return (
    <>
<div className="ag-theme-quartz" style={{ height: 600 }}>
<AgGridReact
  ref={gridRef}
  rowData={rowData}
  columnDefs={colDefs}
  pinnedBottomRowData={totalRowData}
  onCellValueChanged={handleCellValueChanged}
/>
</div>
</>);
};

export default GridContainers;