import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Navbar from '../navbar/navbar';
import Grid1 from './grid1';
import Grid2 from './grid2';

const BancoConsultContas = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [selectedLancIdGrid1, setSelectedLancIdGrid1] = useState([]);
  const [selectedLancIdGrid2, setSelectedLancIdGrid2] = useState(null);
  const [selectedValuesGrid1, setSelectedValuesGrid1] = useState([]);
  const [selectedValueGrid2, setSelectedValueGrid2] = useState(null);
  const [faturamentoData, setFaturamentoData] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [readOnlyConfirm,setReadOnlyConfirm] = useState(false) 
  const [modalConsiliaMenor,setModalConsiliaMenor] = useState(false)



  const formatCurrency = (value) => {
    const numericValue = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  const somarValores = (valores) => {
    const soma = valores
      //.map(valor => parseFloat(valor.replace(/\D/g, '')))
      .reduce((acc, val) => acc + val, 0);
    return soma;
  };

  const associatePaymentToInvoice = async () => {
    setReadOnlyConfirm(true)
    if (!selectedLancIdGrid1.length || !selectedLancIdGrid2) {
      toast.error('Verifique se existe itens selecionados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setReadOnlyConfirm(false);
    }else{
      console.log(selectedValuesGrid1)
      const totalValues= somarValores(selectedValuesGrid1);
      const extValue = parseFloat(selectedValueGrid2.replace(/\D/g, ''));
      console.log(extValue/100)
      console.log(formatCurrency(totalValues - (extValue / 100)));
    if (totalValues > (extValue / 100)){
      console.log('Valor do Lancamento maior que o do Extrato');
      setModalConsiliaMenor(true)
      console.log(totalValues - extValue);
      //toast.error('Valor cobrado esta maior que o do Comprovante!', {
      //  position: "bottom-right",
      //  autoClose: 5000,
      //  hideProgressBar: false,
      //  closeOnClick: true,
      //  pauseOnHover: true,
      //  draggable: true,
      //  progress: undefined,
      //  theme: "light",
      //  });
      setReadOnlyConfirm(false);
    }
    else if (totalValues < (extValue / 100)) {
      setReadOnlyConfirm(false)
      console.log((extValue / 100)-totalValues);
      setFaturamentoData()
      setShowConfirmationModal(true); // Show the confirmation modal
    } else {
      console.log('Valor do Extrato é igual ao Container');
      handleConsciliacao();
      setTimeout(() => {
        setReadOnlyConfirm(false)
        window.location.reload();
      },2000);
    }
    //try {
  }
    //  if (selectedLancIdGrid1 && selectedLancIdGrid2) {
    //
    //    const response = await axios.post(
    //    `${parsedURL}post/lanc/consciliacao_list?idExt=${selectedLancIdGrid2}`,
    //    selectedLancIdGrid1,
    //    {
    //      headers: {
    //        'accept': 'application/json',
    //        'Authorization': `Bearer ${token}`
    //      },
    //    }
    //  );
    //  console.log(response.data);
    //  window.location.reload();
    //} else {
    //    alert('Selecione um ID de lançamento em ambos os grids para associar.');
    //  }
    //} catch (error) {
    //  console.error(error);
    //}
  };
  
  const handleConsciliacao = async () => {
    try {
        const response = await axios.post(
        `${parsedURL}post/lanc/contas/consciliacao_list?idExt=${selectedLancIdGrid2}`,
        selectedLancIdGrid1,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
    } catch (error) {
      console.error(error);
  }
};

const handleConsciliacaoParcial = async () => {
  try {
    const response = await axios.post(
    `${parsedURL}post/lanc/consciliacao_list/parcial?idExt=${selectedLancIdGrid2}`,
    selectedLancIdGrid1,
    {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      },
    }
  );
} catch (error) {
  console.error(error);
}
};

  const handleCreateSaldo  = async () => {
    const totalValues = somarValores(selectedValuesGrid1);
    const extValue = parseFloat(selectedValueGrid2.replace(/\D/g, '')); 
    try {
      const response = await axios.post(
          `${parsedURL}post/create_extract_saldo?idlanc=${selectedLancIdGrid2}&value=${extValue - totalValues}`,
          null, // The data payload should be null if you are only sending query parameters
          {
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          }
      );
      //console.log(response.data);
      //window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = () => {
    setReadOnlyConfirm(false)
    const totalValues= somarValores(selectedValuesGrid1);
    const extValue = parseFloat(selectedValueGrid2.replace(/\D/g, '')); 
    setShowConfirmationModal(false);
    toast.warn(`Valor restante é de ${formatCurrency((extValue - totalValues) / 100)}`, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
      handleConsciliacao();
      handleCreateSaldo();
      setTimeout(() => {
        window.location.reload();
      },2000);
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleConfirmeMenor = () => {
    setReadOnlyConfirm(true)
    handleConsciliacaoParcial();
    setTimeout(() => {
      setReadOnlyConfirm(false)
      window.location.reload();
    },2000);
  };

  const handleCancelMenor = () => {
    setModalConsiliaMenor(false);
  };

  return (
    <>
      <Navbar />
      <div class="row align-items-start g-1">
        <div class="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <form class="row g-3 py-2">
                  <legend className="form-label">Baixa de Lançamentos</legend>
                  <div class="container">
                    <div className="row align-items-start">
                      <div className="col-12 ">
                        <Grid1 data={faturamentoData} selectedLancIdGrid1={selectedLancIdGrid1} setSelectedLancIdGrid1={setSelectedLancIdGrid1} setSelectedValuesGrid1={setSelectedValuesGrid1} />
                      </div>
                      <div className="col-12">
                        <Grid2 grid1Data={faturamentoData} onAssociatePayment={associatePaymentToInvoice} selectedLancIdGrid2={selectedLancIdGrid2} setSelectedLancIdGrid2={setSelectedLancIdGrid2} setSelectedValueGrid2={setSelectedValueGrid2}/>
                      </div>
                      <div><hr class="divider" /></div>
                      <div className="col-6">
                        <button
                          type="button"
                          className="btn mx-2 btn-primary"
                          disabled={readOnlyConfirm}
                          onClick={associatePaymentToInvoice}
                        >
                          Associar Pagamento à Fatura
                        </button>
                        {/*<button
                          className="btn mx-2 btn-success"

                        >
                          Associar Pagamentos (automatico)
                        </button>*/}
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${showConfirmationModal ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showConfirmationModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Confirmação</b></h5>
              <button type="button" className="close bg-danger text-white" onClick={handleCancel} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body bg-light">
              <p>O Valor do <b>Comprovante</b> é maior que os <b>Containers</b> informados.</p>
              <p>Deseja continuar?</p>
            </div>
            <div className="modal-footer bg-warning">
              <button type="button" className="btn btn-secondary" onClick={handleCancel}>Não</button>
              <button type="button" className="btn btn-primary" onClick={handleConfirm}>Sim</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${modalConsiliaMenor ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: modalConsiliaMenor ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Confirmação</b></h5>
              <button type="button" className="close bg-danger text-white" onClick={handleCancelMenor} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body bg-light">
              <p>O Valor do <b>Comprovante</b> é <b className="text-danger">menor</b> que os <b>Containers</b> informados.</p>
              <p>Deseja continuar?</p>
            </div>
            <div className="modal-footer bg-warning">
              <button type="button" className="btn btn-secondary" onClick={handleCancelMenor}>Não</button>
              <button type="button" className="btn btn-primary" disabled={readOnlyConfirm} onClick={handleConfirmeMenor}>Sim</button>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

    </>
  );
};
export default BancoConsultContas;