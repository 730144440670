import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import "../../style/style.css";
import Navbar from "../navbar/navbar";

const Home = () => {
  return (
    <>
      <Navbar />
      </>
  );
};

export default Home;
