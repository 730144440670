import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";

const PermissoesCadastro = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar></Navbar>
        <div className="box-center">
        <div className="card col-md-12 mx-auto">
          <div className="card-body">
            <legend className="form-label">Cadastro de Permissão</legend>
                    <form class="row g-3">
                <div class="col-md-1">
                    <label for="razaosocial" class="form-label ">ID</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial" placeholder="Digite o Primeiro Nome"/>
                </div>
                <div class="col-md-4">
                    <label for="nomefantasia" class="form-label">Nome</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia" placeholder="Nome da Permissão"/>
                </div>
                <div class="col-4">
                    <label for="tipoentidade" class="form-label">Setor</label>
                    <input type="text" class="form-control transparent-input" id="tipoentidade" placeholder="Em qual setor se encontra?"/>
                </div>
                <div class="col-8">
                    <label for="tipoentidade" class="form-label">Complemento</label>
                    <input type="text" class="form-control transparent-input" id="tipoentidade" placeholder=""/>
                </div>
                <div class="col-md-2 ">
                    <label for="data" class="form-label ">Data do Cadastro</label>
                    <input type="date" class="form-control" id="telefone"/>
                </div>
                <div class="col-md-12">
                <label for="exampleFormControlTextarea1" class="form-label">Descrição</label>
                <textarea class="form-control transparent-input" id="exampleFormControlTextarea1" rows="7"></textarea>
                </div>
                
                <div class="col-12">
                    <button type="submit" class="btn btn-success">Cadastrar</button>
                    <button type="reset" class="btn mx-2 btn-warning">Limpar</button>
                </div>
                
                </form>
          </div>
            </div>
            </div>
        </>
  );
};

export default PermissoesCadastro;