import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { Checkbox, Button } from "@mui/material";
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import Navbar from "../navbar/navbar";
import "../../style/style.css";
import { Search } from '@mui/icons-material';
import { useAuth } from "../../context/AuthContext";

const Aba2 = ({errors, rowData, setRowData, totalRowData, setTotalRowData, fat }) => {
    //const [rowData, setRowData] = useState([]);
    const [services, setServices] = useState([]);
    const token = localStorage.getItem("token");
    const parsedURL = localStorage.getItem('urlParsed');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const idLanc = searchParams.get('idlanc');
    const gridRef = useRef();
    const [selectType, setSelectType] = useState();
    const [disabledInfos, setDisableItens] = useState(false)
    const { permissions } = useAuth();

    useEffect(() => {
        if (fat.id_status === 5){
        setDisableItens(true);
        }
      },[fat.id_status])


    useEffect(() => {
        handleGetServices();
        if (idLanc) {
            clearRow();
            handleGetServiceId();
        }
    }, [idLanc]);


    useEffect(() => {
        if (rowData && rowData.length > 0) {
            calculateTotal();
        }
    }, [rowData]);

    const clearRow = () => {
        setRowData([]);
    };

    const handleGetServices = async () => {
        try {
            const response = await axios.get(
                `${parsedURL}get/conf/servicos`,
                {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            if (response.status === 200) {
                console.log(JSON.stringify(response.data.content));
                setServices(response.data.content);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleGetServiceId = async () => {
        try {
            const response = await axios.get(
                `${parsedURL}get/lanc/fat_services_id?idFat=${idLanc}`,
                {
                    headers: {
                        'accept': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                }
            );
            if (response.status === 200 && response.data.content.length > 0) {
                console.log(JSON.stringify(response.data.content));
                setRowData(response.data.content);
            }
        } catch (error) {
            console.log(error);
        }
        
    };

    const handleSelectChange = (index, newValue) => {
        setRowData((prevData) => {
            const newData = [...prevData];
            newData[index].id_servico = newValue;
            return newData;
        });
    };

    const calculateTotal = () => {

        const totalValue = rowData.reduce((acc, item) => acc + parseFloat(item.value/100), 0);
        console.log(totalValue)
        setTotalRowData([{
            container: 'Total',
          //valor_process: totalValorProcess,
          //valor_ap: totalValorAp,
          value: totalValue
        }]);
      };

    const handleAddRow = () => {
        const newRow = {
            id_cc: "",
            id_servico: services.length > 0 ? services[0].id_servico : "",
            processo: "",
            container: "",
            value: "0"
        };
        setRowData((prevData) => [...prevData, newRow]);
        setTimeout(() => {
            gridRef.current.api.startEditingCell({
                rowIndex: rowData.length,
                colKey: 'id_servico'
            });
        }, 0);
    };

    const getServiceNameById = (id) => {
        const service = services.find((c) => c.id_servico === id);
        return service ? service.servico : ''; // Retorna o nome do serviço
    };

    const formatCurrency = (value) => {
        const numericValue = parseFloat(value) / 100;
        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
    };

    const formatContainer = (value) => {
        const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
        if (cleanedValue.length <= 4) {
          return cleanedValue;
        } else if (cleanedValue.length <= 7) {
          return `${cleanedValue.slice(0, 4)} ${cleanedValue.slice(4)}`;
        } else {
          return `${cleanedValue.slice(0, 4)} ${cleanedValue.slice(4, 7)}.${cleanedValue.slice(7, 10)}-${cleanedValue.slice(10)}`;
        }
      };

    const handleInputChange = (value) => {
        const formattedValue = formatContainer(value);
        return (formattedValue);
      };

    const colDefs = [
        {
            field: "id_servico",
            headerName: "Serviço",
            width: 130,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: services.map((service) => service.id_servico) // IDs para seleção
            },
            valueFormatter: (params) => { 
                return getServiceNameById(params.value);
            },
            editable:(params) =>  params.node.rowPinned ? false : disabledInfos ? (permissions.admin || permissions.dev || permissions.fatSuper ? true : !disabledInfos) : true
        }, 
        {
            field: "processo",
            width: 130,
            headerName: "Processo",
            editable:(params) =>  params.node.rowPinned ? false : disabledInfos ? (permissions.admin || permissions.dev || permissions.fatSuper ? true : !disabledInfos) : true
        },
        {
            field: "container",
            headerName: "Container",
            filter: true,
            width: 150,
            editable:(params) =>  params.node.rowPinned ? false : disabledInfos ? (permissions.admin || permissions.dev || permissions.fatSuper ? true : !disabledInfos) : true,
            cellEditor: 'agTextCellEditor',
            valueFormatter: (params) => params.node.rowPinned ? params.value : handleInputChange(params.value),
            cellEditorParams: {
                maxLength: 11 // Adiciona a limitação de 11 caracteres ao editor
            }
        },
        {
            field: "value",
            headerName: "Valor",
            filter: true,
            width: 150,
            editable:(params) =>  params.node.rowPinned ? false : disabledInfos ? (permissions.admin || permissions.dev || permissions.fatSuper ? true : !disabledInfos) : true,
            valueFormatter: (params) => params.node.rowPinned ? formatCurrency(params.value*100) : formatCurrency(params.value),
        },
    ];

    const onCellValueChanged = (params) => {
        if (params.column.colId === 'id_servico') {
            handleSelectChange(params.node.rowIndex, params.newValue);
        }
        if (params.column.colId === 'container') {
            const newValue = params.newValue;
            if (newValue.length > 11) {
                const truncatedValue = newValue.substring(0, 11);
                params.node.setDataValue('container', truncatedValue);
                toast.error('O valor do container não pode exceder 11 caracteres.', {
                  position: "bottom-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
                alert('O valor do container não pode exceder 11 caracteres.');
            } else if (!erNumberValidation(newValue)) {
              toast.error('Container Inválido', {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                });
                params.node.setDataValue('container', '');
            }
        }

        if (params.colDef.field === 'value') {
            calculateTotal();
          }
    };

    const onCellEditingStopped = (params) => {
        if (params.column.colId === 'container') {
            const newValue = params.value;
            if (newValue.length > 11) {
                const truncatedValue = newValue.substring(0, 11);
                params.node.setDataValue('container', truncatedValue);
            } else if (!erNumberValidation(newValue)) {
                params.node.setDataValue('container', '');
            }
        }
    };

    const erNumberValidation = (cntrNbr) => {
        const ALPHA = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        const CODES = [
            10, 12, 13, 14, 15, 16, 17, 18, 19, 20,
            21, 23, 24, 25, 26, 27, 28, 29, 30, 31,
            32, 34, 35, 36, 37, 38
        ];

        if (cntrNbr.startsWith('XXXX')) {
            return true;
        }

        // Remove espaços e hífens do número do contêiner
        cntrNbr = cntrNbr.replace(/\s/g, '').replace(/-/g, '');
        let intSoma = 0;
        let intIndice = 1;

        for (let i = 0; i < 11; i++) {
            let intCodeNumber;
            if (i < 4) {
                intCodeNumber = CODES[ALPHA.indexOf(cntrNbr[i])];
            } else {
                intCodeNumber = parseInt(cntrNbr[i]) || 0;
            }

            if (i < 10) {
                intSoma += intCodeNumber * intIndice;
                intIndice *= 2;
            }
        }

        intIndice = intSoma % 11;
        if (intIndice === 10) {
            intIndice = 0;
        }
        console.log(`${intIndice} = ${parseInt(cntrNbr[10])}`)
        return intIndice === parseInt(cntrNbr[10]);
    };

    return (
        <>
            <div className="box-center1">
                <div className="card col-md-12 mx-auto">
                    <div className="ag-theme-quartz" style={{ height: 600 }}>
                        <AgGridReact
                            ref={gridRef}
                            rowData={rowData}
                            columnDefs={colDefs}
                            rowSelection="unique"
                            pinnedBottomRowData={totalRowData}
                            onCellValueChanged={onCellValueChanged}
                            onCellEditingStopped={onCellEditingStopped}
                        />
                    </div>
                    <div>
                        {errors.containes_has_values && <span className="text-danger">{errors.containes_has_values}</span>}

                        <hr className="divider" />
                    </div>
                    <div className="card-body">
                        <button type="submit" className="btn btn-outline-primary" onClick={handleAddRow} disabled={permissions.admin || permissions.dev || permissions.fatSuper ? false : disabledInfos}>
                            Adicionar Linha
                        </button>
                    </div>
                </div>
                {/*<div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">
                        Fechar
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleSave}>
                        Salvar
                    </button>
                </div>*/}
            </div>
        </>
    );
}

export default Aba2;
