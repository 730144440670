import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import Navbar from '../navbar/navbar';
import Grid2 from './grid2_ava';

const BancoConsultAvaria = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [selectedLancIdGrid1, setSelectedLancIdGrid1] = useState([]);
  const [selectedLancIdGrid2, setSelectedLancIdGrid2] = useState(null);
  const [selectedLancValuesGrid1, setSelectedLancValuesGrid1] = useState([]);
  const [selectedLancValueGrid2, setSelectedLancValueGrid2] = useState(null);

  const [faturamentoData, setFaturamentoData] = useState([]);


  const associatePaymentToInvoice = async () => {
    try {
      if (selectedLancIdGrid1 && selectedLancIdGrid2) {

        const response = await axios.post(
        `${parsedURL}post/lanc/consciliacao_list?idExt=${selectedLancIdGrid2}`,
        selectedLancIdGrid1,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
      window.location.reload();
    } else {
        alert('Selecione um ID de lançamento em ambos os grids para associar.');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Navbar />
      <div class="row align-items-start g-1">
        <div class="col-12">
          <div className="box-center">
            <div className="card col-md-12 mx-auto">
              <div className="card-body pt-2">
                <form class="row g-3 py-2">
                  <legend className="form-label">Baixa a vista</legend>
                  <div class="container">
                    <div className="row xalign-items-start">
                      <div className="col-12">
                      <Grid2 
                            grid1Data={faturamentoData} 
                             onAssociatePayment={associatePaymentToInvoice} 
                             setSelectedLancValueGrid2={setSelectedLancValueGrid2} 
                             setSelectedLancIdGrid2={setSelectedLancIdGrid2} 
                             />
                      </div>
                      <div><hr class="divider" /></div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default BancoConsultAvaria;