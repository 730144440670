import React from 'react';

import Navbar from '../navbar/navbar';
import ReconciliationTable from './data_teste';

const Teste1 = () => {
  const reconciliationData = [
    { date: '01/01/2022', description: 'Compra de material', debit: 100, credit: 0, balance: 100 },
    { date: '02/01/2022', description: 'Pagamento de fornecedor', debit: 0, credit: 50, balance: 50 },
    // Adicione mais dados conforme necessário
  ];

  return (
    <div>
      <h1>Reconciliation Table</h1>
      <ReconciliationTable data={reconciliationData} />
    </div>
  );
};

export default Teste1;
