import React, { useState, useRef, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { format, isValid, parseISO } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import SearchIcon from '@mui/icons-material/Search';
import axios from "axios";

const ReparcelamentoModal = ({ valorTotal, onClose, setRowData, rowData, totalRowData, setTotalRowData, idDataDiret, linha}) => {
  const [centers, setCenters] = useState([]);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const gridRef = useRef();
  const [disabledInfos, setDisableItens] = useState(false)
  //const { permissions } = useAuth();

 //useEffect(() => {
 //    if (fat.id_status === 5){
 //    setDisableItens(true);
 //    }
 //  },[fat.id_status])


  //useEffect(() => {
  //  handleGetCenterCoust();
  //  if (idLanc != null){
  //      clearRow();
  //      handleGetCenterCoustId();
  //}
  //}, [idLanc]);

  const clearRow = () => {
    setRowData([]);
  };
  
  useEffect(() => {
    handleGetCenterCoust();
    if (rowData && rowData.length > 0) {
        calculateTotal();
    }
}, [rowData]);

  const handleGetCenterCoust = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/center_counts`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data.content)
        setCenters(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //const handleGetCenterCoustId = async () => {
  //  try {
  //    const response = await axios.get(
  //      `${parsedURL}get/lanc/fat_center_coust_id?idFat=${idLanc}`,
  //      {
  //        headers: {
  //          'accept': 'application/json',
  //          'Authorization': `Bearer ${token}`
  //        },
  //      }
  //    );
  //    if (response.status === 200) {
  //      console.log(response.data.content);
  //      setRowData(response.data.content);
  //    }
  //  } catch (error) {
  //    console.log(error);
  //  }
  //};

  const handleAddRow = () => {
    console.log(linha)
    const newRow = {
      idDataDiret: idDataDiret,
      lin: linha,
      id_center: centers.length > 0 ? centers[0].id_centro : "",
      value: "0",
    };
    setRowData((prevData) => [...prevData, newRow]);
    setTimeout(() => {
      gridRef.current.api.startEditingCell({
        rowIndex: rowData.length,
        colKey: 'id_center'
      });
    }, 0);
  };

  const handleSelectChange = (index, newValue) => {
    setRowData((prevData) => {
      const newData = [...prevData];
      newData[index].id_center = newValue;
      return newData;
    });
  };

  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };
 
  const getCentroNameById = (id) => {
    const centro = centers.find((c) => c.id_centro === id);
    return centro ? centro.centro_custos : ''; // Retorna o nome do centro
  };

  const colDefs = [
    {
      field: 'id_center',
      headerName: 'Centro de Custo',
      cellEditor: 'agSelectCellEditor',
      width: 140,
      cellEditorParams: {
        values: centers.map((center) => center.id_centro), // IDs para seleção
      },
      valueFormatter: (params) => { return getCentroNameById(params.value); },
      editable:(params) =>  params.node.rowPinned ? false :  true,
    },
    {
      field: "value",
      headerName: "Valor",
      filter: true,
      width: 150,
      valueFormatter: (params) => params.node.rowPinned ? formatCurrency(params.value) : formatCurrency(params.value),
      editable:(params) =>  params.node.rowPinned ? false :  true,
    },
  ];

  const onCellValueChanged = (params) => {
    if (params.column.colId === 'id_center') {
      handleSelectChange(params.node.rowIndex, params.newValue);
    }
    if (params.colDef.field === 'value') {
      calculateTotal();
    }
  };

  const calculateTotal = () => {
    const totalValue = rowData.reduce((acc, item) => acc + parseFloat(item.value), 0);
    console.log(totalValue)
    setTotalRowData([{
      id_center: 'Total',
      value: totalValue
    }]);
  };

  return (
    <div className="modal fade show" style={{ display: 'block' }}>
    <div className="modal-dialog modal-l modal-dialog-scrollable">
        <div className="modal-content">
            <div className="modal-header">
                <h1 className="modal-title fs-5">Dados de Centro de Custo</h1>
                <button type="button" className="btn-close" onClick={onClose}></button>
            </div>
            <div className="modal-body">
                <div className="ag-theme-quartz" style={{ height: '200px', width: '100%', marginTop: '20px' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    rowSelection="unique"
                    onCellValueChanged={onCellValueChanged}
                    pinnedBottomRowData={totalRowData}
                  />
                </div>
        <div><hr className="divider" /></div>
        <div className="card-body">
          <button type="submit" className="btn btn-outline-primary" onClick={handleAddRow} >Adicionar Linha</button>
        </div>

            </div>
            <div className="modal-footer">
                <div type='button' variant="outlined" onClick={onClose}>Fechar</div>
            </div>
        </div>
    </div>
</div>
  );
};

const Aba6 = ({rowData, setRowData,cont}) => {
  const [idDataDireto,setIdDataDireto] = useState();
  const [linha, setLinha] = useState();
  const [dataValueCenter, setDataValueCenter] = useState([]);
  //const [rowData, setRowData] = useState([]);
  const [total, setTotal] = useState(0);
  const [totalRowData, setTotalRowData] = useState([])
  const gridRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const parsedURL = localStorage.getItem('urlParsed');
  const idLanc = new URLSearchParams(window.location.search).get('idlanc');
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (cont.id_origin != null){
        clearRow();
        console.log(cont.id_origin);
        handleGetDataDiretoria();
  }
  }, [cont.id_origin]);

  const clearRow = () => {
    setRowData([]);
  };

  useEffect(() => {
    if (rowData && rowData.length > 0) {
    calculateTotals(rowData);
    }
}, [rowData]);


  const handleOpenModal = (rowData,index,idlan) => {
    console.log(index,idlan)
    setLinha(index);
    setIdDataDireto(idlan);
    setDataValueCenter(rowData.centrosCusto || []); // Se não houver centros de custo, define como array vazio
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    console.log(dataValueCenter)
    const totalValue = totalRowData.reduce((acc, item) => acc + parseFloat(item.value), 0);
    const updatedRowData = rowData.map((row, index) => {
      if (totalValue === row.valor){ // Verifica se é a linha correta
      console.log(row.valor)
      if (index === linha) {
        console.log(index)
        setOpenModal(false);
        return { ...row, centrosCusto: dataValueCenter }; // Atualiza os centros de custo
      }
    }else{
      return row;
    }
    return row;
    });
    setRowData(updatedRowData);
};

  const formatCurrency = (value) => {
    return parseFloat(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const handleGetDataDiretoria = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/lanc/contas/data_diretoria?idlanc=${cont.id_origin}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data)
        setRowData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddRow = () => {
    const newRow = {
      idlanc: null,
      fechamento: "08/2024",
      vencimento: '2024-08-16',
      valor: 0,
      centrosCusto: [] // Aqui armazenaremos os centros de custo
    };
    const updatedRowData = [...rowData, newRow];
    setRowData(updatedRowData);
    calculateTotals(updatedRowData);
  };

  const calculateTotals = (data) => {
    const total = data.reduce((acc, row) => acc + parseFloat(row.valor), 0);
    console.log(total)
    setTotal(total);
  };

  const onCellValueChanged = (params) => {
    const updatedRowData = rowData.map((row, index) =>
      index === params.node.rowIndex ? { ...row, [params.column.colId]: params.newValue } : row
    );
    setRowData(updatedRowData);
    calculateTotals(updatedRowData);
  };

  const colDefs = [
    { 
      field: 'fechamento',
      headerName: 'Fechamento (MM/YYYY)',
      editable: true, 
      width: 150 
    },
    { 
      field: 'vencimento', 
      headerName: 'Vencimento (DD/MM/YYYY)', 
      editable: true, 
      width: 150,
      valueFormatter: (params) => {
        if(!params.node.rowPinned){
          const date = parseISO(params.value);
          if (isValid(date)) { 
            return format(date, 'dd/MM/yyyy', { locale: ptBR });
          } 
        }
        return params.value; 
      },
    },
    {
      field: 'valor',
      headerName: 'Valor',
      
      width: 150,
      valueFormatter: (params) => formatCurrency(params.value), // Formatação de valor
      editable: true,
    },
    {
      field: "id_lanc",
      headerName: "Consultar",
      width: 150,
      cellRenderer: (params) => (
        params.node.rowPinned ? null :
        <div type='button'
             onClick={() => handleOpenModal(params.data, params.node.rowIndex, params.value)} // Passa a linha e o índice
             className="btn btn-outline-dark btn-sm"
             style={{ padding: "2px .2rem", height: "25px", fontSize: "12px" }}>
          <SearchIcon />
        </div>
      ),
    }
  ];

  return (
    <div className="box-center1">
      <div className="card col-md-12 mx-auto">
        <div className="ag-theme-quartz" style={{ height: 600 }}>
          <AgGridReact
            ref={gridRef}
            rowData={rowData}
            columnDefs={colDefs}
            onCellValueChanged={onCellValueChanged}
            pinnedBottomRowData={[
              { vencimento: 'Total', valor: total },
            ]}
          />
        </div>
        <div className="card-body">
          <button type="button" className="btn btn-outline-primary" onClick={handleAddRow}>
            Adicionar Linha
          </button>
        </div>
      </div>
      {openModal && (
        <ReparcelamentoModal
        //valorTotal={dataParcela.valor_apg}
        idDataDiret={!idDataDireto ? 0 : idDataDireto}
        linha={linha}
        rowData={dataValueCenter}
        setRowData={setDataValueCenter}
        totalRowData={totalRowData}
        setTotalRowData={setTotalRowData}
        onClose={handleCloseModal}
        />
        )}
    </div>
  );
};

export default Aba6;
