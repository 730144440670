import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";
import Aba2 from "./aba2";
import Aba6 from "./aba6";
import Aba1 from "./aba1";
import Aba3 from "./aba3";
import Aba4 from "./aba4";
import Aba5 from "./aba5";
import axios from "axios";
import { useAuth } from '../../context/AuthContext';

const ContasModal = ({ functiongetUpdate }) => {
  const navigate = useNavigate();
  const [contas1, setContas1] = useState({
    lanc_os: "",
    id_emp: "",
    id__entidade: "",
    fornecedor: "",
    data_emissao_nf: "",
    data_vencimento: "",
    data_vencimentoOrig: "",
    valor: "",
    valor_apg: "",
    valor_pagm: "",
    id_bank: "",
    id_forma_pag: "",
    number_nf: "",
    doc_conciliacao: "",
    comment: "",
    id_conta: "",
    id_status: "",
    nome: "",
    id_usuario: "",
    value_nf: "",
    value_descI: "",
    value_descC: "",
    value_rentF: "",
    value_rentO: "",
    value_iss: "",
    value_final: "",
    id_origin: "",
    type_reco: "",
    type_fech: "option1"
  });
  const [contas2, setContas2] = useState([]);
  const [contas5, setContas5] = useState([]);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const idLanc = new URLSearchParams(window.location.search).get('idlanc');
  const [codLanc, setCodLanc] = useState(null);
  const [handleButtonBlockSave, setHandleButtonBlockSave] = useState(false)
  const [totalRowDataAba5, setTotalRowDataAba5] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [paymentType, setPaymentType] = useState('');
  const [installments, setInstallments] = useState(0);
  const [recurrence, setRecurrence] = useState(0);
  const [recurrenceType, setRecurrenceType] = useState('monthly');
  const [installmentIntervalType, setInstallmentIntervalType] = useState('monthly');
  const [dayInterval, setDayInterval] = useState('');
  const [rowData, setRowData] = useState([]);
  const [parceView, setParcView] = useState(true);
  const [typeReco, setTypeReco] = useState(null);
  const [visualizarParcelas, setVisualizarParcelas] = useState(false);
  const { permissions } = useAuth();
  const [disabledInfos, setDisableItens] = useState(false)
  const [errors, setErrors] = useState({});
  const [totalRowData4, setTotalRowData4] = useState([]);
  const [contasAba6, setContasAba6] = useState([]);

  useEffect(() => {
    if (idLanc != null) {
      setCodLanc(idLanc);
      setParcView(false);
    } else {
      setParcView(true);
    }
  }, [idLanc])

  useEffect(() => {
    if (contas1.id_status === 6) {
      setDisableItens(true)
    }
  }, [contas1.id_status])


  useEffect(() => {
    if (typeReco === 2) {
      console.log(typeReco)
      setVisualizarParcelas(true);
    } else if (typeReco === 1) {
      console.log(typeReco)
      setVisualizarParcelas(false);
    } else if (typeReco === 3) {
      console.log(typeReco)
      setVisualizarParcelas(false);
    }
  }, [typeReco])

  useEffect(() => {
    if (paymentType === 'cash') {
      console.log(paymentType)
    } else if (paymentType === 'installments') {
      console.log(paymentType)
      console.log(installmentIntervalType)
    }
    else if (paymentType === 'recurrence') {
      console.log(paymentType)
    }
  }, [paymentType])

  useEffect(() => {
    console.log(codLanc)
    setIsReady(true);

  }, [codLanc]);

  useEffect(() => {
    const modalElement = document.getElementById('modal2');
    const closeModalButtons = document.querySelectorAll('[data-bs-dismiss="modal"]');
    const handleCloseModal = () => {
      modalElement.classList.remove('show');
      modalElement.style.display = 'none';
      modalElement.setAttribute('aria-modal', 'false');
      modalElement.setAttribute('aria-hidden', 'true');
      document.body.classList.remove('modal-open');
      removeModalBackdrop();
      window.location.href = `${window.location.origin}/contas/lancamentos`;
      //navigate('/contas/lancamentos');
      //functiongetUpdate();
    };

    closeModalButtons.forEach(button => {
      button.addEventListener('click', handleCloseModal);
    });

    return () => {
      closeModalButtons.forEach(button => {
        button.removeEventListener('click', handleCloseModal);
      });
    };
  }, []);


  const removeModalBackdrop = () => {
    const backdropElement = document.querySelector('.modal-backdrop');
    if (backdropElement) {
      backdropElement.remove();
    }
  };

  const validateFields = () => {
    const newErrors = {};
    if (!contas1.id_emp) newErrors.id_emp = "Empresa é obrigatória";
    //if (!fatAba1.fechamento); newErrors.fechamento = "Fechamento é obrigatório";
    if (!contas1.fornecedor) newErrors.fornecedor = "Fornecedor é obrigatório";
    //if (!contas1.cliente_pag) newErrors.cliente_pag = "Cliente Pagador é obrigatório";
    if (!contas1.data_emissao_nf) newErrors.data_emissao_nf = "Data de Emissão NF é obrigatória";
    if (!contas1.data_vencimento) newErrors.data_vencimento = "Data de Vencimento é obrigatória";
    if (!contas1.valor) newErrors.valor = "Valor Original é obrigatório";
    if (!contas1.valor_apg) newErrors.valor_apg = "Valor a Pagar é obrigatório";
    if (!contas1.valor_pagm) newErrors.valor_pagm = "Valor Pago é obrigatório";
    if (!contas1.id_bank) newErrors.id_bank = "Banco é obrigatório";
    if (!contas1.id_forma_pag) newErrors.id_forma_pag = "Forma de Pagamento é obrigatória";
    if (!contas1.id_conta) newErrors.id_conta = "Conta é obrigatória";
    if (!contas1.id_status) newErrors.id_status = "Status é obrigatório";
    //if (!fatAba2.length > 0) newErrors.containes_has_values = "Os containers são obrigatório";
    //const totalValue2 = fatAba2.reduce((acc, item) => acc + parseFloat(item.value/100), 0);
    const totalValue5 = contas5.reduce((acc, item) => acc + parseFloat(item.value), 0);
    const totalValues4 = rowData.reduce((acc, item) => acc + parseFloat(item.amount), 0);
    if (idLanc != null) {
      if (contas1.id_status != 5 && contas1.id_status != 9 && convertCurrency(contas1.value_nf).toFixed(2) !== totalValue5.toFixed(2)) newErrors.center_has_values = "Total dos Centros esta divergente!";
    } else {      
      if (!paymentType) newErrors.paymentType = 'Selecione o tipo de Parcelamento'
      if (paymentType === 'installments' && convertCurrency(contas1.valor_apg).toFixed(2) !== totalValues4.toFixed(2)) newErrors.paymentType = "Total das Parcelas estão divergente!";
      if (!contas5.length > 0) newErrors.center_has_values = "Os Centros são obrigatório";
      if (contas1.id_status != 5 && contas1.id_status != 9 && convertCurrency(contas1.valor).toFixed(2) !== totalValue5.toFixed(2)) newErrors.center_has_values = "Total dos Centros esta divergente!";
    }
    console.log(convertCurrency(contas1.valor).toFixed(2), totalValue5.toFixed(2))
   // if(contas1.id_status != 5 && contas1.id_status != 9 && convertCurrency(contas1.valor).toFixed(2) !== totalValue2.toFixed(2)) newErrors.containes_has_values = "Total de Container esta divergente!"; 
    //console.log(convertCurrency(contas1.valor));
    //console.log(totalValue2)
    //console.log(totalValue5)


    setErrors(newErrors);
    console.log(newErrors)
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateFields()) {
      toast.error('Por favor, preencha todos os campos obrigatórios!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }
    if (!codLanc) {
      if (paymentType === 'cash') {
        let idreturn;
        if (contas1.id_status === 2 || contas1.id_status === '2') {
          idreturn = await handleAdditionalSubmitForIdStatus2(); 
        } else {
          idreturn = await handleSubmitAba1();
        }
        await handleSaveAba5(idreturn);
        await saveButtonAba6(idreturn)
      } else if (paymentType === 'installments') {
        let idreturn;
        if (contas1.id_status === 2 || contas1.id_status === '2') {
          idreturn = await handleSubmitAba1_parcForId2();
          console.log(paymentType)
        }
        else {
          idreturn = await handleSubmitAba1_parc();
          console.log(idreturn);
        }
        await handleSaveAba5(idreturn);
        await saveButtonAba6(idreturn)
      }
      else if (paymentType === 'recurrence') {
        if (contas1.id_status === 2 || contas1.id_status === '2') {
          console.log(paymentType)
          await handleSubmitAba1_recoForIdStatus2(); // Realiza o submit adicional específico para id_status 6
        }
        else {
          handleSubmitAba1_reco();
        }
        //window.location.href = `${window.location.origin}/contas/lancamentos`;
      }
      return;
    } else {
      if (typeReco === 1) {
        if (contas1.id_status === 2 || contas1.id_status === '2') {
          handleUpdateAba1Extra()
        } 
        else if (contas1.id_status === 6 || contas1.id_status === '6') {
          handleUpdateAba1Extra()
        } 
        else {
          await handleUpdateAba1();
        }
      }
      else if (typeReco === 2) {
        let idreturn;
        idreturn = contas1.id_origin;
        if (contas1.id_status === 2 || contas1.id_status === '2') {
          await handleUpdateAba1Extra()
          await saveButtonAba6(idreturn)
        } 
        else if (contas1.id_status === 6 || contas1.id_status === '6') {
          await handleUpdateAba1Extra()
          await saveButtonAba6(idreturn);
        } 
        else {
        await handleUpdateAba1();
      }
      //await handleSaveAba5(idreturn);x
      //await saveButtonAba6(idreturn)
      }
      else if (typeReco === 3) {
        if (contas1.id_status === 2 || contas1.id_status === '2') {
          handleUpdateAba1Extra()
        } 
        else if (contas1.id_status === 6 || contas1.id_status === '6') {
          handleUpdateAba1Extra()
        } 
        else {
          await handleUpdateAba1();
        }
      }
      else{
        return
      }
      window.location.href = `${window.location.origin}/contas/lancamentos?idlanc=${idLanc}`;
    };
  };

  const handleSubmitAba1_parcForId2 = async () => {
    try {
      console.log(rowData);
      console.log(JSON.stringify({
        cont: contas1,
        parcl: rowData
      }));

      //const response = await fetch(`${parsedURL}post/lanc/cont_parc`, {
      //  method: 'POST',
      //  headers: {
      //    'Content-Type': 'application/json',
      //    'Authorization': `Bearer ${token}`
      //  },
      //  body: JSON.stringify({
      //    cont: contas1,
      //    parcl: rowData
      //  })
      //});
      //console.log(response)

      const response = await axios.post(
        `${parsedURL}post/lanc/cont_parc/extra`,
        JSON.stringify({
          cont: contas1,
          parcl: rowData
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        console.log(data.idlanc)
        
        //console.log('Dados enviados com sucesso:', data.idlanc);
        //setCodLanc(data.idlanc);
        //await handleSaveOtherAbas(data.idlanc);
        toast.success('Lançamento realizado', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return data.idlanc;
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleAdditionalSubmitForIdStatus2 = async () => {
    try {
    const response = await axios.post(
      `${parsedURL}post/lanc/extra_submit`,
      contas1,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      }
    );

    if (response.status === 200) {
      const data = response.data;
      console.log(data)
      toast.success('Dados adicionais enviados com sucesso!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return data.idlanc;
    }
    } catch (error) {
      console.error('Erro ao enviar os dados adicionais:', error);
      toast.error('Erro ao enviar os dados adicionais!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmitAba1 = async () => {
    try {
      const response = await axios.post(
        `${parsedURL}post/lanc/contas`,
        contas1,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        console.log(data.idlanc)
        //const data = await response.json();
        toast.success('Lançamento realizado', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return data.idlanc;
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmitAba1_parc = async () => {
    try {
      console.log(rowData);
      console.log(JSON.stringify({
        cont: contas1,
        parcl: rowData
      }));

      //const response = await fetch(`${parsedURL}post/lanc/cont_parc`, {
      //  method: 'POST',
      //  headers: {
      //    'Content-Type': 'application/json',
      //    'Authorization': `Bearer ${token}`
      //  },
      //  body: JSON.stringify({
      //    cont: contas1,
      //    parcl: rowData
      //  })
      //});
      //console.log(response)

      const response = await axios.post(
        `${parsedURL}post/lanc/cont_parc`,
        JSON.stringify({
          cont: contas1,
          parcl: rowData
        }),
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        console.log(data.idlanc)
        
        //console.log('Dados enviados com sucesso:', data.idlanc);
        //setCodLanc(data.idlanc);
        //await handleSaveOtherAbas(data.idlanc);
        toast.success('Lançamento realizado', {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return data.idlanc;
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmitAba1_reco = async () => {
    console.log(paymentType);
    console.log(recurrence);
    console.log(recurrenceType);
    console.log(dayInterval);

    const lancamentos = [];

    for (let i = 0; i < recurrence; i++) {
      const newLancamento = { ...contas1 };

      if (recurrenceType === 'monthly') {
        // Ajusta a data de vencimento para o próximo mês
        newLancamento.data_vencimento = new Date(newLancamento.data_vencimento);
        newLancamento.data_vencimento.setMonth(newLancamento.data_vencimento.getMonth() + i);

        // Ajusta o fechamento para o mês correspondente
        const mes = String(newLancamento.data_vencimento.getMonth() + 1).padStart(2, '0');
        const ano = newLancamento.data_vencimento.getFullYear();
        newLancamento.fechamento = `${mes}/${ano}`;
      } else if (recurrenceType === 'daily') {
        // Ajusta a data de vencimento para o próximo intervalo de dias
        newLancamento.data_vencimento = new Date(newLancamento.data_vencimento);
        newLancamento.data_vencimento.setDate(newLancamento.data_vencimento.getDate() + i * dayInterval);

        // Ajusta o fechamento para o mês correspondente
        const mes = String(newLancamento.data_vencimento.getMonth() + 1).padStart(2, '0');
        const ano = newLancamento.data_vencimento.getFullYear();
        newLancamento.fechamento = `${mes}/${ano}`;
      }

      // Converte a data de volta para o formato necessário (assumindo que você precise de 'yyyy-mm-dd')
      newLancamento.data_vencimento = newLancamento.data_vencimento.toISOString().split('T')[0];

      lancamentos.push(newLancamento);
    }

    try {
      for (const lanc of lancamentos) {
        const response = await axios.post(
          `${parsedURL}post/lanc/contas/reco`,
          lanc,
          {
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          }
        );
    
        if (response.status === 200) {
          const data = response.data;
          console.log(data.idlanc)
          await handleSaveAba5(data.idlanc);
          await saveButtonAba6(data.idlanc)
          //const data = await response.json();
        }
      }

      toast.success('Lançamentos recorrentes realizados com sucesso!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmitAba1_recoForIdStatus2 = async () => {
    console.log(paymentType);
    console.log(recurrence);
    console.log(recurrenceType);
    console.log(dayInterval);

    const lancamentos = [];

    for (let i = 0; i < recurrence; i++) {
      const newLancamento = { ...contas1 };

      if (recurrenceType === 'monthly') {
        // Ajusta a data de vencimento para o próximo mês
        newLancamento.data_vencimento = new Date(newLancamento.data_vencimento);
        newLancamento.data_vencimento.setMonth(newLancamento.data_vencimento.getMonth() + i);

        // Ajusta o fechamento para o mês correspondente
        const mes = String(newLancamento.data_vencimento.getMonth() + 1).padStart(2, '0');
        const ano = newLancamento.data_vencimento.getFullYear();
        newLancamento.fechamento = `${mes}/${ano}`;
      } else if (recurrenceType === 'daily') {
        // Ajusta a data de vencimento para o próximo intervalo de dias
        newLancamento.data_vencimento = new Date(newLancamento.data_vencimento);
        newLancamento.data_vencimento.setDate(newLancamento.data_vencimento.getDate() + i * dayInterval);

        // Ajusta o fechamento para o mês correspondente
        const mes = String(newLancamento.data_vencimento.getMonth() + 1).padStart(2, '0');
        const ano = newLancamento.data_vencimento.getFullYear();
        newLancamento.fechamento = `${mes}/${ano}`;
      }

      // Converte a data de volta para o formato necessário (assumindo que você precise de 'yyyy-mm-dd')
      newLancamento.data_vencimento = newLancamento.data_vencimento.toISOString().split('T')[0];

      lancamentos.push(newLancamento);
    }
    console.log(lancamentos)

    try {
      for (let i = 0; i < lancamentos.length; i++) {
        const lanc = lancamentos[i];
        if (i === 0) {
          const response = await axios.post(
            `${parsedURL}post/lanc/contas/reco/extra`,
            lanc,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
            }
          );
  
          if (response.status === 200) {
            const data = response.data;
            console.log(data.idlanc);
            await handleSaveAba5(data.idlanc);
          }
        } else {
          const response = await axios.post(
            `${parsedURL}post/lanc/contas/reco`,
            lanc,
            {
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
              },
            }
          );
  
          if (response.status === 200) {
            const data = response.data;
            console.log(data.idlanc);
            await handleSaveAba5(data.idlanc);
            await saveButtonAba6(data.idlanc)

          }
        }
      }

      toast.success('Lançamentos recorrentes realizados com sucesso!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateAba1 = async () => {
    try {
      const response = await fetch(`${parsedURL}put/lanc/cont_id?idlanc=${codLanc}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(contas1)
      });

      if (!response.ok) {
        throw new Error('Erro ao enviar os dados.');
      }

      const data = await response.json();
      console.log('Dados enviados com sucesso:', data);
      await handleSaveOtherAbas(codLanc);
      toast.success('Lançamento atualizado', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateAba1Extra = async () => {
    try {
      const response = await axios.put(
        `${parsedURL}put/lanc/cont_id/extra?idlanc=${codLanc}`,
        contas1,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        console.log('Dados enviados com sucesso:', data);
        await handleSaveAba5(codLanc);
        //const data = await response.json();
      }
      //await handleSaveOtherAbas(codLanc);
      //if (!response.ok) {
      //  throw new Error('Erro ao enviar os dados.');
      //}
      //
      //const data = await response.json();
      
      toast.success('Lançamento atualizado', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleUpdateAba1Parcelamento = async () => {
    try {
      const response = await fetch(`${parsedURL}put/lanc/cont_id?idlanc=${codLanc}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(contas1)
      });

      if (!response.ok) {
        throw new Error('Erro ao enviar os dados.');
      }

      const data = await response.json();
      console.log('Dados enviados com sucesso:', data);
      await handleSaveOtherAbas(codLanc);
      toast.success('Lançamento atualizado', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
      toast.error('Erro ao enviar os dados!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const convertCurrency = (currency) => {
    // Remove o 'R$ ' da string
    let numberString = currency.replace('R$', '').trim();
    // Remove os pontos de separação de milhares
    numberString = numberString.replace(/\./g, '');
    // Substitui a vírgula por ponto
    numberString = numberString.replace(',', '.');
    // Converte para número de ponto flutuante
    const floatValue = parseFloat(numberString);
    return floatValue;
  };

  const handleSaveOtherAbas = async (idlanc) => {
    try {
      await handleSaveAba5(idlanc);
      await saveButtonAba6(idlanc)
    } catch (error) {
      toast.error('Erro ao salvar dados das outras abas!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  const handleSubmitAba5 = async (idlanc) => {
    try {
      const newData = contas5.filter(data => !data.id_centF); // Filtra os dados que não possuem id_centF
      const response = await axios.post(
        `${parsedURL}post/lanc/cont_center_coust?idCont=${idlanc}`,
        newData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateAba5 = async (idlanc) => {
    try {
      const updatedData = contas5.filter(data => data.id_centF);
      const response = await axios.put(
        `${parsedURL}put/lanc/cont_center_coust_id?idCont=${idlanc}`,
        updatedData,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveAba5 = async (idlanc) => {
    await handleSubmitAba5(idlanc);
    await handleUpdateAba5(idlanc);
  };

  const saveButtonAba6 = async (idlanc) => {
    await handleSubmitDataDiretorAba6(idlanc);
    await handleUpdateDataDiretorAba6(idlanc);
  }

  const handleSubmitDataDiretorAba6 = async (idlanc) => {
    try {
      const newData = contasAba6.filter(data => !data.idlanc); 
      const response = await axios.post(
        `${parsedURL}post/lanc/contas/data_diretoria?idlanc=${idlanc}`,
        newData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        console.log(data)
        //const data = await response.json();
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    }
  }

  const handleUpdateDataDiretorAba6 = async (idlanc) => {
    try {
      const newData = contasAba6.filter(data => data.idlanc); 
      const response = await axios.put(
        `${parsedURL}put/lanc/contas/data_diretoria?idlanc=${idlanc}`,
        newData,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
  
      if (response.status === 200) {
        const data = response.data;
        console.log(data)
        //const data = await response.json();
      }
    } catch (error) {
      console.error('Erro ao enviar os dados:', error);
    }
  }


  return (
    <>
      <div class="modal fade" id="modal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-scrollable ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">Contas a Pagar</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              {/*<nav>
                <div class="nav nav-tabs" id="nav-tab" role="tablist">
                  <button class="nav-link active form-label" id="nav-1-tab" data-bs-toggle="tab" data-bs-target="#nav-1" type="button" role="tab" aria-controls="nav-1" aria-selected="true">Lançamento</button>
                  <button class="nav-link form-label" id="nav-2-tab" data-bs-toggle="tab" data-bs-target="#nav-2" type="button" role="tab" aria-controls="nav-2" aria-selected="false">Container</button>
                  <button class="nav-link form-label" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-4" type="button" role="tab" aria-controls="nav-4" aria-selected="false">Forma</button>
                  <button class="nav-link form-label" id="nav-3-tab" data-bs-toggle="tab" data-bs-target="#nav-5" type="button" role="tab" aria-controls="nav-5" aria-selected="false">C. Custo</button>
                 {/*<button class="nav-link form-label" id="nav-4-tab" data-bs-toggle="tab" data-bs-target="#nav-6" type="button" role="tab" aria-controls="nav-6" aria-selected="false">Tributos</button>
                </div>
              </nav>*/}

              <div className="row align-items-start">
                <div className="col-8 ">
                  <legend className="form-label">Dados do Lancamento</legend>
                  <Aba1 errors={errors} cont={contas1} setCont={setContas1} setTypeReco={setTypeReco} />
                </div>
                <div className="col-4 " disabled={permissions.admin ? false : disabledInfos}>
                  <legend className="form-label">Dados da NF</legend>
                  <Aba3 cont={contas1} setCont={setContas1} typeReco={typeReco} />
                  {(visualizarParcelas) && (<><div><hr className="divider" /></div><legend className="form-label">Parcelas</legend><Aba2 /></>)}
                  {(parceView) && (<><div><hr className="divider" /></div><legend className="form-label">Qtd de Parcelas</legend><Aba4 cont={contas1} setCont={setContas1}
                    paymentType={paymentType} setPaymentType={setPaymentType} installments={installments} setInstallments={setInstallments}
                    recurrence={recurrence} setRecurrence={setRecurrence} recurrenceType={recurrenceType} setRecurrenceType={setRecurrenceType}
                    installmentIntervalType={installmentIntervalType} setInstallmentIntervalType={setInstallmentIntervalType} dayInterval={dayInterval}
                    setDayInterval={setDayInterval} rowData={rowData} setRowData={setRowData} errors={errors} totalRowData={totalRowData4} setTotalRowData={setTotalRowData4}/></>)}
                </div>
                <div><hr className="divider" /></div>
                <div className="col-5 ">
                  <legend className="form-label">Centro de Custos</legend>
                  <Aba5 errors={errors} rowData={contas5} setRowData={setContas5} totalRowData={totalRowDataAba5} setTotalRowData={setTotalRowDataAba5} installments={installments} paymentType={paymentType} cont={contas1} />
                </div>
                <div className="col-7 ">
                  <legend className="form-label">Dados Diretoria</legend>
                  <Aba6 rowData={contasAba6} setRowData={setContasAba6}  cont={contas1}/>
                </div>
                {/*     <Aba4 />
                  <Aba6 /> */}

              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                <button type="button" disabled={handleButtonBlockSave} class="btn btn-primary" onClick={handleSave}>Salvar</button>
              </div>
              {/*
              "onClick={idLanc != null ? handleUpdate : handleSubmit}"
              <div class="tab-content" id="nav-tabContent">
                <div class="tab-pane fade show active " id="nav-1" role="tabpanel" aria-labelledby="nav-1-tab" tabindex="0">
                </div>
                <div class="tab-pane fade" id="nav-2" role="tabpanel" aria-labelledby="nav-2-tab" tabindex="0">
                </div>
                <div class="tab-pane fade" id="nav-4" role="tabpanel" aria-labelledby="nav-4-tab" tabindex="0">
                </div>
                <div class="tab-pane fade" id="nav-5" role="tabpanel" aria-labelledby="nav-5-tab" tabindex="0">
                </div>
                <div class="tab-pane fade" id="nav-6" role="tabpanel" aria-labelledby="nav-6-tab" tabindex="0">
                </div>
              </div>*/}
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ContasModal;