import React from 'react';
import "../../style/style.css";
import Navbar from '../navbar/navbar';
import logo from "../../assets/cropped-LOGO.png";

const CompanyInfo = () => {
    const companyName = "Transtec World Transportes e Logistica Ltda.";
    const companyDetails = "";
    const softwareVersion = "1.1.20";
    const apiVersion = "1.1.20";
    const apiEndpoint = "http://api.transtecworld.com/documentacao/docs";

    return (
        <>
        <Navbar/>
        <div className="container mt-5">
            <div className="card">
                <div className="card-header text-black">
                    <div className='d-flex align-items-center'>
                        <img
                            src={logo}
                            style={{ maxHeight: '50px', marginRight: '15px' }} 
                            alt="Company Logo"
                        />
                        <h1 className='mb-0'>{companyName}</h1>
                    </div>
                </div>
                <div className="card-body">
                    <p><strong>Detalhes da Empresa:</strong> {companyDetails}</p>
                    <p><strong>Versão do Software:</strong> {softwareVersion}</p>
                    <p><strong>Versão da API:</strong> {apiVersion}</p>
                    <p><strong>Endpoint da API:</strong> <a href={apiEndpoint}>{apiEndpoint}</a></p>
                </div>
            </div>
        </div>
        </>
    );
}

export default CompanyInfo;
