import React from "react";
import { Link, useNavigate } from 'react-router-dom';
import "../../style/style.css"
import Navbar from "../navbar/navbar";

const CargoCadastro = () => {
  const navigate = useNavigate();

  return (
        <>
        <Navbar></Navbar>
      <div className="box-center">
        <div className="card col-md-12 mx-auto">
          <div className="card-body">
            <legend className="form-label">Dados Funcionário</legend>
                    <form class="row g-3">
                <div class="col-md-6">
                    <label for="razaosocial" class="form-label ">Primeiro Nome</label>
                    <input type="text" class="form-control transparent-input" id="razaosocial" placeholder="Digite o Primeiro Nome"/>
                </div>
                <div class="col-md-6">
                    <label for="nomefantasia" class="form-label">Sobrenome</label>
                    <input type="text" class="form-control transparent-input " id="nomefantasia" placeholder="Digite o Sobrenome"/>
                </div>
                <div class="col-2">
                    <label for="tipoentidade" class="form-label">CPF</label>
                    <input type="text" class="form-control transparent-input" id="tipoentidade" placeholder="000.000.000-00"/>
                </div>
                <div class="col-md-2 ">
                    <label for="inputState" class="form-label ">Gênero</label>
                    <select id="inputState" class="form-select transparent-input">
                    <option selected>Selecione o Genêro</option>
                    <option>Masculino</option>
                    <option>Feminino</option>
                    <option>Outros</option>
                    </select>
                </div>
                <div class="col-md-2 ">
                    <label for="numdocumento" class="form-label">Data de nascimento</label>
                    <input type="date" class="form-control transparent-input" id="numdocumento" placeholder="000.000.000-00 | 00.000.000/0000-00"/>
                </div>
                <div class="col-md-3 ">
                    <label for="iscestadual" class="form-label">Estado Civil</label>
                    <select id="inputState" class="form-select transparent-input">
                    <option selected>Selecione uma opção</option>
                    <option>Masculino</option>
                    <option>Feminino</option>
                    </select>
                    </div>
                <div class="col-md-3">
                    <label for="inputCity" class="form-label">Possui Filhos?</label>
                    <input class="form-check-input mx-2" type="checkbox" id="gridCheck"/>
                    <label class="form-check-label" for="gridCheck">
                        Sim
                    </label>
                    <input class="form-check-input mx-2" type="checkbox" id="gridCheck"/>
                    <label class="form-check-label" for="gridCheck">
                        Não
                    </label>
                    <input type="Number" class="form-control" id="inputCity" placeholder="Quantos?"/>
                </div>
                    <div><hr class="divider"/></div>
                <legend>Dados Pessoais</legend>
                <div class="col-md-2">
                    <label for="telefone" class="form-label">CEP</label>
                    <input type="text" class="form-control" id="telefone" placeholder="00000-000"/>
                </div>
                <div class="col-md-8">
                    <label for="telefone" class="form-label">Endereço</label>
                    <input type="text" class="form-control" id="telefone"/>
                </div>
                <div class="col-md-2">
                    <label for="telefone" class="form-label">Número</label>
                    <input type="number" class="form-control" id="telefone"/>
                </div>
                <div class="col-md-3">
                    <label for="telefone" class="form-label">Complemento</label>
                    <input type="text" class="form-control" id="telefone"/>
                </div>
                <div class="col-md-6">
                    <label for="inputZip" class="form-label">E-mail</label>
                    <input type="text" class="form-control" id="inputZip" placeholder="exemplo@mail.com"/>
                </div>
                <div class="col-md-3">
                    <label for="inputCity" class="form-label">Telefone</label>
                    <input type="text" class="form-control" id="inputCity" placeholder="(00)00000-0000"/>
                </div>
                <div><hr class="divider"/></div>
                <legend>Dados RH</legend>
                <div class="col-md-3">
                    <label for="telefone" class="form-label">Funcionário Setor</label>
                    <select id="inputState" class="form-select transparent-input">
                    <option selected>Selecione uma opção</option>
                    <option>Operacional</option>
                    <option>Gêrencia</option>
                    </select>
                </div>
                <div class="col-md-5">
                    <label for="inputCity" class="form-label">Cargo</label>
                    <input type="email" class="form-control" id="inputCity" placeholder="Digite o nome do cargo"/>
                </div>
                <div class="col-md-3">
                    <label for="inputCity" class="form-label">Salário</label>
                    <input type="text" class="form-control" id="inputCity" placeholder="000000,00"/>
                </div>
                <div class="col-md-3">
                    <label for="telefone" class="form-label">Data da Admissão</label>
                    <input type="date" class="form-control" id="telefone"/>
                </div>
                <div class="col-12">
                    <button type="submit" class="btn btn-success">Cadastrar</button>
                    <button type="reset" class="btn mx-2 btn-warning">Limpar</button>
                </div>
                
                </form>
          </div>
            </div>
            </div>
        
        </>
  );
};

export default CargoCadastro;