import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import CloseIcon from '@mui/icons-material/Close';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import Search from "@mui/icons-material/Search.js";
import AddIcon from '@mui/icons-material/Add';
import "../../style/style.css"
import GridContainers from "./gridContainers"

const ModalBuscarEntidade = ({ entidade, columns, onCloseModalEntid, handleRowDoubleClick }) => {
  const gridRef = useRef();

  return (
    <div className="modal fade show" style={{ display: 'block' }}>
        <div className="modal-dialog modal-l modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title">
                <b>Selecione o Cliente</b></h5>
                <button type="button" className="btn-close text-white" onClick={onCloseModalEntid}>
              </button>
            </div>
            <div className="modal-body ">
              <div className="ag-theme-quartz" style={{ height: 400, width: '100%', marginTop: '20px' }} >
                <AgGridReact
                  ref={gridRef}
                  rowData={entidade}
                  columnDefs={columns}
                  rowSelection="single"
                  onRowDoubleClicked={handleRowDoubleClick}
                />
              </div>
            </div>            
            <div className="modal-footer ">
              <button type="button" className="btn btn-secondary" onClick={onCloseModalEntid}>Fechar</button>
            </div>
          </div>
        </div>
      </div>
  );
};

const LancAvariaModal = ({ ondata, valor_pagm }) => {
  const [rowData, setRowData] = useState([]);
  const [process, setProcess] = useState("");
  const [cntr, setCntr] = useState("");
  const [radioValue, setRadioValue] = useState("sts");
  const location = useLocation();
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const [entidade, setEntidade] = useState([]);
  const [errors, setErrors] = useState({});
  const [empresas, setEmpresas] = useState([]);
  const [totalRowData, setTotalRowData] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showGridEnti, setShowGridEnti] = useState(false)
  const [editor, setEditor] = useState(false);
  const [selectType, setSelectType] = useState();
  const [readOnlyConfirm,setReadOnlyConfirm] = useState(false) 
  const [modalConsiliaMenor,setModalConsiliaMenor] = useState(false)
  const [radiodisable,setRadiodisable] = useState(false)
  const modalGridEntiRef = useRef(null);

  const gridRef = useRef();
  const gridCli = useRef()
  const handleClickSeach = (tipoBusca) => {
    setSelectType(tipoBusca);
    handleGetEntidade();
    setShowGridEnti(true);
  };
  const [dataProcess, setDataProcess] = useState({
    "cliente": "",
    "container": "",
    "processo": "",
    "data_entrada": "",
    "patio": "",
    "importador": "",
    "valor_ap": "0",
    "valor_pg": "0"
  });

  const [fat, setFat] = useState({
    id_emp: "",
    id__entidade: "",
    cliente: "",
    id_entidade_pag: "",
    cliente_pag: "",
    valor_pagm: "",
    comment: ""
  });

  useEffect(() => {
    if (showGridEnti && modalGridEntiRef.current) {
      modalGridEntiRef.current.focus();
    }
  }, [showGridEnti]);

  useEffect(() => {
    if(radioValue === "sts"){
    setFat(prevState => ({
      ...prevState,
      id_emp: 3,
    }));
  }
    else if (radioValue === "grj") {
      setFat(prevState => ({
        ...prevState,
        id_emp: 1,
      }));
    } else {
      setFat(prevState => ({
        ...prevState,
        id_emp: '',
      }));
    }

  },[radioValue]);

  useEffect(() => {
    handleGetEntidade()
    handleGetEmpresas();
    setFat(prevState => ({
      ...prevState,
      id_emp: 3,
      valor_pagm: ondata.value_reais
    }));
    calculateTotal();
  }, [ondata, rowData]);

  useEffect(() => {
    if(rowData.length > 0){
      setRadiodisable(true);
    }
    else{
      setRadiodisable(false);
    }
  })

  const handleGetEntidade = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/cadastro/entidade`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEntidade(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGetEmpresas = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/conf/data_empresas`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(JSON.stringify(response.data.content));
        setEmpresas(response.data.content);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostFat = async () => {
    console.log(rowData)
    //for (let item of rowData) {
      if (radioValue === 'sts') {
        var finalendpoint = 'post/lanc/avaria_avista'
      }else if (radioValue === 'grj'){
        var finalendpoint = 'post/lanc/grjavaria_avista'
      }

    const formattedData = rowData.map(item => ({
      valorOriginal: parseFloat(item.valor_pg),
      valor: parseFloat(item.valorPatioConf),
      container: item.container,
      processo: item.processo,
      nf: item.nf,
      patio: item.patio,
      cliente: item.cliente,
      importador: item.importador
    }));
    try {
      const response = await axios.post(
        `${parsedURL}${finalendpoint}`,
        {
          datafat: fat,
          dataCntr: formattedData,
          consciliacao: ondata
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        toast.success("Lançamento realizado com sucesso!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Erro ao realizar lançamento!");
    }
    //}
    //window.location.reload();
  };

  const handlePostFatValorMenor = async () => {
    console.log(rowData)
    //for (let item of rowData) {
      if (radioValue === 'sts') {
        var finalendpoint = 'post/lanc/avaria_avista_menor'
      }else if (radioValue === 'grj'){
        var finalendpoint = 'post/lanc/grjavaria_avista_menor'
      }
    const formattedData = rowData.map(item => ({
      valorOriginal: parseFloat(item.valor_pg),
      valor: parseFloat(item.valorPatioConf),
      container: item.container,
      processo: item.processo,
      nf: item.nf,
      patio: item.patio,
      cliente: item.cliente,
      importador: item.importador
    }));
    try {
      const response = await axios.post(
        `${parsedURL}${finalendpoint}`,
        {
          datafat: fat,
          dataCntr: formattedData,
          consciliacao: ondata
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        toast.success("Lançamento realizado com sucesso!");
      }
    } catch (error) {
      console.log(error);
      toast.error("Erro ao realizar lançamento!");
    }
    //}
    //window.location.reload();
  };

  const handleGetProcess = async () => {
    console.log(radioValue);
    if (radioValue === 'sts') {
    try {
      const response = await axios.get(
        `${parsedURL}get/lanc/buscar_process_patio?cntr=${cntr}&processo=${process}`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        console.log(response.data);
        if(response.data.cliente === null){
          toast.warn(`Não existe valores para esse container!`, {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        } else if (response.data.cliente !== null) {
        const data = response.data;
        setDataProcess(data);
      }
      }
    } catch (error) {
      console.log(error);
    }
    } else if ( radioValue === 'grj'){
      try {
        const response = await axios.get(
          `${parsedURL}get/lanc/buscar_process_patio_grj?cntr=${cntr}&processo=${process}`,
          {
            headers: {
              'accept': 'application/json',
              'Authorization': `Bearer ${token}`
            },
          }
        );
        if (response.status === 200) {
          console.log(response.data);
          if(response.data.cliente === null){
            toast.warn(`Não existe valores para esse container!`, {
              position: "bottom-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          } else if (response.data.cliente !== null) {
          const data = response.data;
          setDataProcess(data);
        }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const parseCurrencyToNumber = (value) => {
    const numericValue = parseFloat(value.replace(/\D/g, ''));
    console.log(numericValue);
    return numericValue;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    let formattedValue = value;
    if (name === 'valor' || name === 'valor_receb' || name === 'valor_pagm') {
      const numericValue = parseFloat(value.replace(/\D/g, ''));
      formattedValue = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      }).format(numericValue / 100);
    }

    setFat(prevState => ({
      ...prevState,
      [name]: formattedValue
    }));
  };

  const handleConfirmeMenor = async () => {
    setReadOnlyConfirm(true)
    handlePostFatValorMenor();
    await gerar_intercambio();
    //handleConsciliacaoParcial();
    setTimeout(() => {
      setReadOnlyConfirm(false)
      window.location.reload();
    },2000);
  };

  const handleCancelMenor = () => {
    setModalConsiliaMenor(false);
  };

  const handleButtonCad = async () => {
    if (!validateFields()) {
      toast.error('Por favor, preencha todos os campos obrigatórios!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const totalpg = parseCurrencyToNumber(formatCurrency(totalRowData[0].valorPatioConf));
    const pgextrato = parseCurrencyToNumber(fat.valor_pagm);
    const data_extr = rowData;

    if (totalpg > pgextrato) {
      if (rowData.length > 1) {
        console.log('Valor do Container maior que o do Extrato');
        toast.error('Valor cobrado esta maior que o do Comprovante!', {
        position: "bottom-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      }
      else{
      setModalConsiliaMenor(true)

      }
    }
    else if (totalpg < pgextrato) {
      setShowConfirmationModal(true); // Show the confirmation modal
    } else {
      console.log('Valor do Extrato é igual ao Container');
      handlePostFat();
      await gerar_intercambio();
      setTimeout(() => {
        window.location.reload();
      }, 2000);
      //handlePostFat();
      }
      
    };

  const gerar_intercambio = async () => {
    try {
      for (let i = 0; i < rowData.length; i++) {
        const response = await axios.get(
          `${parsedURL}get/recibo_intercambio/in?container=${rowData[i].container}&processo=${rowData[i].processo}`,
          {
            headers: {
              'Accept': 'application/pdf'
            },
            responseType: 'blob' // Adicione esta linha para tratar a resposta como um blob (PDF)
          }
        );
  
        if (response.status === 200) {
          // Cria um link para download do arquivo PDF
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Recibo de Intercambio${rowData[i].container}_${rowData[i].processo}.pdf`); // Define o nome do arquivo para download
          document.body.appendChild(link);
          link.click();
          console.log(`PDF for container ${rowData[i].container} and process ${rowData[i].processo} downloaded successfully`);
        }
      }
    } catch (error) {
      console.log("Error fetching PDF:", error);
    }
  }
  
  const validateFields = () => {
    const newErrors = {};
  
  
    if (!fat.id_entidade_pag) newErrors.id_entidade_pag = "O pagador é obrigatório";
    //if (!fatAba1.fechamento); newErrors.fechamento = "Fechamento é obrigatório";
    if (!fat.id__entidade) newErrors.id__entidade = "Cliente é obrigatório";
    if (!rowData.length > 0) newErrors.containes_has_values = "Os containers são obrigatório";
    //if (!fatAba5.length > 0) newErrors.center_has_values = "Os Centros são obrigatório";
    //const totalValue2 = fatAba2.reduce((acc, item) => acc + parseFloat(item.value/100), 0);
    //const totalValue5 = fatAba5.reduce((acc, item) => acc + parseFloat(item.value/100), 0);
    //if (convertCurrency(fatAba1.valor) !== totalValue2) newErrors.containes_has_values = "Total de Container esta divergente!";
    //if (convertCurrency(fatAba1.valor) !== totalValue5) newErrors.center_has_values = "Total dos Centros esta divergente!";
    //console.log(convertCurrency(fatAba1.valor));
    //console.log(totalValue2)
    //console.log(totalValue5)


    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCreateSaldo = async () => {
    const totalValues = parseCurrencyToNumber(formatCurrency(totalRowData[0].valorPatioConf));
    const extValue = parseCurrencyToNumber(fat.valor_pagm);
    try {
      const response = await axios.post(
        `${parsedURL}post/create_extract_saldo?idlanc=${ondata.id_lanc}&value=${extValue - totalValues}`,
        null, // The data payload should be null if you are only sending query parameters
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      //console.log(response.data);
      //window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirm = async () => {
    const totalpg = parseCurrencyToNumber(formatCurrency(totalRowData[0].valorPatioConf));
    const pgextrato = parseCurrencyToNumber(fat.valor_pagm);
    handlePostFat();
    handleCreateSaldo();
    await gerar_intercambio();
    setShowConfirmationModal(false);
    toast.warn(`Valor restante é de ${formatCurrency((pgextrato - totalpg) / 100)}`, {
      position: "bottom-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    setTimeout(() => {
      window.location.reload();
    }, 2000);
    //handlePostFat();
  };

  const handleCancel = () => {
    setShowConfirmationModal(false);
  };

  const handleButtonAddContaienr = () => {
    const newDataProcess = {
      ...dataProcess,
      valorPatioConf: dataProcess.valor_pg,
      nf:'000000'
    };

    setRowData([...rowData, newDataProcess]);

    setDataProcess({
      "cliente": "",
      "container": "",
      "processo": "",
      "data_entrada": "",
      "patio": "",
      "importador": "",
      "valor_ap": "0",
      "valor_pg": "0"
    });
  };

  const handleRemoveRow = (container) => {
    setRowData(prevData => prevData.filter(item => item.container !== container));
  };

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  const calculateTotal = () => {
    const totalValorProcess = rowData.reduce((acc, item) => acc + parseFloat(item.valor_process), 0);
    const totalValorOficial = rowData.reduce((acc, item) => acc + parseFloat(item.valor_pg), 0);
    const totalvalorPatioConf = rowData.reduce((acc, item) => acc + parseFloat(item.valorPatioConf), 0);
    const totalValorAp = rowData.reduce((acc, item) => acc + parseFloat(item.valor_ap), 0);

    setTotalRowData([{
      cliente: 'Total',
      //valor_process: totalValorProcess,
      //valor_ap: totalValorAp,
      valor_pg: totalValorOficial,
      valorPatioConf: totalvalorPatioConf
    }]);
  };

  const formatContainer = (value) => {
    const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    if (cleanedValue.length <= 4) {
      return cleanedValue;
    } else if (cleanedValue.length <= 7) {
      return `${cleanedValue.slice(0, 4)} ${cleanedValue.slice(4)}`;
    } else {
      return `${cleanedValue.slice(0, 4)} ${cleanedValue.slice(4, 7)}.${cleanedValue.slice(7, 10)}-${cleanedValue.slice(10)}`;
    }
  };

  const handleRowDoubleClick = (params) => {
    if (selectType === 'cliente') {
      setFat(prevState => ({
        ...prevState,
        id__entidade: params.data.id_entidade,
        cliente: params.data.razao_social
      }));
    }
    else {
      setFat(prevState => ({
        ...prevState,
        id_entidade_pag: params.data.id_entidade,
        cliente_pag: params.data.razao_social
      }));
    }
    setShowGridEnti(false);
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const formattedValue = formatContainer(value);
    setCntr(formattedValue);
  };

  const handleRadioChange = (e) => {
    setRadioValue(e.target.value);
  };

  const colDefs = [
    {
      field: "container",
      headerName: "Container",
      floatingFilter: true,
      pinned: 'left',
      width: 150,
    },
    {
      field: "processo",
      headerName: "Processo",
      floatingFilter: true,
      filter: true,
      pinned: 'left',
      width: 130,
    },
    {
      field: "cliente",
      headerName: "Cliente",
      floatingFilter: true,
      filter: true,
      width: 150,
    },
    {
      field: "patio",
      headerName: "Patio",
      floatingFilter: true,
      filter: true,
      width: 180,
    },
    {
      field: "nf",
      headerName: "NF",
      floatingFilter: true,
      filter: true,
      width: 140,
      editable: (params) => params.node.rowPinned ? false : true,
    },
    //{
    //  field: "valor_process",
    //  headerName: "Valor Estimado",
    //  filter: true,
    //  width: 150,
    //  valueFormatter: (params) => formatCurrency(params.value),
    //},
    //{
    //  field: "valor_ap",
    //  headerName: "Valor Aprovado",
    //  filter: true,
    //  width: 150,
    //  valueFormatter: (params) => formatCurrency(params.value),
    //},
    {
      field: "valor_pg",
      headerName: "Valor Pago",
      floatingFilter: true,
      width: 150,
      editable: false,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      field: "valorPatioConf",
      headerName: "V. Total P",
      floatingFilter: true,   
      width: 150,
      editable: (params) => params.node.rowPinned ? false : true,
      valueFormatter: (params) => formatCurrency(params.value),
    },
    {
      headerName: "Apagar",
      field: "actions",
      cellRenderer: (params) => params.node.rowPinned ? null : (
        <button type="button" className="btn btn-danger" onClick={() => handleRemoveRow(params.data.container)}><CloseIcon /></button>
      ),
      width: 100
    }
  ];

  const handleCellValueChanged = (params) => {
    if (params.colDef.field === 'valorPatioConf') {
      calculateTotal();
    }
  };

  const columns = [
    {
      field: "id_entidade",
      headerName: "ID",
      floatingFilter: true,
      width: 80
    },
    {
      field: "razao_social",
      headerName: "Razão Social",
      floatingFilter: true,
      width: 200,
      filter: true
    },
    {
      field: "fantasia",
      headerName: "Fantasia",
      floatingFilter: true,
      width: 200,
      filter: true
    },
    {
      field: "cnpj_cpf",
      headerName: "CPF/CNPJ",
      floatingFilter: true,
      width: 180,
      filter: true,
    },
    //{ field: "Tipo", headerName: "Tipo", width: 150 },
    {
      field: "cidade",
      headerName: "Cidade",
      floatingFilter: true,
      width: 200,
      filter: true
    },
    {
      field: "estado",
      headerName: "UF",
      floatingFilter: true,
      width: 80,
      filter: true
    }
  ];

  const onCloseModalEntid = () => setShowGridEnti(false);

  return (
    <>
    <div className="box-center1">
      <div className="modal fade" id="modal2" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-xl modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h1 className="modal-title fs-5" id="exampleModalLabel">Criar Lancamento Avaria</h1>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
              <div className="row align-items-end">
                <div className="col-4">
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="radioSantos" value="sts" checked={radioValue === 'sts'} onChange={handleRadioChange} disabled={radiodisable}/>
                    <label className="form-check-label" htmlFor="radioSantos">Santos</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="radioGuaruja" value="grj" checked={radioValue === 'grj'} onChange={handleRadioChange} disabled={radiodisable}/>
                    <label className="form-check-label" htmlFor="radioGuaruja">Guaruja</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="inlineRadioOptions" id="radioRioClaro" value="rc" checked={radioValue === 'rc'} onChange={handleRadioChange} disabled />
                    <label className="form-check-label" htmlFor="radioRioClaro">Rio Claro</label>
                  </div>
                </div>
                <div><hr className="divider" /></div>
                <div className="col-2">
                  <div className="form-label">Processo</div>
                  <input value={process} onChange={(e) => setProcess(e.target.value)} className="form-control transparent-input" />
                </div>
                <div className="col-2">
                  <div className="form-label">Container</div>
                  <input
                    maxLength={15}
                    name="cntrInp"
                    value={cntr}
                    onChange={handleInputChange}
                    className="form-control transparent-input"
                  />
                </div>
                <div className="col-2">
                  <button type="button" className="btn btn-success" onClick={handleGetProcess}>Procurar</button>
                </div>
              </div>
              <div className="row align-items-end">
                <div className="col-2">
                  <div className="form-label">Data de Entrada</div>
                  <input readOnly={true} value={dataProcess.data_entrada} className="form-control transparent-input" />
                </div>
                <div className="col-2">
                  <div className="form-label">Importador</div>
                  <input readOnly={true} value={dataProcess.importador} className="form-control transparent-input" />
                </div>
                <div className="col-2">
                  <div className="form-label">Armador</div>
                  <input readOnly={true} value={dataProcess.cliente} className="form-control transparent-input" />
                </div>
                <div className="col-2">
                  <div className="form-label">Patio</div>
                  <input readOnly={true} value={dataProcess.patio} className="form-control transparent-input" />
                </div>
                {/*<div className="col-2">
                  <div className="form-label">Val. Aprovado</div>
                  <input readOnly={true} value={formatCurrency(dataProcess.valor_ap)} className="form-control transparent-input" />
                </div>*/}
                <div className="col-2">
                  <div className="form-label">Val. Cobrado</div>
                  <input readOnly={true} value={formatCurrency(dataProcess.valor_pg)} className="form-control transparent-input" />
                </div>
              </div>
              <div className="row pt-2 align-items-end">
                <div className="col-2">
                  <button type="button" className="btn btn-success" onClick={handleButtonAddContaienr}>Adicionar</button>
                </div>
              </div>
              <div><hr className="divider" /></div>
              <GridContainers gridRef={gridRef} rowData={rowData} colDefs={colDefs} totalRowData={totalRowData} handleCellValueChanged={handleCellValueChanged}/>
              {errors.containes_has_values && <span className="text-danger">{errors.containes_has_values}</span>}
              <div><hr className="divider" /></div>
              <div className="row align-items-end">
                <div className="col-2">
                  <div className="form-label">Empresa</div>
                  <select id="inputState" className="form-select transparent-input" value={fat.id_emp} name="id_emp" onChange={handleChange}>
                    <option value="">Selecione a Empr.</option>
                    {empresas.map((opcao) => (
                      <option key={opcao.id_emp} value={opcao.id_emp}>{opcao.fantasia}</option>
                    ))}
                  </select>
                </div>
                {/*
                <div className="col-2">
                  <div className="form-label">Cliente</div>
                  <select id="inputState" className="form-select transparent-input" name="id__entidade" value={fat.id__entidade} onChange={handleChange}>
                    <option selected value=''>Selecione o Cliente</option>
                    {entidade.map((opcao) => (
                      <option key={opcao.id_entidade} value={opcao.id_entidade}>{opcao.razao_social}</option>
                    ))}
                  </select>
                  {errors.id__entidade && <span className="text-danger">{errors.id__entidade}</span>}
                </div>
                 <div className="col-2">
                  <div className="form-label">Pagador</div>
                  <select id="inputState" className="form-select transparent-input" name="id_entidade_pag" value={fat.id_entidade_pag} onChange={handleChange}>
                    <option selected value=''>Selecione o Cliente</option>
                    {entidade.map((opcao) => (
                      <option key={opcao.id_entidade} value={opcao.id_entidade}>{opcao.razao_social}</option>
                    ))}
                  </select>
                  {errors.id_entidade_pag && <span className="text-danger">{errors.id_entidade_pag}</span>}
                </div>*/}

                <div className="col-md-3">
                  <label className="hfx form-label">Cliente</label>
                  <div className="input-group">
                    <input type="text" value={fat.cliente} readOnly={true} className="form-control transparent-input" />
                    <button type="button" onClick={() => handleClickSeach("cliente")} disabled={editor} className="input-group-text btn-primary btn"><Search /></button>
                  </div>
                  {errors.id__entidade && <span className="text-danger">{errors.id__entidade}</span>}
                </div>
                <div className="col-md-3">
                  <label className="hfx form-label">Cliente Pagador</label>
                  <div className="input-group">
                    <input type="text" value={fat.cliente_pag} readOnly={true} className="form-control transparent-input" />
                    <button type="button" onClick={() => handleClickSeach("cliente_pag")} className="input-group-text btn-primary btn"><Search /></button>
                    {errors.id_entidade_pag && <span className="text-danger">{errors.id_entidade_pag}</span>}
                  </div>
                </div>
                
                <div className="col-1 justify-content-center">
                  <Link className="btn btn-success" to="/cadastro_entidade" target="_blank" rel="noopener noreferrer"><AddIcon /></Link>
                </div>
                <div className="col-2">
                  <div className="form-label">Valor Pago R$</div>
                  <input value={fat.valor_pagm} onChange={handleChange} readOnly={true} name="valor_pagm" className="form-control transparent-input" />
                </div>
              </div>
              <div><hr className="divider" /></div>
              <div className="modal-footer">
                <button type="button" className="btn btn-success" onClick={handleButtonCad}>Cadastrar</button>
              </div>
            </div>
          </div>
        </div>
      {showGridEnti && (
        <ModalBuscarEntidade
        entidade={entidade} columns={columns} onCloseModalEntid={onCloseModalEntid} handleRowDoubleClick={handleRowDoubleClick}
        />
      )}
      <div className={`modal ${showConfirmationModal ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: showConfirmationModal ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Confirmação</b></h5>
              <button type="button" className="close bg-danger text-white" onClick={handleCancel} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body bg-light">
              <p>O Valor do <b>Comprovante</b> é maior que os <b>Containers</b> informados.</p>
              <p>Deseja continuar?</p>
            </div>
            <div className="modal-footer bg-warning">
              <button type="button" className="btn btn-secondary" onClick={handleCancel}>Não</button>
              <button type="button" className="btn btn-primary" onClick={handleConfirm}>Sim</button>
            </div>
          </div>
        </div>
      </div>
      <div className={`modal ${modalConsiliaMenor ? 'd-block' : ''}`} tabIndex="-1" role="dialog" style={{ display: modalConsiliaMenor ? 'block' : 'none' }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header bg-warning">
              <h5 className="modal-title"><b>Confirmação</b></h5>
              <button type="button" className="close bg-danger text-white" onClick={handleCancelMenor} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body bg-light">
              <p>O Valor do <b>Comprovante</b> é <b className="text-danger">menor</b> que os <b>Containers</b> informados.</p>
              <p>Deseja continuar?</p>
            </div>
            <div className="modal-footer bg-warning">
              <button type="button" className="btn btn-secondary" onClick={handleCancelMenor}>Não</button>
              <button type="button" className="btn btn-primary" disabled={readOnlyConfirm} onClick={handleConfirmeMenor}>Sim</button>
            </div>
          </div>
        </div>
      </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
    </>
  );
};

export default LancAvariaModal;
