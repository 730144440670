import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";
import { AgGridReact } from 'ag-grid-react';
import { AG_GRID_LOCALE_BR } from '@ag-grid-community/locale';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import "../../style/style.css";

const Grid1 = ({ data, setSelectedLancIdGrid1, setSelectedValuesGrid1 }) => {


  const [rowData, setRowData] = useState(data);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const token = localStorage.getItem("token");
  const parsedURL = localStorage.getItem('urlParsed');
  const today = new Date().toLocaleDateString('pt-BR');

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
  };


  useEffect(() => {
    getConsultFat();
  }, []);

  const formatCurrency = (value) => {
    const numericValue = parseFloat(value);
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(numericValue);
  };

  const getConsultFat = async () => {
    try {
      const response = await axios.get(
        `${parsedURL}get/lanc/listLanc_dont_consci/NotCancel`,
        {
          headers: {
            'accept': 'application/json',
            'Authorization': `Bearer ${token}`
          },
        }
      );
      if (response.status === 200) {
        setRowData(response.data.content);
        console.log(response.data.content)
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSelectionChanged = (event) => {
    const selectedRows = event.api.getSelectedRows();
    const selectedIdLancs = selectedRows.map(row => row.id_lanc);
    const selectedValueLancs = selectedRows.map(row => row.valor_receb);
    setSelectedValuesGrid1(selectedValueLancs);
    setSelectedLancIdGrid1(selectedIdLancs);
  };

  var filterParams = {

    comparator: (filterLocalDateAtMidnight, cellValue) => {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("/");
      var cellDate = new Date(
        Number(dateParts[2]),
        Number(dateParts[1]) - 1,
        Number(dateParts[0]),
      );

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }

      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }

      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
      return 0;
    },
    minValidYear: 2000,
    maxValidYear: 2024,
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
  };

  const colDefs = [
    {
      field: 'id_lanc',
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 35,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'lanc_os',
      headerName: 'Ordem de Serviço',
      floatingFilter: true,
      filter: true,
      width: 150,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })

    },
    {
      field: 'client',
      headerName: 'Cliente',
      floatingFilter: true,
      filter: true, width: 200,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })

    },
    {
      field: 'client_pag',
      headerName: 'Cliente Pag',
      floatingFilter: true,
      filter: true,
      width: 200,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'valor_round',
      headerName: 'Valor',
      valueFormatter: (params) => formatCurrency(params.value),
      floatingFilter: true,
      filter: true,
      width: 130,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'valor_receb_round',
      headerName: 'Valor A Rec',
      valueFormatter: (params) => formatCurrency(params.value),
      floatingFilter: true,
      filter: true,
      width: 130,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'number_nf',
      headerName: 'Nota Fiscal',
      floatingFilter: true,
      filter: true,
      width: 130,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })
    },
    {
      field: 'data_data_emissao_nf',
      headerName: 'Data Emissão',
      filter: "agDateColumnFilter",
      filterParams: filterParams,
      floatingFilter: true,
      width: 140,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })

    },
    {
      field: 'data_vencimento',
      headerName: 'Vencimento',
      width: 130,
      filter: "agDateColumnFilter",
      floatingFilter: true,
      filterParams: filterParams,
      cellStyle: (params) => ({
        backgroundColor: parseDate(params.data.data_vencimento) === parseDate(today) ? 'yellow' : parseDate(params.data.data_vencimento) < parseDate(today) ? 'red' : 'inherit', // Aplica estilo se nracond for 'Total'
      })

    },
  ];

  return (
    <>
      <div className="box-center1 bg-custom">
        <div className="card col-md-12 mx-auto">
          <div className="ag-theme-quartz ag-theme-custom" style={{ height: 370 }}>
            <AgGridReact
              localeText={AG_GRID_LOCALE_BR}
              rowData={rowData}
              columnDefs={colDefs}
              rowSelection="multiple"
              onSelectionChanged={handleSelectionChanged}
              pagination={true}
              rowStyle={(params) => ({ backgroundColor: params.data.selected ? 'green' : 'red' })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Grid1;